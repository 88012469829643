/* POP UP - MODAL AND HEADING ONLY. ALL SPOT CARDS ARE IN SPOT FILE */

ion-modal.caldatepopupmodal {
    --width: 90%;
    --max-width: 500px;
    --height: 66%;
    /* --width: fit-content;
    --width: 95vw;
    --height: fit-content; */
    --border-radius: 20px;
    /* box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ; */
    --background: transparent;
}

ion-modal.caldatepopupmodal::part(backdrop) {
    background: rgba(0, 0, 0);
    --backdrop-opacity: .6;
}

ion-toolbar.toolbarcalpopup {
    --max-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* --background: #333; */

    --background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(10px);
}

ion-content.calcontent {
    /* --padding-bottom: 75px; */
    /* display: relative; */
    /* height: fit-content; */
    /* width: 100%;
    height: 100%; */
    /* border: 2px solid blue; */
    /* overflow: hidden; */
    /* padding-bottom: 65px; */
    height: 100%;
    /* padding: 10px; */
}


.calpopupheadercontainer {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calpopupleft {
    width: 12%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.calpopupright {
    width: 12%;
    height: 100%;
    display: flex;
    /* justify-content: end; */
    /* why don't I have the above? */
    align-items: center;
  }

.calviewchevroncontainerl {
    transform: rotate(270deg);
    font-family: Poppins;
    /* text-shadow:
    0 0 1px rgb(0, 255, 255),
    0 0 2px rgb(0, 255, 255),
    0 0 3px rgb(0, 255, 255),
    0 0 8px rgb(0, 255, 255)
   
    ; */
    text-shadow:
    0 0 1px rgb(11, 255, 182),
    0 0 2px rgb(11, 255, 182),
    0 0 3px rgb(11, 255, 182),
    0 0 8px rgb(11, 255, 182)
    /* 0 0 16px rgb(0, 255, 255) */
    /* 0 0 32px rgb(0, 255, 255) */
    ;
    cursor: pointer;
}

.calviewchevroncontainerr {
    transform: rotate(90deg);
    font-family: Poppins;
    /* text-shadow:
    0 0 1px rgb(0, 255, 255),
    0 0 2px rgb(0, 255, 255),
    0 0 3px rgb(0, 255, 255),
    0 0 8px rgb(0, 255, 255)
   
    ; */
    text-shadow:
    0 0 1px rgb(11, 255, 182),
    0 0 2px rgb(11, 255, 182),
    0 0 3px rgb(11, 255, 182),
    0 0 8px rgb(11, 255, 182)
    /* 0 0 16px rgb(0, 255, 255) */
    /* 0 0 32px rgb(0, 255, 255) */
    ;
    cursor: pointer;
}

.calviewchevroncontainerdbl {
    transform: rotate(270deg);
    font-family: Poppins;
    color: grey;
    opacity: 0.6;
}

.calviewchevroncontainerdbr {
    transform: rotate(90deg);
    font-family: Poppins;
    color: grey;
    opacity: 0.6;
}

.calviewchevron {
    font-size: .9rem;
    font-weight: 300;
    -webkit-transform:scale(2,1); /* Safari and Chrome */
    -moz-transform:scale(2,1); /* Firefox */
    -ms-transform:scale(2,1); /* IE 9 */
    -o-transform:scale(2,1); /* Opera */
    transform:scale(2,1); /* W3C */
    /* which of the above do I need */
}

.calpopupheader {
    font-size: 1.15rem;
    font-weight: bold;
    font-family: Montserrat;
    color: white;
    /* text-transform: uppercase; */
}


/* CALENDAR */

@media (max-width: 589px) {
.calendar {
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -6px;
    /* was -12 before */
    padding-bottom: 25px;
}
}

@media (min-width: 590px) {
    .calendar {
        position: relative;
    
        width: 100%;
        
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 50% 50%;

        margin-top: -6px;
        /* padding-bottom: 10px; */
        padding-bottom: 25px;
    }
    }

.calmonth {
    width: 88%;
    aspect-ratio: 1 / 1;
    /* height: 340px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.topminus {
    margin-top: -12px;
}

.calmonthheader {
    width: 100%;
    height: 25%;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.calmonthheaderinner {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calmonthheaderdate {
    width: 100%;
    padding: 8px 15px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.15rem;
    color: fuchsia;
    text-align: center;
    font-family: Montserrat;

    /* background: -webkit-linear-gradient(34deg, #BC00FE, fuchsia);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.calmonthdaysbox {
    width: 100%;
    height: 30%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(7, 1fr);
    font-family: Gilroy;
    font-size: .9rem;
    color: white;
}

.calmonthday {
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
}

.caldatesgridlarge {
    width: 100%;
    height: 90%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
}

.caldatesgridstd {
    width: 100%;
    height: 75%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 1px;
}

.calvisibledate {
    padding: 3px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    color: white;
}

.calinvisibledate {
    visibility: hidden;
    padding: 3px;
}

#todaydate {
    background-color: #FF1493;
    opacity: 1 !important;
    /* should be able to get rid of the above when i current the whole current date thing.. because right now today date is getting greyed out */
    border-radius: 50%;
}

.caldatebox {
    position: relative;
    width: 100%;
    height: 100%;   
}
/* above is likely redundant */

.caldateimg {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.caldateimgoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 30px; */
    border-radius: 50%;
    opacity: 0.3;
    z-index: 2;
    background-color: black;
}

.caldatenum {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    z-index: 2;
}
