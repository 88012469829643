/* swiper for today and live events in party grid view */

.swipercontainerstd {
    /* display: flex; */
    margin-top: 5px;
}

.swipercontainerstdft {
    /* display: flex; */
    margin-top: 5px;
    /* padding: 0 10px; */
    /* border: 1px solid blue; */
    /* display: flex;
    justify-content: center; */
}

.swipercontainerstd2 {
    /* display: flex; */
    margin-top: 5px;
    /* margin-left: -20px; */
    /* border: 1px solid blue; */
}

.swiperaddspace {
    padding-bottom: 10px;
    width: 100%;
    /* --swiper-scrollbar-bg-color: #555;
    --swiper-scrollbar-drag-bg-color: #7DF9FF; */
}

.controlswp {
    /* border: 1px solid green; */
    width: 200px;
    /* width: fit-content; */
    /* height: 200px; */
    /* height: 150px; */
    height: fit-content;
    /* height: 100%; */
    /* border: 1px solid aqua; */
    display: flex;
    /* justify-content: end; */
    /* margin-left: -20px; */
}

.swipercard {
    position: relative;
    width: 96%;
    aspect-ratio: 1/.95;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ; */
    /* note the box shadow doesnt work as well as gradient gets lighter */
    /* BUT why does old version look good. check BC and PPL cards */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
    
}

.swipercardnew {
    position: relative;
    width: 96%;
    aspect-ratio: 1/.95;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ; */
    /* note the box shadow doesnt work as well as gradient gets lighter */
    /* BUT why does old version look good. check BC and PPL cards */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
    
}

.swipercard2 {
    position: relative;
    width: 155px;
    /* height: 150px; */
    aspect-ratio: 1/.95;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* note the box shadow doesnt work as well as gradient gets lighter */
    /* BUT why does old version look good. check BC and PPL cards */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
    position: relative;
    /* left: 20px; */
}

.swipercardfeat {
    position: relative;
    margin: 0 auto;
    width: 95%;
    aspect-ratio: 16/9;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ; */
    /* note the box shadow doesnt work as well as gradient gets lighter */
    /* BUT why does old version look good. check BC and PPL cards */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
    
}

.swiper {
    /* width: 100%; */
    /* width: fit-content; */
    /* background-color: aqua; */
    
}

.swiper.pagination {
    /* --color: #0BFFB6;
    --border: 1px solid red; */
    
}

.swiper.swipercls {
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    /* --swiper-navigation-size: 50px; */
    /* --swiper-navigation-top-offset: 50%; */
    /* --swiper-navigation-sides-offset: 60px; */
    /* --swiper-navigation-color:orange; */
    /* --swiper-pagination-color: #bebebe; */
    --swiper-pagination-color: white;
    --swiper-pagination-bottom: 0px;
    /* border: 1px solid red; */
    padding-bottom: 30px;
    --swiper-pagination-bullet-inactive-color: #444;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 5px;

}


.bignum {
    height: 100%;
    /* border: 1px solid purple; */
    /* display: flex; */
    /* align-items: center; */
    font-size: 6rem;
    position: relative;
    left: 15px;
    color: transparent;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0BFFB6;
    font-family: Gilroy;
}


