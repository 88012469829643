/* WINDOW POP UP MAP */

ion-modal.windowmapmodal {
    --width: 90%;
    --height: 50%;
    --border-radius: 20px;
    box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;
}
/* changed the above from id to class so check that still works */

/* CRUISE WINDOW MAP */
ion-modal.windowmapmodalC {
    --width: 90%;
    --height: 75%;
    --border-radius: 20px;
    box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;
}

ion-modal.windowmapmodal::part(backdrop) {
    background: rgb(0, 0, 0);
    /* would --background be better?  */
    --backdrop-opacity: .8;
}

ion-modal.windowmapmodalC::part(backdrop) {
    background: rgb(0, 0, 0);
    /* would --background be better?  */
    --backdrop-opacity: .8;
}

.mapcontainer{
    position: relative;
    height: 100%;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.mapcontainerinner{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* not sure why you need both of the above */


/* MULTI PLACE MAP EG FOR BARS AND CLUBS */

.BCmapmain {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mapboxstyle {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapblank {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.65); /* Semi-transparent background instead of opacity */
    /* opacity: 0.2; */
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapmsg {
    position: absolute;
    /* top: 0; */
    background-color: rgba(0, 0, 0, .5); /* Semi-transparent background instead of opacity */

    /* background-color: #000; */
    /* opacity: 1 !important; */
    padding: 10px;
    /* z-index: 111; */
    /* overflow: hidden; */
    border-radius: 10px;
    /* height: 100px; */
    /* border: 1px solid red; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */

}

.mapviewcontainerBC {
    position: absolute;
    bottom: 5%;
    width: 90%;
    max-width: 500px;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;

    /* border: 1px solid red; */

    --ion-background-color: transparent;
}

.mapioncontent {
    background-color: #111;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.marker {
    height: 32px;
    width: 32px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /* color: rgb(0, 255, 255); */
    color: #FF00EE;
    font-weight: bold;
    font-size: .9rem;
    /* background-image: linear-gradient(
        180deg,
        #000, #222, #444
    ); */
    background-color: #333;
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
  }

  .marker2 {
    height: 32px;
    width: 32px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    color: #FF00EE;
    font-weight: bold;
    font-size: .9rem;
    /* background-image: linear-gradient(
        180deg,
        #222, #444, #555
    ); */
    background-color: #333;
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
  }