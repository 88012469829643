ion-menu {
  /* max-width: 290px; */
  /* --max-width: 100%; */
  --ion-background-color: #1a1a1a;
  
}

@media (min-width: 1200px) {
  ion-menu {
    --width: 305px;
  width: 305px;
  max-width: 305px;
  /* --max-width: 100%; */
  }
}

ion-toolbar.menutoolbarbg {
  --background: #111;
}

ion-menu::part(backdrop) {
  --background: rgba(36, 36, 36, 0.6);
}

ion-menu::part(container) {
  border-right: 2px solid #000 !important;
}

.menutoolbar {
  min-height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: transparent !important; */
  
}

.menuheaderbox {
  height: 100%;
  /* width: 73%; */
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */


  width: 100%;
  justify-content: center;
  align-items: center;

  font-weight: bold;
    font-family: Orbitron;
    font-size: 1.37rem;
    letter-spacing: 0.21rem;
}

@media (max-width: 360px) {
  .menuheaderbox {
    height: 100%;
    /* width: 73%; */
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
  
  
    width: 100%;
    justify-content: center;
    align-items: center;
  
    font-weight: bold;
      font-family: Orbitron;
      font-size: 1.27rem;
      letter-spacing: 0.15rem;
  }


}



.menutriangle {
  /* height: 21px; */
  /* margin-right: 2px; */
  /* margin-bottom: 1.5px; */
  /* filter:
  
     drop-shadow(0px 0px 10px fuchsia)
     drop-shadow(0px 0px 15px fuchsia)
  
    ; */
    width: 210px;
    margin-top: 3px;
}

/* .menumainsetwidth {
  width: 91%;
  margin: 0 auto;
  padding-bottom: 100px;
} */

.menumainsetwidth {
  /* width: 90%; */
  /* margin: 10px auto 0 auto; */
  /* margin: 0 auto; */
  /* border: 1px solid red; */

  /* padding-bottom: 12px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.cityheaderbox {
  padding: 13px 0 4px 0;
  display: flex;
}

.menufirstlinecont {
  padding: 0 5px;
  width: 10%;
}

.menuthirdlinecont {
  flex-grow: 1;
  padding: 0 5px;
}

.menutopline {
  height: 50%;
  border-bottom: .3px solid fuchsia;
}

.menubotline {
  height: 50%;
  border-top: .3px solid fuchsia;
}

.menutopline2 {
  height: 50%;
  border-bottom: .3px solid #7df9ff;
}

.menubotline2 {
  height: 50%;
  border-top: .3px solid #7df9ff;
}




.smalltop {
  margin-top: 3px;
}

.menusection {
  /* padding: 9px 0 8px 4px;  
  display: flex;
  align-items: center; */

  width: 90%;
  /* padding: 9px 0 7px 4px;   */
  padding-bottom: 7px;
  display: flex;
  align-items: center;
}

.menusectionalt {
  /* padding: 9px 4px 8px 4px; */
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  /* margin-top: 0px; */
}

.btnsfeedback {
  /* padding: 9px 4px 8px 4px; */
  width: 90%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  margin: 10px 0;
}

ion-select {
  font-family: Montserrat;
}

ion-select::part(label) {
  font-size: 1rem;
 
  font-size: .9rem;
  /* letter-spacing: .5px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* padding: 15px 0 10px 0; */
  /* padding: 9px 4px 8px 4px;   */

  /* font-family: Gilroy; */
  font-family: Montserrat;
  /* color: #999; */
  /* color: #BC00FE; */
  /* font-size: 1rem; */
  /* letter-spacing: .01rem; */
  /* color: #999; */
  /* color: #BC00FE; */
  /* font-size: 1rem; */
  /* letter-spacing: .07rem; */
  
  /* background: -webkit-linear-gradient(90deg, #444, #555, #666, #777);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  color: #777;
}

ion-select::part(icon) {
  font-family: Montserrat;
  color: fuchsia;
}

ion-select::part(text) {
  font-family: Montserrat;
  /* text-transform: uppercase; */
  font-size: 1.05rem;
  color: white;
  /* letter-spacing: 0.04rem; */
  /* font-weight: bold; */
}

ion-popover {
 font-family: Montserrat !important;
 /* text-transform: uppercase; */
}


.menusectheader {
  

  padding-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;

  letter-spacing: .02rem;
  
  

  color: #999;
  font-size: .9rem;
}

.menusubheader {
  /* height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  color: white;
  margin-bottom: 5px; */

  width: 100%;
  height: 40px;
  /* border-radius: 8px; */
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-family: Poppins; */
  /* color: #BEBEBE; */
  color: white;
  margin-bottom: 3px;
  /* text-transform: uppercase; */
  border-left: 2px solid transparent;
  cursor: pointer;
}

.menusubheader2 {
  /* height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  color: white;
  margin-bottom: 5px; */

  width: 100%;
  height: 100%;

  /* height: 40px; */
  /* border-radius: 8px; */
  
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  /* font-family: Poppins; */
  /* color: #BEBEBE; */
  color: white;

  
  /* margin-bottom: 3px; */
  
  padding: 10px 0;
  /* text-transform: uppercase; */
  border-left: 2px solid transparent;
  
}

.menusubheaderbt {
  /* height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  color: white;
  margin-bottom: 5px; */

  /* border-top: 1px solid #000; */
  
  width: 90%;
  /* height: 50px; */
  /* border-radius: 8px; */
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-family: Poppins; */
  /* color: #BEBEBE; */
  color: white;
  /* margin-bottom: 3px; */
  /* text-transform: uppercase; */
  border-left: 2px solid transparent;
  
}

.botpd {
  /* padding-bottom: 10px; */
}

.menuselected {
  /* font-weight: bold;
  color: white;
  background-color: #333; */
  font-weight: bold;
  color: white;
  background-color: #333;
  border-left: 2px solid fuchsia;
}

.menuselected .numbox {
  background-image: linear-gradient(
        135deg,
        fuchsia, #FF1493
        );
}

.menuselected .menuoption {
  color: fuchsia;
}

.extratop {
  margin-top: 10px;
}

.dividermenucont {
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 10px 0;
  /* border-top: 1px solid #ccc; */
}

.dividermenu {
  width: 90%;
  /* margin: 10px 0; */
  border-top: 1px solid #555;
}

.menuoption {
 
  margin-left: 5%;
  font-family: Gilroy;
  font-size: 1.05rem;
  letter-spacing: 0.03rem;
  /* color: white; */
  /* cursor: pointer; */
}

.menuoptionbt {
  
  display: flex;
  /* justify-content: center; */
  align-items: center;

  /* padding: 0 15px; */
  /* margin-left: 5%; */
  font-family: Gilroy;
  font-size: 1.05rem;
  letter-spacing: 0.03rem;
  /* color: white; */
  position: relative;
  /* padding: 10px 0 0 0; */
}

.numbox {
  margin-right: 5%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  border-radius: 5px;
  background-color: #383838;
  font-size: 0.7rem;
  font-family: Poppins;
letter-spacing: 0.01rem;
}

.numbox2 {
  margin-right: 5%;
  margin-bottom: 4px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  border-radius: 5px;
  /* background-color: #383838; */
  font-size: 0.7rem;
  font-family: Poppins;
letter-spacing: 0.01rem;
  /* background-image: linear-gradient(
        135deg,
        fuchsia, #FF1493
        ); */
        /* color: white; */

        background-color: #000;
        border: 1px solid #ff00ff;
}

.numbox3 {
  margin-right: 5%;
  margin-bottom: 4px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  border-radius: 5px;
  /* background-color: #383838; */
  font-size: 0.7rem;
  font-family: Poppins;
letter-spacing: 0.01rem;
  /* background-image: linear-gradient(
        135deg,
        fuchsia, #FF1493
        ); */
        /* color: white; */

        background-color: #000;
        border: 1px solid fuchsia;
}

.menufooter {
  cursor: pointer;
  /* background-color: #222; */
  /* position: fixed;
  left: 0px;
  bottom: 0px; */
  height: 65px;
  width: 100%;
  /* border-radius: 15px 15px 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: space-between; */
  align-items: center;
  /* border: 1px solid red; */
  z-index: 1000;
  /* background-image: linear-gradient(
      360deg,
      #111, #222, #444
  ); */
  /* border-top: 1px solid #000; */
  /* box-shadow: 0px 0px 5px 0px rgba(36,36,36,0.2),
  0px 0px 10px 0px rgba(0,0,0,0.2)
 
; */

background-color: rgba(36, 36, 36, .5);

    backdrop-filter: saturate(180%) blur(10px);
/* 
--background: transparent;
background-color: transparent; */

/* border-top: 1px solid #84FFF3; */
font-family: Montserrat;
font-weight: bold;

/* background-image: linear-gradient(
        135deg,
        fuchsia, #FF1493
        ); */

/* background-color: rgba(51,51,51,.3);
    backdrop-filter: saturate(180%) blur(15px); */
}

.fvmr {
  margin-top: 5px;
}

.settingsopt {
  width: 100%;
  padding: 0 5%;
  height: 68px;
  display: flex;
  align-items: center;
  font-size: 1.05rem;
  /* color: #84FFF3; */
  color: white;
  /* background-color: #333; */
  background-color: transparent;
  position: relative;
}

.cccopt {
  width: 100%;
  padding: 9px 4% 10px 4%;
  /* height: 48px; */
  
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  letter-spacing: 0.03rem;
  /* background-color: fuchsia; */
  color: fuchsia;
  /* text-transform: uppercase; */
  font-family: Montserrat;
  font-weight: bold;
  /* margin-bottom: -3px; */
  background-color: rgba(36,36,36,.3);
  backdrop-filter: saturate(180%) blur(10px);
}



.seticon {
  color: #84FFF3;
  /* color: #ccc; */
  font-size: 1.1rem;
  margin-right: 10px;
}

.seticoncl {
  color: #84FFF3;
  font-size: 1.2rem;
  margin-right: 12px;
  z-index: 10;
}

.seticonclarr {
  color: #84FFF3;
  font-size: 1.2rem;
  margin-right: 12px;
  z-index: 10;
  transform: rotate(-45deg);
}

.seticonclnew {
  color: #ff00ff;
  font-size: 1rem;
  /* margin-right: 12px; */
  z-index: 10;
  transform: rotate(-45deg);
}


.seticonclnew2 {
  color: #7df9ff;
  font-size: 1rem;
  /* margin-right: 12px; */
  z-index: 10;
  /* transform: rotate(-45deg); */
}

.seticonclnew3 {
  position: absolute;
  right: 100%;
  color: #7df9ff;
  font-size: 1rem;
  /* margin-right: 12px; */
  z-index: 10;
  /* transform: rotate(-45deg); */
  margin-right: 12px;

}

.stretchcl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  /* border: 1px solid yellow; */
  /* text-align: center; */
  /* padding-right: 37px; */
  background-color: transparent;
  /* color: #84FFF3; */
  color: white;
}

.seticon2 {
  color: fuchsia;
  font-size: 1.1rem;
  margin-right: 7px;
}

.seticon3 {
  color: #84FFF3;
  font-size: 1.2rem;
  /* margin-right: 10px; */
  margin-left: auto;
}

.seticon4 {
  color: white;
  font-size: 1.6rem;
  /* margin-right: 10px; */
  margin-left: auto;
  margin-bottom: 5px;
}

.chcty {
  margin-left: auto;
  /* font-family: Poppins; */
  font-size: .85rem;
  letter-spacing: 0.03rem;
  border-radius: 20px;
  /* border: 1px solid white; */
  padding: 6px 12px;
  background-color: #000;
  border: 1px solid #ff00ff;

  color: fuchsia;
  /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
  /* box-shadow: 1px 1px 0px 0px rgba(36,36,36,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7)
    ; */
    font-weight: 500;
    /* margin-bottom: 1px; */
    font-weight: bold;
    font-family: Montserrat;
    text-shadow: 1px 1px 0 #111;
    cursor: pointer;
}



.gradtxt1 {
  /* color:fuchsia; */
  background: -webkit-linear-gradient(34deg, #8E2DE2, #BC00FE);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}





.gradtxt2 {
  background: -webkit-linear-gradient(34deg, #8E2DE2, #BC00FE);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}







.custombox {
  /* border: 1px solid red; */
  /* padding: 15px 0; */
  width: 91%;
  /* margin: 20px auto 0 auto; */
  /* border: 1px solid red; */
  padding-top: 10px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.customtxt {
  width: 90%;
  
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 0 7px 0;
  /* padding: 9px 4px 8px 4px;   */

  font-family: Montserrat;

  
  letter-spacing: .02rem;
  

  color: #777;
  font-size: .9rem;
  
}

.customtxtfdb {
  width: 50%;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-family: Montserrat;

  
  letter-spacing: .02rem;
  

  color: white;
  font-size: .9rem;
  
}

.filtermenu {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  font-family: Gilroy;
  color: white;
  /* font-weight: bold; */
  font-size: 1rem;
  /* letter-spacing: 0.1rem; */
  padding: 12px 0;
  /* text-transform: uppercase; */

  font-family: Gilroy;
  font-size: 1.05rem;
  letter-spacing: 0.03rem;
}

.filtersinnerfbmenu2 {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  /* padding: 0 5px; */
}




ion-button.sbmtbtn {
  /* width: 77%; */
  width: 47.5%;
  /* height: 63%; */
  height: 30px;

  /* border-radius: 50px 8px; */
  --border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  /* color: fuchsia; */
  letter-spacing: 0.04rem;
  text-shadow: .5px .5px 0 #333;
  
      --background: linear-gradient(
      0deg,
      #111, #222, #333, #444
  );
  /* border: 3px solid #0BFFB6; */
      /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
      1px 3px 6px 0px rgba(36,36,36,0.7),
      2px 4px 8px 0px rgba(0,0,0,0.7)
  ; */
  font-family: Montserrat;
  /* transform: skew(-15deg); */
  /* padding: 15px 0 17px 0; */



  /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */

}

.btncolp {
  color: fuchsia;
}

.btncolr {
  color: #FF1493;
}

.btncolb {
  color: #444;
}



ion-loading.custom-loading {
  --background: #333;
  --spinner-color: fuchsia;

  color: fuchsia;
}


@media (max-width: 430px) {
  .cccopt {
    width: 100%;
    padding: 9px 4% 10px 4%;
    /* height: 48px; */
    
    display: flex;
    align-items: center;
    font-size: 1.05rem;
    letter-spacing: 0.02rem;
    /* background-color: fuchsia; */
    color: fuchsia;
    /* text-transform: uppercase; */
    font-family: Montserrat;
    font-weight: bold;
    /* margin-bottom: -3px; */
    background-color: rgba(36,36,36,.3);
    backdrop-filter: saturate(180%) blur(10px);
  }

  .chcty {
    margin-left: auto;
    /* font-family: Poppins; */
    font-size: .8rem;
    letter-spacing: 0.02rem;
    border-radius: 20px;
    /* border: 1px solid white; */
    padding: 6px 10px;
    background-color: #000;
    border: 1px solid #ff00ff;
  
    color: fuchsia;
    /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
    /* box-shadow: 1px 1px 0px 0px rgba(36,36,36,0.7),
                  1px 3px 6px 0px rgba(0,0,0,0.7)
      ; */
      font-weight: 500;
      /* margin-bottom: 1px; */
      font-weight: bold;
      font-family: Montserrat;
      text-shadow: 1px 1px 0 #111;
  }
}


@media (max-width: 360px) {
  

  .cccopt {
    width: 100%;
    padding: 9px 4% 10px 4%;
    /* height: 48px; */
    
    display: flex;
    align-items: center;
    font-size: 1rem;
    letter-spacing: 0.02rem;
    /* background-color: fuchsia; */
    color: fuchsia;
    /* text-transform: uppercase; */
    font-family: Montserrat;
    font-weight: bold;
    /* margin-bottom: -3px; */
    background-color: rgba(36,36,36,.3);
    backdrop-filter: saturate(180%) blur(10px);
  }

  .chcty {
    margin-left: auto;
    /* font-family: Poppins; */
    font-size: .8rem;
    letter-spacing: 0.02rem;
    border-radius: 20px;
    /* border: 1px solid white; */
    padding: 6px 10px;
    background-color: #000;
    border: 1px solid #ff00ff;
  
    color: fuchsia;
    /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
    /* box-shadow: 1px 1px 0px 0px rgba(36,36,36,0.7),
                  1px 3px 6px 0px rgba(0,0,0,0.7)
      ; */
      font-weight: 500;
      /* margin-bottom: 1px; */
      font-weight: bold;
      font-family: Montserrat;
      text-shadow: 1px 1px 0 #111;
  }
}