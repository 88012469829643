/* standard toolbar */
/* im putting other toolbar classes in respective sectinons (eg searchbars) for now */

ion-toolbar.transparenttoolbar {
    /* --background: rgba(36,36,36,.7); */
    --background: rgba(17,17,17,.7);
    backdrop-filter: saturate(180%) blur(15px);
}

.toolbarstd {
    height: 75px;
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.toolbar2 {
    position: relative;
    height: 46px;
    /* height: 100%; */
    /* height: 55px; */
    /* width: 92%; */
    width: 100%;
    padding: 10px 0;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    /* margin: 0 auto -2px auto; */
    margin: 0 auto;
    /* border: 1px solid red; */
    /* margin-bottom: -16px; */
}

.toolbar3 {
    display: none;
}


.menusectb {
    position: relative;
    display: block;
    /* padding: 0 0 0 20px; */
    display: flex;
  
  }

  .newviewtog {
    background-color: #222;
    /* padding-left: 3%; */
    display: flex;
    /* justify-content: end; */
    justify-content: center;
    align-items: center;
    /* padding: 20px 0; */
    /* margin-bottom: 15px; */
    /* background-color: #111; */
  
    /* margin-right: 10px; */
    /* padding: 5px 10px; */
    border-radius: 20px;
    height: 42px;
    /* margin-left: 10px; */
    padding: 0 5px;
  }

  .segtext {
    font-size: 1.15rem;
    padding: 0 10px;
    /* color: white; */
    letter-spacing: 0.08rem;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* border: 2px solid aqua; */
    color: #777;
}

.segcolored {
    color: #BC00FE;
    font-weight: bold;
    /* cursor: default; */
}

.segtext.segcolored {
    cursor: default;
    /* color: #555; */
}

.grdic {
    font-size: 1.6rem;
}

.segtog {
    display: flex;
    justify-content: end;
    align-items: center;
    
}

ion-toggle.grdcaltog {
    /* padding: 12px; */
    --track-background: #444;
    --track-background-checked: #444;
    --handle-background: #BC00FE;
    --handle-background-checked: #BC00FE;
    /* --handle-width: 28px;
    --handle-height: 28px; */
    /* --handle-max-height: auto;
    --handle-spacing: 6px;
    --handle-border-radius: 4px;
    --handle-box-shadow: none; */
    /* --handle-height: 20px; */
}

ion-toggle.iontg {
    /* padding: 12px; */
    --track-background: #888;
    --track-background-checked: fuchsia;
    --handle-background: #555;
    --handle-background-checked: #555;
    /* --handle-width: 28px;
    --handle-height: 28px; */
    /* --handle-max-height: auto;
    --handle-spacing: 6px;
    --handle-border-radius: 4px;
    --handle-box-shadow: none; */
    /* --handle-height: 20px; */
}

ion-toggle.grdcaltog::part(track),
ion-toggle.grdcaltog.toggle-checked::part(track) {
  height: 22px;
  width: 38px;

  /* background: #ddd; */

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  /* border-radius: 4px; */
}

.calic {
    font-size: 1.6rem;
}

.menuicncontalt {
    border-radius: 9px;
    /* background-color: black; */
    height: 35px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
    font-size: 1.2rem;
    /* font-weight: bold; */
    cursor: pointer;
    background-color: #222;
    border-radius: 20px;
    height: 42px;
    cursor: pointer;
    margin: 0 10px;
  }

  .menuicncontaltpp {
    border-radius: 9px;
    /* background-color: black; */
    height: 35px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    font-size: 1.2rem;
    /* font-weight: bold; */
    cursor: pointer;
    background-color: #222;
    border-radius: 20px;
    height: 42px;
    cursor: pointer;
    margin: 0 0 0 10px;
  }
  
  .menuicncontalt2 {
    border-radius: 9px;
    /* background-color: black; */
    height: 35px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
    font-size: 1.2rem;
    /* font-weight: bold; */
    cursor: pointer;
    background-color: #0BFFB6;
    border-radius: 20px;
    height: 42px;
    cursor: pointer;
    margin: 0 10px;

  }

  .menuicncontaltpp2 {
    border-radius: 9px;
    /* background-color: black; */
    height: 35px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
    font-size: 1.2rem;
    /* font-weight: bold; */
    cursor: pointer;
    background-color: #0BFFB6;
    border-radius: 20px;
    height: 42px;
    cursor: pointer;
    margin: 0 0 0 10px;

  }

  .negmg {
    margin-right: -10px;
  }

  .menicn2 {
    color: #0BFFB6;
    font-size: 1.6rem;
    /* margin-left: 10px; */
  }
  
  .menicn22 {
    color: #333;
    font-size: 1.6rem;
    /* margin-left: 10px; */
  }

  .filtcirc {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: #111;
    margin-left: 7px;
  }
  
  .filtcirc2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: white;
    margin-left: 7px;
  }

  .menicnfilt {
    color: #0BFFB6;
    font-size: 1rem;
    /* margin-left: 10px; */
  }
  
  .menicnfiltnum {
    color: #0BFFB6;
    font-size: .65rem;
    /* margin-left: 10px; */
  }

/* .tooltop {
    font-family: Montserrat;
    margin-bottom: 2px;
    color: grey;
    font-size: 0.8rem;
} */

.tooltop {
    font-weight: bold;
    font-family: Montserrat;
    margin-top: 2px;
    color: white;
    font-size: 1.3rem;
}

.toolbtm {
    font-weight: bold;
    font-family: Montserrat;
    margin-top: 2px;
    color: white;
    font-size: 1.2rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tlmr {
    margin-right: 50px;
}

.tlml {
    margin-left: 50px;
}


.toolbarsideele {
    height: 100%;
    /* width: 27%; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* border: 1px solid red; */
}

.toolbarcenterele {
    height: 100%;
    width: 46%;
    flex-grow: 1;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbarother {
    height: 100%;
    /* width: 46%; */
    /* flex-grow: 1; */
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbaricon2 {
    font-size: 1.9rem;
    color: fuchsia;
}

.toolbaricon3 {
    font-size: 2.2rem;
    color: fuchsia;
    margin-left: 7px;
    /* opacity: 0.4; */
}


.toolbariconfade2 {
    font-size: 2.2rem;
    color: fuchsia;
    margin-left: 7px;
    opacity: 0.4;
}

.toolbariconfeat {
    font-size: 1.5rem;
    color: fuchsia;
    margin: 0 3px;
    padding: 4px;
}

.toolbaricon {
    /* font-size: 1.5rem; */
    font-size: 1.6rem;
    /* color: #84FFF3; */
    color: fuchsia;
}

.toolbariconfade {
    /* font-size: 1.5rem; */
    font-size: 1.6rem;
    /* color: #84FFF3; */
    color: fuchsia;
    opacity: 0.4;
}

.btnposition {
    /* border: 1px solid yellow; */
    margin: 0 auto;
    background-color: transparent;
    color: transparent;
}

.toolbaricontransparent {
    font-size: 1.8rem;
    background-color: transparent;
    color: transparent;
    /* border: 1px solid red; */
    /* color: fuchsia; */
    /* margin-left: -22px; */
}

.headerlong {
    flex-grow: 1;
    /* border: 1px solid yellow; */
    /* height: fit-content; */
    padding-right: 1px;
}

.toolbaricontransparent2 {
    font-size: 1.7rem;
    /* color: transparent; */
    /* border: 1px solid red; */
    
}

.abscontionmenu {
    position: relative;
    /* position: absolute;
    width: 100%;
    height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: 100%;
    /* width: calc(100%/5); */
    /* width: 15%; */
    width: 42px;
    /* margin-top: 4px; */
    /* aspect-ratio: 1/.94; */
    /* border-radius: 7px; */

    

    /* background-color: #222; */
}

.abstlb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    /* position: absolute;
    width: 100%;
    height: 100%; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid red; */
    height: 100%;
    /* margin-top: 4px; */
    /* aspect-ratio: 1/.94; */
    /* border-radius: 7px; */

    z-index: 10;
    background-color: transparent;
    /* background-color: #222; */
}

.abscontionmenu2 {
    /* position: absolute; */
    /* width: 100%; */
    height: 100%;
    margin-top: 4px;
    aspect-ratio: 1/.94;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
    border-radius: 7px;
    background-color: #222;
}

.abscontionmenu3 {
    /* position: absolute; */
    /* width: 100%; */
    height: 100%;
    margin-top: 4px;
    aspect-ratio: 1/.94;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
    border-radius: 7px;
    background-color: #222;
}

.toolbariconmenu {
    font-size: 2.2rem;
    color: fuchsia;
    /* padding-right: 5px; */
    /* z-index: 10; */
}

.toolbariconmenuoth {

    font-size: 2.2rem;
    color: fuchsia;
    /* padding-right: 5px; */
    /* z-index: 10; */
}

.toolbariconmenualt {
    
    font-size: 1.6rem;
    color: fuchsia;
    /* padding-right: 5px; */
    /* border: 1px solid red; */
}

.toolbariconmenu2 {
    font-size: 2rem;
    color: fuchsia;
    /* padding-right: 5px; */
}

.toolbariconmenu3 {
    font-size: 1.6rem;
    color: fuchsia;
    /* padding-right: 5px; */
}

.toolbariconcontainer {
    position: relative;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.toolbariconcontaineralt {
    position: relative;
    /* width: 50px; */
    /* height: 100%; */
    height: 36px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid red; */
    border-radius: 7px;
    /* background-image: linear-gradient(
        0deg,
        #222, #333, #333, #222
    ); */
    /* background-color: #000; */
    /* box-shadow: 0 0 2px #FF00FF, 0 0 3px #FF00FF, 0 0 4px #FF00FF; */
}

.toolbariconcontaineralt2 {
    position: relative;
    width: 50px;
    height: 36px;
    /* margin-top: 3px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    border-radius: 7px;
    /* background-image: linear-gradient(
        0deg,
        #222, #333, #333, #222
    ); */
    /* background-color: #000; */
    /* box-shadow: 0 0 2px #FF00FF, 0 0 3px #FF00FF, 0 0 4px #FF00FF; */
}

.logocirc {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbariconcontfeat {
    /* position: relative;
    width: 48px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    
}



.toolbarcenterwordcontainer {
    height: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}






.chevroncontainerrotated {
    position: absolute;
    right: -10px;
    height: 100%;
    transform: rotate(180deg);
    font-family: Poppins;
}

.toolbarchevron {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .6rem;
    font-weight: 300;
    -webkit-transform:scale(2,1); /* Safari and Chrome */
    -moz-transform:scale(2,1); /* Firefox */
    -ms-transform:scale(2,1); /* IE 9 */
    -o-transform:scale(2,1); /* Opera */
    transform:scale(2,1); /* W3C */
    text-shadow:
    0 0 1px rgb(255, 0, 255),
    0 0 2px rgb(255, 0, 255),
    0 0 3px rgb(255, 0, 255),
    0 0 8px rgb(255, 0, 255),
    0 0 16px rgb(255, 0, 255),
    0 0 32px rgb(255, 0, 255)
    ;
}

.opaquetoolbar {
    opacity: 0.3;
}

.filtercounter {
    /* position: fixed; */
    position: absolute;
    top: -3px;
    right: 2px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.55rem;
    color: white;
    font-weight: bold;
    /* background-color: #E100FF; */
    background-color: #FF1493;
    z-index: 2;
    font-family: Gilroy;
  }






  @media (max-width: 320px) {
    .toolbarcenterwordmg {
        
        font-size: 1.1rem;
        letter-spacing: .01rem;
    }
       
  }


  /* ION PICKER */

  ion-picker {
    --border-color: #222 !important;
    /* --background: green !important; */
    /* background-color: #7df9ff; */
    font-family: Gilroy !important;
    /* font-size: 2.5rem !important; */
    color: fuchsia !important;
    /* --height: 400px !important; */
    /* text-transform: uppercase; */
    --fade-background-rgb: var(--ion-color-tertiary);
    /* --highlight-background: green; */
  }

  /* ion-picker-column-option {
    text-transform: uppercase;
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    
  } */

  @media (min-width: 1200px) {
   .toolbar2 {
    display: none;
   }
       
   .toolbar3 {
    position: relative;
    height: 56px;
    /* height: 100%; */
    /* height: 55px; */
    /* width: 92%; */
    width: 100%;
    padding: 10px;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    align-items: center;
    background-color: transparent;
    /* margin: 0 auto -2px auto; */
    margin: 0 auto;
    /* border: 1px solid red; */
    /* margin-bottom: -16px; */
}
  }