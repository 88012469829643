ion-modal.filtersmodalstd {
    /* --height: auto; */

    --width: 92%;
    --max-width: 550px;
    /* --height: auto; */
    /* --max-height: fit-content; */
    /* --height: fit-content; */
    --border-radius: 20px;
    --min-height: 200px;
  --max-height: 50%;
  /* --height: auto; */
    /* box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;     */
    /* --box-shadow: 0 0 5px fuchsia, 0 0 5px fuchsia, 0 0 5px fuchsia; */
    --background: transparent;
    
}

ion-modal.filtersmodalstd::part(backdrop) {
    background: rgba(0, 0, 0);
    /* opacity: .5; */
    --backdrop-opacity: 0.7;
}

/* changed above two from id to class because im reusing in different components */

ion-content.filtersmodalcontainer {
    --padding-top: 20px;
    --padding-bottom: 10px;
    --padding-end: 30px;
    --padding-start: 25px;
    --background: transparent;
    /* min-height: 200px; */
    /* background-image: linear-gradient(
        to top,
        #000, #111, #222
    ); */
    /* background-color: #111; */
    --background: rgba(0,0,0,.75);
    backdrop-filter: saturate(180%) blur(15px);

    position: relative;
}

.removeall {
    position: absolute;
    top: -20px;
    left: 0;
    height: 220px;
    width: 220px;
    border: 1px solid red;
}





.filtersgrid {
    display: grid;
    /* justify-items: center; */
    align-items: center;
    grid-template-columns: 50% 50%;
    margin-bottom: 10px;
    gap: 15px;
}

.filtersinnerflexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* padding: 0 15px; */
    padding-right: 5px;
    /* border: 1px solid red; */
}

.filtersinnerfbmenu {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0 5px;
}




ion-toggle {
    /* padding: 12px; */
    --track-background: #888;
    --track-background-checked: fuchsia;
    --handle-background: #333;
    --handle-background-checked: #333;
    /* --handle-width: 28px;
    --handle-height: 28px; */
    /* --handle-max-height: auto;
    --handle-spacing: 6px;
    --handle-border-radius: 4px;
    --handle-box-shadow: none; */
    /* --handle-height: 20px; */
    --handle-width: 18px;
    --handle-height: 18px;
}

ion-toggle::part(track) {
    height: 22px;
    width: 38px;
    /* border-radius: 12px; */
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }

.filternameheart {
    font-size: 1.35rem;
}

.menusectheader2 {
    
    padding: 10px 0 12px 0px;
    /* padding-left: 2px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    font-family: Montserrat;
   
    letter-spacing: .02rem;
    
   
  
    color: #999;
    font-size: .9rem;
  }

  .filtername2 {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-family: Gilroy;
    color: white;
    font-weight: bold;
    font-size: .95rem;
    padding: 0px 5px 0px 0;
}

@media (max-width: 374px) {
  

    ion-content.filtersmodalcontainer {
        --padding-top: 20px;
        --padding-bottom: 10px;
        --padding-end: 15px;
        --padding-start: 15px;
        --background: transparent;
        /* min-height: 200px; */
        /* background-image: linear-gradient(
            to top,
            #000, #111, #222
        ); */
        /* background-color: #111; */
        --background: rgba(0,0,0,.75);
        backdrop-filter: saturate(180%) blur(15px);
    
        position: relative;
    }

    .filtersgrid {
       
        gap: 8px;
    }

    .filtername2 {
        
        font-size: .8rem;
        padding: 8px 5px 8px 0;
    }

    .menusectheader2 {
    
        padding: 0 0 4px 0px;
       
        font-family: Montserrat;
       
        letter-spacing: .03rem;
        
       
      
        color: #999;
        font-size: .75rem;
      }

    /* ion-toggle {
     
        
        --handle-height: 14px;
        --handle-width: 14px;
    } */

    /* ion-toggle::part(track) {
        height: 10px;
        width: 25px;
       
        overflow: visible;
      } */
       /* --background: fuchsia; */
        /* Required for iOS handle to overflow the height of the track */



        ion-toggle {
            /* padding: 12px; */
            --track-background: #888;
            --track-background-checked: fuchsia;
            --handle-background: #333;
            --handle-background-checked: #333;
            /* --handle-width: 28px;
            --handle-height: 28px; */
            /* --handle-max-height: auto;
            --handle-spacing: 6px;
            --handle-border-radius: 4px;
            --handle-box-shadow: none; */
            /* --handle-height: 20px; */
            --handle-width: 15px;
            --handle-height: 15px;
        }
        
        ion-toggle::part(track) {
            height: 19px;
            width: 32px;
            /* border-radius: 12px; */
            /* Required for iOS handle to overflow the height of the track */
            overflow: visible;
          }

    }
