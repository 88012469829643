.splashscreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    z-index: 2;
    /* background: radial-gradient(
        circle at bottom left,
        #222,
        #111 30%,
        #000
    ) no-repeat; */
    /* border: 3px solid red; */
}

.splashscreen2 {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* position: relative; */
  z-index: 2;
  /* background: radial-gradient(
      circle at bottom left,
      #222,
      #111 30%,
      #000
  ) no-repeat; */
  /* border: 3px solid red; */
}

.fadeanim {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 3px solid red; */
    /* animation: fadeBackground;
    animation-duration: 3s; */
    animation: fadeBackground 3s;
    -webkit-animation: fadeBackground 3s;
    -moz-animation: fadeBackground 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeBackground {
    0% {
        opacity:1;
      }
      100% {
        opacity:0;
      }
  }

  @-moz-keyframes fadeBackground {
        0% {
        opacity:1;
      }
      100% {
        opacity:0;
      }
  }
  
  @-webkit-keyframes fadeBackground {
        0% {
        opacity:1;
      }
      100% {
        opacity:0;
      }
  }




.combineul {
  position: relative;
}

@media (max-width: 450px) {
 



.combineul {
  position: relative;
}
}

@media (max-width: 390px) {
  



.combineul {
  position: relative;
}
}

@media (max-width: 340px) {


.combineul {
  position: relative;
}
}

.thickline {
  /* position: relative; */
  /* border-bottom: 3px solid #0BFFB6; */
  /* position: relative;
  left: 10px;
  top: 10px; */
  height: 3px;
  width: 100%;
  background: -webkit-linear-gradient(300deg, #0BFFB6, fuchsia, #FF1493);
  margin-top: 10px;
}

.invisibleline {
  border-bottom: 4px solid transparent;
}

.tripink {
    /* height: 70px; */
    /* filter: 
   
     drop-shadow(0 0 35px fuchsia)
     drop-shadow(0 0 35px fuchsia)
  
    ; */

    width: 78%;
    max-width: 500px;
}

.tgalogo {
    width: 75%;
}

.mgbot {
  margin-bottom: 30px;
}

.triang {
  width: 40%;
}

.tricirc {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.triang2 {
  /* height: 35px; */
  height: 13px;
  margin-top: 10px;
  margin-bottom: 25px;
}

@media (min-width: 600px) {
  .triang2 {
    /* height: 35px; */
    height: 14px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

.opp {
  opacity: .4;
}

.pickcitybox {
    width: 90%;
    /* width: 50%; */
    /* max-width: 500px; */
    height: 100%;
    /* height: 45%; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;

    opacity: 0;
    /* animation: fadeIn;
    animation-duration: 2s; */
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    animation-fill-mode: forwards;
    animation-delay: 1s;

      /* border: 1px solid green; */
      /* border-left: 1px solid fuchsia; */

}

.image-container {
  width: 50vw; /* Half of the viewport width */
  width: 50%;
  height: 100vh; /* Full height of the viewport */
  height: 100%;
  overflow: hidden; /* Ensures the image does not overflow the container */
}

.image-container img {
  width: 100%; /* Ensure the image fills the container width */
  height: 100%; /* Ensure the image fills the container height */
  /* height: 400px; */
  object-fit: cover; /* Keeps the image's aspect ratio and covers the container */
  /* opacity: 0.5; */
}

ion-content.contmin {
  min-height: 100px;
  /* display: flex; */
}


.innerwrap {
  display: flex;
  flex-wrap: wrap;

}

.pickcity {
  /* height: 20%; */
  /* position: absolute;
  
  top: 15%; */
  /* padding-bottom: 5%; */
  width: 100%;
  
  font-family: Gilroy;
  font-size: 1.75rem;
  letter-spacing: 0.05rem;
  color: #fff;
  /* padding: 5px 0; */
  /* border: 1px solid green; */
  /* background-color: fuchsia; */
  /* opacity: 0; */
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 24px;
    /* animation: fadeIn;
    animation-duration: 2s; */
/* border-bottom: 1px solid fuchsia; */

    /* why need the below as well? */
    /* animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    animation-fill-mode: forwards;
    animation-delay: 1s; */

}

.pinkline {
  width: 75%;
  border: 1px solid fuchsia;
  margin-bottom: 20px;
}

.selcit {
  margin-bottom: 25px;
  font-family: Gilroy;
  color: #949494;
  font-size: 1.1rem;
  /* border: 1px solid green; */
  width: 90%;
  text-align: center;

}

.cityonethrd {
  /* padding: 20% 0 16% 0; */
  padding: 50px 20px;
  height: 100%;
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-direction: column;
    /* justify-content: space-between; */
    /* justify-content: center; */
    /* align-items: center; */
    align-items: start;
}

.onethrdinner {
  /* padding: 20% 0; */
  height: 80%;
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-direction: column;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
}

.blankthird {
  height: 13%;
/* border: 1px solid green; */

}

.contbtm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
    /* border: 1px solid green; */
  /* height: 20%; */
  padding-top: 30px;
}

.firstcity {
  font-family: Montserrat;
    /* font-weight: bold; */
  font-size: 1.8rem;
  color: fuchsia;
  filter:
   /* drop-shadow(0px 0px 35px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
   drop-shadow(0px 0px 10px fuchsia)
   drop-shadow(0px 0px 25px fuchsia)
   /* drop-shadow(0px 0px 40px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
  ;

  letter-spacing: 0.2rem;


  /* margin: 20px 0; */
}

.firstcitysyd {
  font-family: Montserrat;
    /* font-weight: bold; */
  font-size: 1.8rem;
  color: fuchsia;
  filter:
   /* drop-shadow(0px 0px 35px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
   drop-shadow(0px 0px 10px fuchsia)
   drop-shadow(0px 0px 25px fuchsia)
   /* drop-shadow(0px 0px 40px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
  ;

  letter-spacing: 0.3rem;
  /* margin: 20px 0; */
}

.citybb {
  font-family: Gilroy;
    /* font-weight: bold; */
  font-size: 1.2rem;
  color: #ccc;
  margin-bottom: 12px;
  margin-right: 10px;
  padding: 10px 17px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #444;
  border-radius: 20px;

  /* letter-spacing: 0.3rem; */
  /* margin: 20px 0; */
  cursor: pointer;
}

.citybb2 {
  font-family: Gilroy;
    font-weight: bold;
  font-size: 1.1rem;
  /* background-color: #222; */
  color: fuchsia;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 16px;
  /* width: 100%; */
  width: fit-content;

  border: 1px solid fuchsia;
  border-radius: 20px;

  /* letter-spacing: 0.3rem; */
  /* margin: 20px 0; */
}

.citybb.addpnk {
  color: fuchsia;
  border: 1px solid fuchsia;
  font-weight: bold;
}

.pinkball {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: fuchsia;
  /* margin: 20px 0; */
}

@media (max-width: 340px) or (max-height: 600px) {
  /* .pickcity {
    font-size: 0.85rem;
  } */

  /* .firstcitysyd, .firstcity {
    font-size: 1.4rem;
  } */

}

@media (max-height: 400px) {
  .pinkball {
    display: none;
  }
}




@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.suspendscreen {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    z-index: 2;
    background: radial-gradient(
        circle at bottom left,
        #444,
        #222 30%,
        #000
    ) no-repeat;

    z-index: 10;
}


  .suspendt {
    margin-bottom: 4px;
  }

  .suspendb {
    margin-top: 4px;
  }

  .promoscreen {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    z-index: 2;
    /* background: rgba(0,0,0,0.5); */
    background: transparent;

    z-index: 9;
}



  .promobox {
    width: 85%;
    height: 60%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    border-radius: 20px;
    box-shadow: 1px 1px 0px 0px rgba(36,36,36,0.9),
              1px 2px 4px 0px rgba(36,36,36,0.5),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;
    background: radial-gradient(
        circle at bottom left,
        #444,
        #222 30%,
        #000
    ) no-repeat;
    border: 3px solid #7df9ff;
}




.promoimg {
    object-fit: cover;
    /* width: 80%; */
    height: 40%;
    border-radius: 20px;
    aspect-ratio: 1/1;
    /* width: 100%;
    height: 100%; */
}


.promobtnbox {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.loadingdiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  letter-spacing: 0.15rem;
  font-size: 1.2rem;
  /* border: 4px solid red; */
  font-family: Montserrat;
}

.absloadingbox {
  /* position: absolute; */
  /* top: 400%; */
  /* right: 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid red;
}

.absloadingtxt {
  font-family: Gilroy;
  color: #ccc;
  font-weight: 500;
  font-size: 1.3rem;
  /* margin-top: 30px; */
  /* width: 78%; */
  padding: 11%;
  display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 415px) {
  /* .absloadingbox {
    position: absolute;
    bottom: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  .absloadingtxt {
  
    font-size: 1.2rem;
  
  }
}

@media (max-width: 390px) {
  /* .absloadingbox {
    position: absolute;
    bottom: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  .absloadingtxt {
   
    font-size: 1.1rem;
   
  }

  .triang2 {
    /* height: 35px; */
    height: 12px;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .absloadingtxt {
  
    font-size: 1rem;
   
  }

  .pickcity {
 
    font-size: 1.6rem;
    letter-spacing: 0.04rem;
    
    margin-bottom: 24px;
  
  
  }

  .citybb {
  
    font-size: 1.1rem;
   
    padding: 9px 15px;
   
  }

  
}

@media (max-width: 325px) {
  /* .absloadingbox {
    position: absolute;
    bottom: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  .triang2 {
    /* height: 35px; */
    height: 11px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .absloadingtxt {
  
    font-size: 1rem;
   
  }

  .pickcity {
 
    font-size: 1.5rem;
    letter-spacing: 0.02rem;
    
    margin-bottom: 24px;
  
  
  }

  .citybb {
    font-family: Gilroy;
      /* font-weight: bold; */
    font-size: 1rem;
    color: #ccc;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 9px 15px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #444;
    border-radius: 20px;
  
    /* letter-spacing: 0.3rem; */
    /* margin: 20px 0; */
    cursor: pointer;
  }
  
}

ion-spinner {
  /* width: 38px;
  height: 38px; */
  width: 20%;
  stroke: fuchsia;
  fill: fuchsia;
  color: fuchsia;
  stroke: #777;
  fill: #777;
  color: #777;
  
}

ion-alert.customalert {
  --backdrop-opacity: 0.4;
  --background: #333;
}

ion-modal.custommod {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 15px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.updatebox {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
}

.updatehead {
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: bold;
  color: fuchsia;
  text-align: center;
  margin-bottom: 10px;
}

.updatemsg {
  font-family: Montserrat;
  font-size: 1.1rem;
  line-height: 1.8rem;
}


.abstribox {

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  height: calc(100%/3);

}

.downloadtxt {
  font-size: 1.2rem;
  color: grey;
  font-family: Montserrat;
}

.splashupper {
  /* position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%; */
  /* border: 4px solid orange; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: transparent; */
  /* z-index: 4; */
  /* border: 5px solid #444; */
  height: calc(100%/3);
}

.splashlower {
  /* position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%; */
  /* border: 4px solid orange; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* background-color: transparent; */
  /* z-index: 4; */
  /* border: 5px solid #444; */
  height: calc(100%/3);
}

.loadingtxt {
  font-family: Montserrat;
  letter-spacing: 0.03rem;
  font-size: 1.45rem;
  padding: 0 0 30px 0;
  color: #555;
}

.containerforprogressbar {
  height: 5px;
  width: 340px;
  background-color: #222;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12)
  ;
  /* border-radius: 3px solid green; */
}

.outerbar {
  width: 300px;
  background-color: #777;
}

.innerbar {
  background-color: fuchsia;
  height: 100%;
  /* z-index: 443; */
  
  /* width: 100%; */
}





ion-radio-group {
  font-size: 1.1rem;
  font-family: Gilroy;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* border: 2px solid red; */
  width: 100%;
  /* height: 240px; */
}

ion-radio {
  height: 60px;
}

ion-radio::part(container) {
  width: 30px;
  height: 30px;

  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: #6815ec;
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}

.contarrow {
  font-size: 1.8rem;
  color: white;
  /* margin-left: 4px; */

  /* transform: rotate(-45deg); */

  /* margin-top: 1px; */
}

.contarrow.small {
  color: #444;
}

.splashbtn {
  /* width: 77%; */
  /* width: 100%; */
  /* height: 50px; */
  

  /* border-radius: 50px 8px; */
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .95rem;
  font-weight: bold;
  color: white;
  letter-spacing: 0.03rem;
  text-shadow: .5px .5px 0 #333;
  /* background-image: linear-gradient(
      90deg,
      #FF007A 50%, #FF00FF 70%, #14EAFC
      ); */
      /* background-image: linear-gradient(
      0deg,
      #000, #111, #222, #444
  ); */
  background-color: #222;
  /* border: 3px solid #0BFFB6; */
      /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
      1px 3px 6px 0px rgba(36,36,36,0.7),
      2px 4px 8px 0px rgba(0,0,0,0.7)
  ; */
  font-family: Montserrat;
  /* transform: skew(-15deg); */
  padding: 15px 0 17px 0;



  /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */

}

.splashbtn2 {
  /* width: 77%; */
  /* width: 100%; */
  /* height: 50px; */
  

  /* border-radius: 50px 8px; */
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .95rem;
  font-weight: bold;
  color: white;
  letter-spacing: 0.03rem;
  text-shadow: .5px .5px 0 #333;
  /* background-image: linear-gradient(
      90deg,
      #FF007A 50%, #FF00FF 70%, #14EAFC
      ); */
      /* background-image: linear-gradient(
      0deg,
      #000, #111, #222, #444
  ); */
  background-color: fuchsia;
  /* border: 3px solid #0BFFB6; */
      /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
      1px 3px 6px 0px rgba(36,36,36,0.7),
      2px 4px 8px 0px rgba(0,0,0,0.7)
  ; */
  font-family: Montserrat;
  /* transform: skew(-15deg); */
  padding: 15px 0 17px 0;

  cursor: pointer;

  /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */

}


@media (max-height: 625px) {
  /* .pickcitybox {
    width: 90%;
    height: 100%;
  
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;



} */

/* .pickcity {

  width: 100%;
  display: flex;
  flex-direction: column;
 
    justify-content: end;

  font-family: Gilroy;
  font-size: 1.3rem;
  letter-spacing: 0.05rem;
  color: white;

  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;


} */

/* .triang2 {
  height: 10px;
  margin-bottom: 20px;
} */

.selcit {
  margin-bottom: 18px;
  font-family: Gilroy;
  font-size: .95rem;
  text-align: center;
}

/* .citybb {
  font-family: Gilroy;

  font-size: .95rem;
  color: white;
  margin-bottom: 6px;
  padding: 9px 12px;

  border-radius: 8px;


} */

/* .citybb2 {
  font-family: Gilroy;

  font-size: .95rem;
  background-color: #222;
  color: fuchsia;
  margin-bottom: 6px;
  padding: 9px 12px;

  border-radius: 8px;
  font-weight: bold;

} */

/* .cityonethrd {
  padding: 50px 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.onethrdinner {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.splashbtn {
 
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .95rem;
  font-weight: bold;
 
  letter-spacing: 0.03rem;
  text-shadow: .5px .5px 0 #333;
 
  background-color: #222;
 
  font-family: Montserrat;
  padding: 15px 0 17px 0;


}

.splashbtn2 {
  
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .95rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  text-shadow: .5px .5px 0 #333;
  
  background-color: fuchsia;
  
  font-family: Montserrat;
  padding: 15px 0 17px 0;
} */

.contarrow {
  font-size: 1.5rem;
  color: white;
  /* margin-left: 4px; */

  /* transform: rotate(-45deg); */

  /* margin-top: 1px; */
}

  ion-radio-group {
    font-size: 1.1rem;
    font-family: Gilroy;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* border: 2px solid red; */
    width: 100%;
    /* height: 240px; */
  }
  
  ion-radio {
    height: 40px;
  }
  
  ion-radio::part(container) {
    /* width: 30px;
    height: 30px;
  
    border-radius: 8px;
    border: 2px solid #ddd; */
  }
  
  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }
  
  ion-radio.radio-checked::part(container) {
    /* background: fuchsia; */
    border-color: transparent;
  }
  
  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
  
    
    margin-bottom: 7px;
    /* border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff; */
  
    transform: rotate(45deg);
  }

}



ion-picker {
  --highlight-background: var(--ion-color-dark);
  --highlight-border-radius: 50px;

  --fade-background-rgb: var(--ion-color-light-rgb);
}

ion-picker-column-option {
  text-transform: uppercase;
  /* background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto; */
  color: #fff;
  /* background-clip: text; */
  /* text-fill-color: transparent; */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite; */
}


.pk {
  color: fuchsia;
}