/* PARTIES PAGE */

.segmentcon {
    position: relative;
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.segment-inner-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* font-size: .9rem; */
    
}

ion-segment#check {
    /* --background: rgba(56,56,56,.7); */
    --background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(15px);
    /* background-color: transparent; */
    
}

ion-segment-button {
    /* --indicator-color: rgb(255,0,255); */
    --indicator-color: rgba(36,36,36,.65);
    /* backdrop-filter: saturate(180%) blur(20px); */
    /* color: white; */

    font-family: Montserrat;
    font-weight: bold;
    letter-spacing: 0.04rem;
    font-size: .8rem;
    
    /* --background: transparent; */
    
}

ion-label {
    margin-top: 3px;
    text-transform: none;
}

ion-segment-button::part(indicator-background) {
    /* background: #08a391;
    background: #333;
    background-color: #999; */
    /* background: #FF1493; */
    /* background-color: #39FF14; */
    /* border: 5px solid fuchsia; */
}
    
/* Material Design styles */
/* ion-segment-button.md::part(native) {
    color: #000;
}
    
.segment-button-checked.md::part(native) {
   
    color: fuchsia;
    
}
    
ion-segment-button.md::part(indicator-background) {
   
} */
    
/* iOS styles */
ion-segment-button.ios::part(native) {
    /* color: fuchsia; */
    /* --color: #D4D4D4; */
    font-weight: 500;
    border-top: 3px solid transparent;
    color: white;
}

ion-segment-button.ios {
    
    /* --color-checked: #fff; */

  }
    
.segment-button-checked.ios::part(native) {
    /* color: #fff; */
    color: fuchsia;
    border-top: 3px solid fuchsia;
    font-weight: bold;
}
    
ion-segment-button.ios::part(indicator-background) {
    /* border-radius: 20px; */
    /* border-top: 2px solid #444; */
}



@media (min-width: 1200px) {
    .segmentcon {
        display: none;
    }
    

}