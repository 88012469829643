ion-toolbar.searchtoolbar {
    --background: rgba(36,36,36,1);
    /* backdrop-filter: saturate(180%) blur(10px); */

}

/* ion-modal.searchmodal {
    border-radius: 20px;
} */

.searchtoolbardiv {
    margin: 0 auto;
    height: 78px;
    /* the above should match other Pages headers */
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
}

.searchtoolbardiv2 {
    /* height: 53px; */
    margin: 0 auto;
    /* height: 75px; */
    /* the above should match other Pages headers */
    
    

    /* height: 75px; */
    height: 100%;
    width: 92%;
    
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    /* align-items: center; */
    background-color: transparent;
    /* margin: 0 auto -10px auto; */
    
    /* margin-bottom: -16px; */
}

ion-searchbar.searchbarparties {
    font-family: Gilroy;
    /* font-size: 0.5rem; */
    /* padding: 0 0 10px 0; */
    /* --margin: 0;
    --padding: 0; */
    /* height: 100%; */
    /* width: 80%; */
    /* display: flex;
    align-items: center; */
    /* padding-top: 0;
    padding-bottom: 0; */
    padding: 0;
    --icon-color: #999;
    color: #ccc;
    /* --icon-size: 0.4rem; */
    /* height: 52px; */
    --background: #333;
    --border-radius: 9px;

    /* border: 1px solid red; */
}
/* the above was an id before so check still works */

ion-searchbar.searchbarpartiesweb {
    font-family: Gilroy;
    /* font-size: 0.5rem; */
    /* padding: 0 0 10px 0; */
    /* --margin: 0;
    --padding: 0; */
    /* height: 100%; */
    /* width: 80%; */
    /* display: flex;
    align-items: center; */
    /* --height: 35px;
    height: 36px;
    --max-height: 36px; */

    /* padding-top: 0;
    padding-bottom: 0; */
    padding: 0;
    /* --padding-top: 0px; */
    --icon-color: #999;
    color: #ccc;
    /* --icon-size: 0.4rem; */
    /* height: 52px; */
    --background: #222;
    --border-radius: 25px;

    /* border: 1px solid red; */
}
/* the above was an id before so check still works */

ion-searchbar.searchbarpartiesweb2 {
    font-family: Gilroy;
    /* font-size: 0.5rem; */
    /* padding: 0 0 10px 0; */
    /* --margin: 0;
    --padding: 0; */
    /* height: 100%; */
    /* width: 80%; */
    /* display: flex;
    align-items: center; */
    /* padding-top: 0;
    padding-bottom: 0; */
    padding: 0;
    --icon-color: #fff;
    color: #fff;
    /* --icon-size: 0.4rem; */
    /* height: 52px; */
    --background: fuchsia;
    --border-radius: 25px;

    /* border: 1px solid red; */
}

@media (max-width: 1199px) {
.searchbar-input {
    font-size: .9rem !important;
    padding-left: 50px !important;
}
}

@media (min-width: 1200px) {
    .searchbar-input {
        font-size: .95rem !important;
        /* padding-left: 40px !important; */
    }
    }

ion-icon.searchbar-search-icon {
    font-size: 0.5rem !important;
    /* background-color: green; */
    /* height: 3px; */
    /* margin-right: 2px; */
    /* --font-size: 0.4rem; */
}

ion-buttons.closebutton {
    /* padding-left: 10px; */
    color: white;
    padding: 0;
    justify-content: end;
    /* text-align: end !important; */
    font-family: Gilroy;
}
/* the above was id before so check still works */

ion-button.inncl {
    font-family: Montserrat;
    font-size: 0.9rem;
    font-weight: bold;
    /* text-align: end !important; */
    padding-left: 3px;
}

.searchmodalflexbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-radius: 20px; */
}


.searchtoolbarweb {
    /* margin: 0 auto; */
    height: 100%;
    /* height: 90%; */
    /* the above should match other Pages headers */
    /* width: 50%; */
    /* padding: 0 10px; */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid yellow; */
}

.misctitle {
    font-family: Gilroy;
    font-weight: bold;
}