/* Header */
.landing-header {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    padding: 0 20px;
    /* position: absolute; */
    /* top: 0; */
    width: 100%;
    height: 55px;
    background-color: #111;
    border-bottom: 1px solid fuchsia;
  }

  .landing-header-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px; */
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    /* border: 1px solid red; */
  }
  
  .logo {
    height: 17px;
  }
  
  /* .buy-now-button {
    background-color: #be29ec;
    border-radius: 20px;
    color: white;
    font-size: 16px;
  } */

  .twobtncont {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .instagram-button {
    height: 68%;
    width: 145px;
    font-family: Montserrat;
    font-weight: bold;
    background-color: #111; /* Dark grey background */
    color: fuchsia; /* Neon text */
    border: 1px solid fuchsia;
    border-radius: 30px;
    /* padding: 0 24px; */
    font-size: 1.1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px; /* Space between icon and text */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .instagram-button:hover {
    background-color: fuchsia; /* Neon effect on hover */
    color: white; /* Dark text on hover */
  }
  
  .instagram-icon {
    font-size: 20px; /* Adjust size of the Instagram icon */
    margin-bottom: 2px;
  }

  .web-button {
    height: 68%;
    width: 145px;
    font-family: Montserrat;
    font-weight: bold;
    background-color: fuchsia; /* Dark grey background */
    color: white; /* Neon text */
    border: 1px solid fuchsia;
    border-radius: 30px;
    /* padding: 0 24px; */
    font-size: 1.05rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 9px; */
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-left: 10px;
  }

  .web-icon {
    font-size: 19px; /* Adjust size of the Instagram icon */
    /* margin-bottom: 2px; */
    margin-left: 4px;
    margin-top: 2px;
  }
  
  /* Main Container */
  .main-container {
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 55px);
    /* height: 100vh; */
    /* max-height: 780px; */
    max-height: 615px;
    max-width: 1440px;
    margin: 0 auto;
    /* padding: 0 20px; */
    position: relative;
    overflow: hidden;
    /* border: 1px solid red; */
  }


.left-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: center; */
  max-width: 50%; /* Left side takes up 50% of the screen */
  /* border: 4px solid red; */
  height: 100%;
  max-height: 100%;
  /* height: calc(100vh - 55px); */
  /* position: relative; */
  /* top: 55px; */
  padding-left: 40px;
}
  
  .text-content {
    flex: 1;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    position: relative;
    /* border: 1px solid red; */
    /* max-height: 50%; */
  }
   
  
  .text-content h1 {
    font-family: 'Montserrat Semi Bold';
    font-size: 3.5rem;
    line-height: 5rem;
    /* font-weight: 600; */
    color: white;
    margin-bottom: 0px;
  }
  
  .text-content h1 span {
    font-family: 'Montserrat Extra Bold';
  }
  
  /* .stats {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin-bottom: 20px;
  }
  
  .stats div {
    text-align: center;
  }
  
  .stats p {
    font-size: 1rem;
    color: white;
  }
  
  .stats h2 {
    font-size: 2.5rem;
    color: #e61ed8;
  }
  
  .subtext {
    font-size: 1.2rem;
    color: #e61ed8;
    margin-bottom: 20px;
  } */

  .categories {
    /* color: fuchsia; */
    background: linear-gradient(45deg, #FF1493, #FF00FF);
    -webkit-background-clip: text;
    color: transparent; /* This makes the gradient visible on the text */
    font-family: Gilroy;
    letter-spacing: 0.04rem;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-top: 20px;
    /* font-weight: bold; */
  }
  
  .download-section {
    margin-top: 50px;
    /* text-align: center; */
    margin-bottom: 44px; /* Adjust the bottom margin as needed */
  }
  
  .download-text {
    font-family: Gilroy;
    font-weight: bold;
    font-size: 1.55rem; /* Adjust size for visibility */
    margin-bottom: 30px;
  }
  
  .download-buttons {
    display: flex;
    /* justify-content: center; */
    /* gap: 30px; */
    /* position: relative; */
    left: -14px;
    /* border: 1px solid red; */
    position: relative;
    max-width: 600px;
  }
  
  /* .download-buttons {
    display: flex;
    gap: 20px;
  } */

  .invleft {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 50%;
    max-width: 280px;
    height: 100px;
    /* border: 1px solid yellow; */
    max-height: 80px;
    cursor: pointer;
  }

  .invright {
    position: absolute;
    /* flex-grow: 1; */
    top: 0;
    right: 0;
    z-index: 10;
    width: 50%;
    max-width: 400px;
    height: 100px;
    /* border: 1px solid yellow; */
    max-height: 80px;
    cursor: pointer;
  }
  
  .download-img {
    width: 550px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Add shadow for contrast */
  }
  
  .image-container {
    flex: 1;
    position: relative;
  }
  
  .iphone-image {
    width: 120%;       /* Increase the width to make the image larger */
    max-width: none;   /* Remove the max-width restriction */
    height: auto;      /* Maintain aspect ratio */
  }


  
  /* Breakpoint to move image behind text */


 

/* Pink strip below main content */
/* .pink-strip {
  background-color: #e61ed8;
  height: 30vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.pink-strip .section {
  flex: 1;
  text-align: center;
  color: white;
  padding: 10px;
}

.pink-strip .section:not(:last-child) {
  margin-right: 10px;
}

.pink-strip .icon {
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
}

.feature-heading {
  font-size: 1.2rem;
  font-family: Montserrat;
  font-weight: bold;
}

.pink-strip p {
  font-size: .95rem;
  line-height: 1.4;
  font-family: Gilroy;
} */

/* @media (max-width: 768px) {
  .pink-strip {
    flex-direction: column;
    height: auto;
    padding: 20px 0;
  }

  .pink-strip .section {
    margin-bottom: 20px;
  }

  .pink-strip .section:not(:last-child) {
    margin-right: 0;
  }
} */



/* Wrapper to handle the scrolling content */
.pink-strip-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  background-color: #e61ed8;
  padding: 20px 0;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.icon {
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
}

.feature-heading {
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.4;
  margin: 0 10px;
}

/* Bottom section with background #111 */
.bottom-section {
  background-color: #111;
  height: 75vh; /* Three-quarters of the main-container */
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px;
  color: white; /* Text color */
  overflow: hidden;
}

/* Text content that appears in the top third */
.text-content-bottom {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  text-align: center;
  font-size: 1.2rem; /* Medium-sized font */
  line-height: 1.6;
  z-index: 2;
}

/* Image container for the bottom of the section */
.image-container-bottom {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0; /* Align the image container to the bottom */
  width: 100%;
  height: auto; /* Let the image container adjust its height */
}

.bottom-image {
  width: auto;
  height: 500px; /* Set a fixed height for the image */
  max-height: 66%; /* Ensure it doesn't exceed two-thirds of the section */
  object-fit: contain; /* Maintain aspect ratio */
}


.footer {
  border-top: 1px solid fuchsia;
  background-color: #111111;
  color: #cccccc;
  text-align: center;
  padding: 20px 20px 18px 20px;
  font-size: 0.9rem;
}

.footer a {
  color: #cccccc;
  text-decoration: underline;
}

.footer a:hover {
  color: fuchsia; /* Neon pop for hover effect */
}


.notif-section {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 550px; /* Almost the same height as main-content */
  /* padding: 40px 20px; */
  background-color: #000000;
}

/* Left and Right Sections */
.notif-left, .notif-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Right Section */
.notif-right {
  flex-direction: column;
  color: white;
  padding: 0 80px;
  text-align: center;
}

.icon-circle {
  /* background-color: fuchsia; */
  border: 2px solid fuchsia;
  border-radius: 50%; /* Circle shape */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; /* Size of the circle */
  height: 80px;
  margin-bottom: 20px; /* Space between the circle and header */
}

.notif-icon {
  font-size: 3rem; /* Adjust icon size */
  color: fuchsia; /* White icon */
}

.notif-bell {
  height: 80px;
}

.notif-right h1 {
  font-family: Montserrat;
  font-size: 3rem;
  color: white;
  font-weight: bold;
  margin-bottom: 25px; /* Space between header and paragraph */
}

.notif-right h1 span {
  color: fuchsia;
}

.notif-right p {
  font-family: Gilroy;
  font-size: 1.6rem;
  line-height: 2.6rem;
  /* line-height: 1.6; */
  max-width: 600px; /* Keep text block from stretching too wide */
}

.phone-container {
  position: relative;
  display: flex;
  justify-content: center; /* Center notifications horizontally */
  align-items: center; /* Ensure they are aligned vertically based on percentage */
}

.phone-image {
  height: 500px;
  width: auto;
}

.notification-box {
  position: absolute;
  width: 80%;
  height: 100px;
  background-color: #222222;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  padding: 15px;
  color: white;
  /* text-align: center; */
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  /* No need for left and translate, they are centered by the flexbox container */
}

.notification-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(10deg, #FF1493, #FF00FF);
  -webkit-background-clip: text;
  color: transparent; /* This makes the gradient visible on the text */
}

.notification-header img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.notification-content {
  font-size: 0.9rem;
  display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
}

.notification-content span {
  font-size: 0.9rem;
  font-weight: bold;
  /* color: fuchsia; */
}

.box1 {
  top: 120px; /* Evenly spaced over the height of the iPhone image */
}

.box2 {
  top: 235px; /* Adjusted positioning */
}

.box3 {
  top: 350px; /* Adjusted positioning */
}

/* Animation for notifications appearing */
@keyframes showBox {
  0% { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
}

.box1.appear { animation: showBox 0.3s forwards; }
.box2.appear { animation: showBox 0.4s forwards; }
.box3.appear { animation: showBox 0.5s forwards; }






@media (max-width: 1370px) {
  .text-content h1 {
    font-size: 3.3rem;
    line-height: 4.2rem;
  }

  .categories {
    line-height: 2.7rem;
    letter-spacing: 0.04rem;
    font-size: 1.75rem;
    
  }

  .notif-right h1 {
   
    font-size: 2.85rem;
   
   
  }
  

  
  .notif-right p {
    
    font-size: 1.5rem;
    line-height: 2.5rem;
   
  }

  

  .download-text {
    
    font-size: 1.5rem; /* Adjust size for visibility */
  
  }
  
}

@media (max-width: 1290px) {
  .text-content h1 {
    font-size: 3.2rem;
  }

  .categories {
   
    letter-spacing: 0.04rem;
    font-size: 1.7rem;
    line-height: 2.6rem;
  }

  .notif-right h1 {
   
    font-size: 2.8rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.4rem;
    line-height: 2.25rem;
   


  }

  

 
}

@media (max-width: 1220px) {
  .text-content h1 {
    font-size: 3.1rem;
  }

  .categories {
   
    letter-spacing: 0.04rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .notif-right h1 {
   
    font-size: 2.5rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.35rem;
    line-height: 2.2rem;
   
  }

  .download-text {
    
    font-size: 1.45rem; /* Adjust size for visibility */
  
  }
}


@media (max-width: 1024px) {
  .notif-section {
    padding-top: 5px;
    flex-direction: column-reverse; /* Stack right above left */
    height: auto; /* Allow height to adjust based on content */
  }

  .notif-right, .notif-left {
    padding: 15px 0 0 0; /* Add some padding for separation */
    align-items: center;
  }

  .notif-right {
    padding: 20px;
  }

  .notif-bell {
    display: none;
  }
  
  .main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* position: relative; */
    /* border: 4px solid yellow; */
  }

  .left-wrapper {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* justify-content: center; */
    max-width: 90%; /* Ensure the text doesn't stretch too wide on smaller screens */
    /* border: 4px solid red; */
    height: 100%;
    max-height: 100%;
    /* height: calc(100vh - 55px); */
    /* position: relative; */
    /* top: 55px; */
    padding-left: 0px;
    /* border: 3px solid green; */
    z-index: 2;
  }

  .text-content {
    position: relative;
    z-index: 2;
    
    margin: 0 auto;
    /* border: 1px solid red; */
  }

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .iphone-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the background area */
    opacity: 0.3; /* Reduce the opacity to create the overlay effect */
  }

  /* Overlay effect for text readability */
  .left-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); /* Dark overlay for readability */
    z-index: 1;
  }

  .text-content h1 {
    font-size: 3.4rem;
    z-index: 2;
  }

  .categories {
    font-weight: bold;
    z-index: 2;
    font-size: 1.8rem;
    line-height: 2.7rem;
    letter-spacing: 0.03rem;
  }

  .stats {
    flex-direction: column;
  }

  .stats div {
    margin-bottom: 10px;
  }

  .buy-now-button {
    align-self: center;
  }


  .download-text {
  
    font-size: 1.5rem; /* Adjust size for visibility */
  
  }

 

}


@media (max-width: 890px) {

  .text-content h1 {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

@media (max-width: 690px) {
  .text-content h1 {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }

  .categories {
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.6rem;
    line-height: 2.6rem;
    letter-spacing: 0.02rem;
  }


  .download-text {
    
    font-size: 1.5rem; /* Adjust size for visibility */
  
  }

  .notif-right h1 {
   
    font-size: 2.3rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.25rem;
    line-height: 2rem;
   
  }
}

@media (max-width: 600px) {

    .logo {
        height: 15px;
      }

    .instagram-button {
        width: 132px;
        /* padding: 0 20px; */
        font-size: 1rem;
       
        gap: 9px; /* Space between icon and text */
       
      }
      
    
      .instagram-icon {
        font-size: 20px; /* Adjust size of the Instagram icon */
        margin-bottom: 2px;
      }
    
      .web-button {
       
        width: 132px;
       
        font-size: 1rem;
        margin-left: 6px;
      }
    
      .web-icon {
        font-size: 20px; /* Adjust size of the Instagram icon */
        /* margin-bottom: 2px; */
        margin-left: 3px;
        margin-top: 1px;
      }


  .text-content h1 {
    font-size: 3rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.5rem;
    line-height: 2.6rem;
    letter-spacing: 0.01rem;
  }

  .download-text {
  
    font-size: 1.35rem; /* Adjust size for visibility */
  
  }

  .notif-right h1 {
   
    font-size: 2.3rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.25rem;
    line-height: 2rem;
   
  }
}


@media (max-width: 560px) {
    .logo {
        height: 13px;
      }

    .instagram-button {
        height: 67%;
        width: 115px;
        /* padding: 0 20px; */
        font-size: .9rem;
       
        gap: 8px; /* Space between icon and text */
       
      }
      
    
      .instagram-icon {
        font-size: 17px; /* Adjust size of the Instagram icon */
        margin-bottom: 0px;
      }
    
      .web-button {
        height: 67%;
        width: 115px;
       
        font-size: .9rem;
        margin-left: 6px;
      }
    
      .web-icon {
        font-size: 17px; /* Adjust size of the Instagram icon */
        /* margin-bottom: 2px; */
        margin-left: 3px;
        margin-top: 1px;
      }

  .text-content h1 {
    font-size: 2.9rem;
    line-height: 4.2rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.01rem;
  }

  .download-text {
  
    font-size: 1.3rem; /* Adjust size for visibility */
  
  }

  .notif-right h1 {
   
    font-size: 2.2rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.2rem;
    line-height: 1.8rem;
   
  }
}


@media (max-width: 500px) {
    .logo {
        height: 13px;
      }

    .instagram-button {
        height: 63%;
        width: 107px;
        /* padding: 0 20px; */
        font-size: .85rem;
       
        gap: 8px; /* Space between icon and text */
       
      }
      
    
      .instagram-icon {
        font-size: 16px; /* Adjust size of the Instagram icon */
        margin-bottom: 0px;
      }
    
      .web-button {
        height: 63%;
        width: 107px;
       
        font-size: .82rem;
        margin-left: 6px;
      }
    
      .web-icon {
        font-size: 16px; /* Adjust size of the Instagram icon */
        /* margin-bottom: 2px; */
        margin-left: 2px;
        margin-top: 1px;
      }

  .text-content h1 {
    font-size: 2.7rem;
    line-height: 4rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.3rem;
    line-height: 2.3rem;
    letter-spacing: 0.01rem;
  }

  .download-text {
  
    font-size: 1.25rem; /* Adjust size for visibility */
  
  }


.phone-image {
  height: 480px;
  width: auto;
}

  .notif-right h1 {
   
    font-size: 2.1rem;
   
  }

  
  .notif-right p {
    
    font-size: 1.1rem;
    line-height: 1.7rem;
   
  }

  .notification-box {
   
    /* width: 75%; */
   
  }

  .notification-header {
    
    font-size: 0.9rem;
  }

  .notification-content {
    font-size: 0.85rem;
  }
  
  .notification-content span {
    font-size: 0.85rem;
    /* color: fuchsia; */
  }
}

@media (max-width: 455px) {
  .logo {
    height: 12px;
  }

  .disapp {
    display: none;
  }

  .instagram-button {
    /* height: 58%; */
    width: 40px;
    border-radius: 14px;
    /* font-size: .75rem; */
   
    /* gap: 7px; */
   
  }

  /* .instagram-button {
    height: 60%;
    width: 96px;

    font-size: .8rem;
   
    gap: 8px;
   
  }
  

  .instagram-icon {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .web-button {
    height: 60%;
    width: 96px;
   
    font-size: .8rem;
    margin-left: 5px;
  }

  .web-icon {
    font-size: 15px;
    margin-left: 2px;
    margin-top: 1px;
  } */


  


  .text-content h1 {
    font-size: 2.65rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.01rem;
  }

  .download-text {
  
    font-size: 1.2rem; /* Adjust size for visibility */
  
  }

  .phone-image {
    height: 440px;
    width: auto;
  }

  .box1 {
    top: 100px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 210px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 320px; /* Adjusted positioning */
  }

  .notif-right h1 {
   
    font-size: 2rem;
   
  }

  
  .notif-right p {
    
    font-size: 1rem;
    line-height: 1.6rem;
   
  }

  .icon {
    font-size: 30px;
    color: white;
    margin-bottom: 10px;
  }
  
  .feature-heading {
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p {
    font-family: Gilroy;
    font-size: .95rem;
    line-height: 1.4;
    margin: 0 10px;
  }


  .notification-box {
   
    /* width: 80%; */
   
  }

  .notification-header {
    
    font-size: 0.85rem;
  }

  .notification-content {
    font-size: 0.8rem;
  }
  
  .notification-content span {
    font-size: 0.8rem;
    /* color: fuchsia; */
  }
}

@media (max-width: 415px) {

    .logo {
        height: 11px;
      }

      .web-button {
        width: 90px;
      }

      .web-icon {
        display: none;
      }
    
      /* .instagram-button {
        height: 58%;
        width: 90px;
        font-size: .75rem;
       
        gap: 7px;
       
      }
      
    
      .instagram-icon {
        font-size: 13px;
        margin-bottom: 0px;
      }
    
      .web-button {
        height: 58%;
        width: 90px;
       
        font-size: .72rem;
        margin-left: 5px;
      }
    
      .web-icon {
        font-size: 13px;
   
        margin-left: 2px;
        margin-top: 0px;
      } */

  .text-content h1 {
    line-height: 3.8rem;
    font-size: 2.6rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.15rem;
    line-height: 2.1rem;
    letter-spacing: 0.01rem;
  }

  .download-text {
  
    font-size: 1.2rem; /* Adjust size for visibility */
  
  }

  .phone-image {
    height: 420px;
    width: auto;
  }

  .box1 {
    top: 90px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 195px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 300px; /* Adjusted positioning */
  }

  .notif-right {
    padding: 10px;
  }

  .notif-right h1 {
   
    font-size: 1.8rem;
   
  }

  
  .notif-right p {
    
    font-size: 1rem;
    line-height: 1.6rem;
   
  }




  .notification-box {
   
    /* width: 80%; */
   
  }

  .notification-header {
    
    font-size: 0.77rem;
  }

  .notification-content {
    font-size: 0.7rem;
  }
  
  .notification-content span {
    font-size: 0.7rem;
    /* color: fuchsia; */
  }
}


@media (max-width: 385px) {
   

    .web-button {
        width: 80px;
        font-size: .75rem;
        /* width: 90px; */
        border-radius: 14px;
      }

    
    
      /* .instagram-button {
        height: 58%;
        width: 82px;
        font-size: .7rem;
       
        gap: 6px;
       
      }
      
    
      .instagram-icon {
        font-size: 12px;
        margin-bottom: 0px;
      }
    
      .web-button {
        height: 58%;
        width: 82px;
       
        font-size: .67rem;
        margin-left: 3px;
      }
    
      .web-icon {
        font-size: 12px;

        margin-left: 1.5px;
        margin-top: 1px;
      } */

  .text-content h1 {
    line-height: 3.6rem;
    font-size: 2.4rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.1rem;
    line-height: 2rem;
    letter-spacing: 0rem;
  }

  .download-text {
  
    font-size: 1.2rem; /* Adjust size for visibility */
  
  }

  .phone-image {
    height: 390px;
    width: auto;
  }

  .box1 {
    top: 80px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 175px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 270px; /* Adjusted positioning */
  }

  .notif-right {
    padding: 10px;
  }

  .notif-right h1 {
   
    font-size: 1.7rem;
   
  }

  
  .notif-right p {
    
    font-size: .95rem;
    line-height: 1.4rem;
   
  }




  .notification-box {
   
    width: 85%;
   height: 90px
  }

  .notification-header {
    
    font-size: 0.77rem;
  }

  .notification-content {
    font-size: 0.7rem;
  }
  
  .notification-content span {
    font-size: 0.7rem;
    /* color: fuchsia; */
  }
}

@media (max-width: 355px) {
    
    .logo {
        height: 10px;
      }

      .instagram-button {
        height: 60%;
        width: 35px;
      }

    .web-button {
        height: 60%;
        width: 70px;
        font-size: .7rem;
        /* width: 90px; */
        border-radius: 13px;
        margin-left: 3px;
      }


  .text-content h1 {
    line-height: 3.4rem;
    font-size: 2.1rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1.05rem;
    line-height: 1.9rem;
    letter-spacing: 0rem;
  }

  .download-text {
  
    font-size: 1.1rem; /* Adjust size for visibility */
  
  }


  .phone-image {
    height: 360px;
    width: auto;
  }

  .box1 {
    top: 60px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 155px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 250px; /* Adjusted positioning */
  }

  .notif-right {
    padding: 10px;
  }

  .notif-right h1 {
   
    font-size: 1.55rem;
   
  }

  
  .notif-right p {
    
    font-size: .95rem;
    line-height: 1.4rem;
   
  }




  .notification-box {
   
    width: 88%;
   height: 90px
  }

  .notification-header {
    
    font-size: 0.7rem;
  }

  .notification-header img {
    width: 15px;
    margin-right: 8px;
  }

  .notification-content {
    font-size: 0.67rem;
  }
  
  .notification-content span {
    font-size: 0.7rem;
    /* color: fuchsia; */
  }
}

@media (max-width: 315px) {
  .text-content h1 {
    line-height: 3.2rem;
    font-size: 2rem;
  }

  .categories {
    font-weight: bold;
   
    /* letter-spacing: 0.04rem; */
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0rem;
  }

  .download-text {
  
    font-size: 1.05rem; /* Adjust size for visibility */
  
  }


  .phone-image {
    height: 360px;
    width: auto;
  }

  .box1 {
    top: 60px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 155px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 250px; /* Adjusted positioning */
  }

  .notif-right {
    padding: 10px;
  }

  .notif-right h1 {
   
    font-size: 1.55rem;
   
  }

  
  .notif-right p {
    
    font-size: .95rem;
    line-height: 1.4rem;
   
  }




  .notification-box {
   
    width: 88%;
   height: 90px
  }

  .notification-header {
    
    font-size: 0.68rem;
  }

  .notification-header img {
    width: 10px;
    margin-right: 6px;
  }

  .notification-content {
    font-size: 0.65rem;
  }
  
  .notification-content span {
    font-size: 0.7rem;
    /* color: fuchsia; */
  }
}

@media (max-width: 295px) {
  .text-content h1 {
    font-size: 1.9rem;
  }

  .categories {
    font-weight: bold;
   
    letter-spacing: 0.04rem;
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 0rem;
  }

  .download-text {
  
    font-size: 1rem; /* Adjust size for visibility */
  
  }


  .phone-image {
    height: 360px;
    width: auto;
  }

  .box1 {
    top: 60px; /* Evenly spaced over the height of the iPhone image */
  }
  
  .box2 {
    top: 155px; /* Adjusted positioning */
  }
  
  .box3 {
    top: 250px; /* Adjusted positioning */
  }

  .notif-right {
    padding: 10px;
  }

  .notif-right h1 {
   
    font-size: 1.55rem;
   
  }

  
  .notif-right p {
    
    font-size: .95rem;
    line-height: 1.4rem;
   
  }




  .notification-box {
   
    width: 88%;
   height: 90px
  }

  .notification-header {
    
    font-size: 0.65rem;
  }

  .notification-header img {
    width: 10px;
    margin-right: 6px;
  }

  .notification-content {
    font-size: 0.65rem;
  }
  
  .notification-content span {
    font-size: 0.7rem;
    /* color: fuchsia; */
  }
}

@media (max-height: 700px) {
  .text-content {
    padding-top: 30px;
  }

  .download-section {
    margin-top: 30px;
  
  }
}

@media (max-height: 650px) {
    /* Main Container */
    .main-container {
      height: 650px;
      max-height: 650px;
 
    }
}