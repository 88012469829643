/* CAL VIEW IN PARTIES - DATE POP UP */



@media (min-width: 470px) {
    .calviewspotcard {
        position: relative;
        width: 95%;
        height: 88px;
        display: flex;
        /* border-bottom: 1px solid #444; */
        font-family: Montserrat;
        margin: 0px auto;
    }
}

.calspotfirstsection {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* border: 1px solid red; */
    font-size: 0.9rem;
    font-weight: bold;
}

.calspotfirstsectimg {
    object-fit: cover;
    width: 75%;
    aspect-ratio: 1 / 1;
    /* height: 80%; */
    border-radius: 10px;
}

.calspotmainsection {
    width: 55%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.calspotmainsectinner {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */    
}

.calspotmainsectinnert {
    padding: 1px 0 4px 4px;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    margin-bottom: 3px;
    text-transform: uppercase;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.calspotmainsectinnerb {
    padding: 4px 0 1px 4px;
    font-size: .9rem;
    font-weight: bold;
    color: grey;
    letter-spacing: 0.03rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.calspotlastsection {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* font-size: 0.75rem; */
    color: white;
    /* font-weight: bold; */
}

.calspotlastsectcont {
    text-transform: uppercase;
    height: 31%;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    color: fuchsia;
    font-weight: bold;
    font-size: 0.8rem;
   
    letter-spacing: 0.04rem;
    background-color: #000;
}

.calspotlastsectconm {
    height: 26%;
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: #949494;
    /* font-weight: bold; */
    /* background-image: linear-gradient(
        90deg,
     #333, #444, #555
    ); */
    font-size: 0.7rem;
    font-style: italic;
    /* margin-bottom: 2px; */

    /* border-top: 1px solid fuchsia; */
    /* border: 1px solid fuchsia; */
    letter-spacing: 0.04rem;
    font-weight: 500;
}

.calspotlastsectconb {
    text-transform: uppercase;

    height: 31%;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    color: fuchsia;
    font-weight: bold;
    font-size: 0.8rem;
  
    letter-spacing: 0.04rem;
    /* border: 1px solid #0BFFB6; */
    background-color: #000;
}
/* def should combine above two. I think only difference is color right now */

.calspotlivebox {
    height: 35%;
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 15px; */
    border-radius: 12px;
    color: #FF1493;
    font-weight: bold;
    /* background-image: linear-gradient(
        90deg,
     #333, #444, #555
    ); */
    /* background-image: linear-gradient(
        0deg,
        #000, #333, #444
    ); */
    /* background-color: #333; */
    /* margin-bottom: 2px; */

    border: 1px solid #FF1493;
    max-width: 80px;
    /* font-size: 1.1rem; */
}

.calspotlastsectfrom {
    color: grey;
    font-weight: lighter;
    font-style: italic;
    margin-right: 4.5px;
    font-size: 0.65rem;
}

.calspotnoparties {
    padding: 16px 10px;
    width: 100%;
    text-align: center;
    color: grey;
}

/* VERSION FOR SEARCH (PARTIES) */

.searchspotcard {
    position: relative;
    height: 75px;
    width: 95%;
    display: flex;
    margin: 8px auto;
}

.mapspotcard {
    width: 95%;
    height: 75px;
    display: flex;
    border-bottom: 1px solid #333;
    /* font-size: 1.1rem; */
    margin: 4px auto;
}

.calviewspotcard {
    font-family: Montserrat;
    position: relative;
    width: 95%;
    height: 75px;
    display: flex;
  
    margin: 8px auto;
    /* padding: 2px 0; */
}

.searchspotfirstsection {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
}

.searchfirstsectimg {
    object-fit: cover;
    width: 66%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
}

.searchspotmainsection {
    width: 55%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-right: 10px;
    padding-left: 5px;
}

.searchspotmainsectionnew {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-right: 10px;
}

.searchspotmainsectioninner {
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.searchspotmainsectinnert {
    /* padding: 2px 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    margin-bottom: 4px;
    text-transform: uppercase; */
    font-family: Montserrat;
    /* padding: 2px 0; */
    font-size: 1.05rem;
    font-weight: bold;
    color: white;
    margin-bottom: 4px;
    /* text-transform: uppercase; */
    margin-bottom: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.searchspotmainsectinneroth {
    font-family: Montserrat;
    /* padding: 2px 0; */
    font-size: 1.05rem;
    font-weight: bold;
    color: white;
    margin-bottom: 4px;
    /* text-transform: uppercase; */
    margin-bottom: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.searchspotmainsectinneroth2 {
    font-family: Montserrat;
    /* padding: 2px 0; */
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-bottom: 4px;
    /* text-transform: uppercase; */
    margin-bottom: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.grncol {
    /* color: #0BFFB6; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.grncol2 {
    letter-spacing: 0rem;
    /* color: #0BFFB6; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rdcol2 {
    color: #FF1493;
    letter-spacing: 0rem;
    /* color: #0BFFB6; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.addcut {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.addcut2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #7df9ff;
}

.searchspotmainsectinnerothb {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-family: Gilroy;
    /* padding: 2px 0 1px 0; */
    font-size: .95rem;
    color: #ccc;
    letter-spacing: 0.03rem;
    
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */

}

.searchspotmainsectinnerothb2 {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-family: Gilroy;
    /* padding: 2px 0 1px 0; */
    font-size: .9rem;
    color: #ccc;
    /* letter-spacing: 0.03rem; */

    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */

}

.searchspotmainsectinnerb {
  
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-family: Gilroy;
    /* padding: 2px 0 1px 0; */
    font-size: .95rem;
    color: #ccc;
    letter-spacing: 0.03rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.searchspotlastsection {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    /* font-size: 0.8rem; */
    color: white;
    font-weight: bold;
}


.searchspotlastsectfrom {
    color: #DEDEDE;
    font-weight: lighter;
    font-style: italic;
    margin-right: 6.5px;
    font-size: 0.65rem;
    text-transform: none;
}

@media (min-width: 450px) {
  

    .searchspotfirstsection {
        width: 22%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .searchspotmainsection {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .searchspotlastsection {
        width: 23%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        /* font-size: 0.8rem; */
        color: white;
        font-weight: bold;
    }
}

@media (min-width: 520px) {
 

    .searchspotfirstsection {
        width: 17%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .searchspotmainsection {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .searchspotlastsection {
        width: 28%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        /* font-size: 0.8rem; */
        color: white;
        font-weight: bold;
    }

    
}


@media (max-width: 599px) {
    .searchspotBCballs {
       
        width: 90%;
       
    }
}

@media (min-width: 600px) {
    .searchspotcard {
        position: relative;
        height: 95px;
        width: 95%;
        display: flex;
        font-family: Montserrat;
        margin: 8px auto;
    }



    .searchspotBCballs {
       
        width: 70%;
       
    }
}

@media (max-width: 380px) {
    
   
    

    .searchspotlastsectfrom {
        color: #DEDEDE;
        font-weight: lighter;
        font-style: italic;
        margin-right: 5px;
        font-size: 0.62rem;
    }

    /* .searchspotmainsectinnert {
        padding: 2px 0;
        font-size: 1rem;
        font-weight: bold;
        color: white;
        margin-bottom: 4px;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } */
    
    /* .searchspotmainsectinnerb {
        padding: 2px 0 1px 0;
        font-size: .9rem;
        color: #999;
        letter-spacing: 0.03rem;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    } */

    .calspotmainsectinnert {
        padding: 2px 0 2px 4px;
        font-size: 1rem;
        font-weight: bold;
        color: white;
        margin-bottom: 3px;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .calspotmainsectinnerb {
        padding: 2px 0 1px 4px;
        font-size: .85rem;
        font-weight: bold;
        color: grey;
        letter-spacing: 0.03rem;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .calspotlastsectconm {
        height: 26%;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        color: #949494;
        /* font-weight: bold; */
        /* background-image: linear-gradient(
            90deg,
         #333, #444, #555
        ); */
        font-size: 0.6rem;
        font-style: italic;
        /* margin-bottom: 2px; */
    
        /* border-top: 1px solid fuchsia; */
        /* border: 1px solid fuchsia; */
    }
}

@media (max-width: 330px) {

   

    .searchspotlastsectfrom {
        color: #DEDEDE;
        font-weight: lighter;
        font-style: italic;
        margin-right: 4px;
        font-size: 0.53rem;
    }

    .calspotmainsectinnert {
        padding: 2px 0 2px 4px;
        font-size: .95rem;
        font-weight: bold;
        color: white;
        margin-bottom: 3px;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .calspotmainsectinnerb {
        padding: 2px 0 1px 4px;
        font-size: .8rem;
        font-weight: bold;
        color: grey;
        letter-spacing: 0.03rem;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}




/* SEARCH CARDS IN BAR AND CLUBS (VERY SIMILAR TO PARTIES) */

.searchspotfirstsectionBC {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
}

.searchfirstsectimgBC {
    object-fit: cover;
    width: 70%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
}

.searchspotlastsectionBC {
    width: 35%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
    font-family: Montserrat;
}

.searchspotBCballs {
    height: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
}



.spotballop {
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.65rem;
    font-weight: bold;
    color: #0BFFB6;
    /* background-image: linear-gradient(
        90deg,
        #222, #333, #444
    ); */
    background-color: #333;
    /* box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
}

.spotballcl {
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.6rem;
    font-weight: bold;
    color: #0BFFB6;
    background-image: linear-gradient(
        90deg,
        #000, #222, #555
    );
    /* box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    
    opacity: 0.25;
}
/* did I mean to make the opacity ones smaller? */


/* SPOT CARDS FOR MULTI MAP EG IN BARS AND CLUBS */



.mapspotfirstsect{
    width: 36%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
}

.mapspotcirc {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* color: rgb(0, 255, 255); */
    color: #0BFFB6;
    font-weight: bold;
    font-size: .9rem;
    background-image: linear-gradient(
        180deg,
        #000, #111
    );
    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
}

.mapspotcirc2 {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* color: white; */
    color: #FF00EE;
    font-weight: bold;
    font-size: .9rem;
    /* background-image: linear-gradient(
        320deg,
        #FFDFA5, fuchsia, #FF00EE
    ); */
    background-image: linear-gradient(
        180deg,
        #222, #444, #555
    );
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
}

.mapspotcirccont3 {
    /* position: absolute;
    left: 0px;
    width: fit-content; */
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid green; */
}

.mapspotcirc3 {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* color: white; */
    color: #FF00EE;
    font-weight: bold;
    font-size: .9rem;
    /* background-image: linear-gradient(
        320deg,
        #FFDFA5, fuchsia, #FF00EE
    ); */
    /* background-image: linear-gradient(
        180deg,
        #222, #444, #555
    ); */
    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    background-color: #333;
    /* background: rgba(0,0,0,.7);
    backdrop-filter: saturate(180%) blur(10px); */
}

.mapspotsecondsect {
    width: 16%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    /* color: #3EB489; */   
}

.mapspotsecondsectimg {
    object-fit: cover;
    width: 60%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
}

.mapspotmainsect {
    width: 68%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.mapspotmainsectinner {
    width: 91%;
    height: 95%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.mapspotmainsectinnert {
    /* margin: 1px 0 7px 0; */
    
    

    font-family: Montserrat;
    /* padding: 2px 0; */
    font-size: 1.05rem;
    font-weight: bold;
    color: white;
    /* margin-bottom: 4px; */
    /* text-transform: uppercase; */
    margin-bottom: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mapspotmainsectinnerb {
    margin: 2px 0;
    font-family: Montserrat;
    font-size: .85rem;
    font-weight: bold;
    color: grey;
    letter-spacing: 0.05rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mapspotlastsection {
    width: 16%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
}

.mapspotarrowcont {
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    /* background-image: linear-gradient(
        0deg,
        #000, #111, #222, #333
    ); */
    border: 1px solid fuchsia;
    background-color: #000;
    /* box-shadow: 0 0 2px fuchsia, 0 0 2px fuchsia, 0 0 2px fuchsia; */
}

.mapspotarrow {
    color: fuchsia;
    font-size: 1.4rem;
    transform: rotate(-45deg);
    

}

.mapspotarrowcontoth {
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-image: linear-gradient(
            0deg,
            #7F00FF, #E100FF
            );
    /* box-shadow: 0 0 2px fuchsia, 0 0 2px fuchsia, 0 0 2px fuchsia; */
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
}

.mapspotarrowoth {
    color: white;
    font-size: 1.4rem;
    transform: rotate(-45deg);
    

}

/* VERSION FOR MAP OF DESTINATIONS IN CRUISE WINDOW */

.cruisemapspotcard {
    width: 95%;
    height: 88px;
    display: flex;
    /* font-size: 1.1rem; */
    border-bottom: 1px solid #333;
    margin: 0px auto;
}

.cruisemapspotfirstsection {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
}

.cruisemapdestcirc {
    /* height: 35px;
    width: 35px; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; */
    /* color: rgb(0, 255, 255); */
    /* color: #0BFFB6; */
    /* font-weight: bold;
    font-size: .9rem; */
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* color: white; */
    color: #FF00EE;
    font-weight: bold;
    font-size: .9rem;
    /* background-image: linear-gradient(
        320deg,
        #FFDFA5, fuchsia, #FF00EE
    ); */
    /* background-image: linear-gradient(
        180deg,
        #222, #444, #555
    ); */
    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    background-color: #333;
}

.cruisemapspotmainsection {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cruisemapspotmainsectinner {
    width: 91%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.cruisemapspotmainsectinnert {
    padding: 2px 0;
    /* font-size: 1rem; */
    /* font-weight: bold; */
    /* color: white; */
    /* letter-spacing: 0.04rem; */
    /* text-transform: uppercase; */

    

    font-family: Montserrat;
    /* padding: 2px 0; */
    font-size: 1rem;
    font-weight: bold;
    color: white;
    /* margin-bottom: 4px; */
    /* text-transform: uppercase; */
    margin-bottom: 1px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cruisemapspotmainsectinnerb {

    display: flex;
    align-items: center;
    font-family: Gilroy;
    /* padding: 2px 0 1px 0; */
    font-size: .9rem;
    color: #ccc;
    /* letter-spacing: 0.03rem; */

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
 }

 .cruisemapfont {
    color: fuchsia;
    /* text-shadow: .5px .5px 0 fuchsia; */
    font-weight: bold;
    /* font-size: 0.85rem; */
 }



 @media (max-width: 380px) {
   

    .mapspotmainsectinnert {
        margin: 1px 0 7px 0;
        font-size: .95rem;
        font-weight: bold;
        color: white;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

 
    
}

 @media (max-width: 374px) {
    .mapspotmainsectinnert {
        margin: 1px 0 7px 0;
        font-size: .95rem;
        font-weight: bold;
        color: white;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .mapspotmainsectinnerb {
        margin: 2px 0;
        font-family: Montserrat;
        font-size: .7rem;
        font-weight: bold;
        color: grey;
        letter-spacing: 0.05rem;
    
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .cruisemapspotmainsectinnert {
       
        font-size: .9rem;
       
    }

    .cruisemapspotmainsectinnerb {
       
        font-size: 0.8rem;
        
     }

    .cruisemapfont {
        color: fuchsia;
        /* text-shadow: .5px .5px 0 fuchsia; */
        font-weight: bold;
        /* font-size: 0.7rem; */
     }

     .calspotlastsection {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* font-size: 0.65rem; */
        color: white;
        /* font-weight: bold; */
    }

    .calspotlastsectcont, .calspotlastsectconb {
        width: 90%;
        font-size: 0.7rem;
    }
   
}

@media (max-width: 330px) {
    

     .calspotlastsection {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* font-size: 0.62rem; */
        color: white;
        /* font-weight: bold; */
    }

    .calspotlastsectcont, .calspotlastsectconb {
        font-size: 0.7rem;
        width: 100%;
    }

    .cruisemapspotmainsectinnerb {
        
        font-size: 0.7rem;
        
     }

    .cruisemapfont {
        color: fuchsia;
        /* text-shadow: .5px .5px 0 fuchsia; */
        font-weight: bold;
        /* font-size: 0.63rem; */
     }
   
}

 /* SPOT CARD FOR PEOPLE SEARCH */

/* using generic (parties) search card "searchspotcard" for the main dimensions */

.searchspotmainsectionPP {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.searchspotlastsectionPP {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
}

.searchspottypegrid {
    width: 90%;
    height: 82%;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
}





.typeop {
    opacity: 0.2;
}

/* CRUISE SPOT CARD SEARCH */

.smallto {
    padding: 1px 0;
    color: grey;
    font-size: 0.65rem;
    font-style: italic;
}

.searchspotfirstsectionC {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
}

.searchfirstsectimgC {
    object-fit: cover;
    width: 75%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
}

.searchspotmainsectionC {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchspotlastsectionC {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
}



@media (min-width: 460px) {
    .calspotlastsectcont, .calspotlastsectconb {
        width: 75%;
        
    }
}

@media (min-width: 500px) {
    .calspotlastsectcont, .calspotlastsectconb {
        width: 70%;
        font-size: 0.7rem;
        font-size: .8rem;
    }
}