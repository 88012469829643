ion-modal.infomodal {
    --width: 87%;
    --max-width: 500px;
    --height: auto;
    --border-radius: 12px;
    
    /* --background: linear-gradient(
        90deg,
        #111, #222
    ); */
    --background: #111;
    /* --background: rgba(0,0,0,1); */
    /* --box-shadow: 0 0 5px fuchsia, 0 0 5px fuchsia, 0 0 5px fuchsia; */
    /* font-family: Gilroy; */
}

ion-modal.infomodal::part(backdrop) {
    background: rgba(0, 0, 0);
    /* opacity: .7; */
    --backdrop-opacity: 0.75;
}

.infomodmsg {
    padding: 15px 15px;
    
  
    font-family: Montserrat;
    font-size: 1rem;
    /* font-weight: bold; */
    line-height: 1.3rem;
    color: white;

    /* box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ; */
}

@media (max-width: 375px) {
    .infomodmsg {
        
        font-size: .85rem;
        
    }
}