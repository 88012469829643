/* ion-content::part(background) {
    background: #d31373;
  }

ion-content::part(scroll) {
    color: #000;
  } */

  /* ion-content::-webkit-scrollbar {
    background-color: #222;
  } */

.ultimategridviewcontainer {
    /* padding: 10px 0; */
    /* padding: 2px 0; */
    /* border: 1px solid red; */
}

.ultimategridviewcontaineroth {
    /* padding: 10px 0; */
    padding: 10px 0;
    /* border: 1px solid red; */
}

.mgdates {
    /* position: fixed; */
    /* height: 3%; */
    padding: 2.5px 0 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    
    /* background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(10px); */
}

.mgdatesoth {
    /* position: fixed; */
    /* height: 3%; */
    /* padding: 2.5px 0 5px 0; */
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    
    /* background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(10px); */
}

.mgdatesothpp {
    /* position: fixed; */
    /* height: 3%; */
    /* padding: 2.5px 0 5px 0; */
    width: 100%;
    height: 32px;
    display: flex;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
    
    /* background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(10px); */
}

.infocontl {
    width: 50px;
    /* border: 1px solid red; */
}

.infocontr {
    cursor: pointer;
    width: 50px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: end;
    align-items: center;
}

.infocontr2 {
    cursor: pointer;
    /* width: 50px; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.helpicon {
    font-size: 1.6rem;
    /* margin: 0 6px; */
    margin-right: 14%;
    color: #ccc;
}

.helpicon2 {
    font-size: 1.9rem;
    /* margin: 0 6px; */
    margin-right: 14%;
    color: #ccc;
}

.mgdatelines {
    padding: 0 7px;
    height: 100%;
    /* width: 30%; */
    flex-grow: 1;
    
}

.mgdatelinet {
    /* border-bottom: 1px solid #BC00FE; */
    border-bottom: 2px solid #444;
}

.mgdatelineb {
    /* border-top: 1px solid #BC00FE; */
    border-bottom: 1px solid #333;
}

.mgdatelinet2 {
    /* border-bottom: 1px solid #fc6767; */
    border-bottom: 2px solid #444;
}

.mgdatelineb2 {
    /* border-top: 1px solid #fc6767; */
    border-bottom: 1px solid #333;
}

.mgdatelinet3 {
    border-bottom: 1px solid #36D1DC;
}

.mgdatelineb3 {
    border-top: 1px solid #36D1DC;
}





.extpad {
    margin: 3px 0;
}



/* .loop {
  
    padding: 3px 7px;
 
    border-radius: 8px;
  
    background-image: linear-gradient(
        90deg,
        #7F00FF, #E100FF
    );
    color: white;
    font-size: 0.82rem;
} */

/* @media (max-width: 349px) {
    .mgdatestext, .mgdatestext2, .mgdatestext3, .loop {
        font-size: 0.75rem;
    }
}

@media (min-width: 650px) {
    .mgdatestext, .mgdatestext2, .mgdatestext3, .loop {
        font-size: 0.95rem;
    }
} */

.gridviewgrid {
    width: 96%;
    display: grid;
    justify-items: center;
    /* align-items: center; */
    grid-template-columns: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.gridviewgridfix {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.gridviewgridBC {
    width: 86%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 100%;
    margin: 0 auto 25px auto;
    /* border: 1px solid red; */
    /* gap: 3px; */
}

.windowgridfour {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    width: 100%;
    margin: 0 auto 25px 0;
}

.gridviewgridPP {
    /* width: 90vh; */
    width: 96%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 50% 50%;
    /* grid-template-columns: calc(100vw/3); */
    /* grid-template-columns: 1fr 1fr 1fr; */
    margin: 0 auto;
}

/* PEOPLE CARDS IN PARTY WINDOW WHICH IS A GRID */

.windowgrid {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;

    width: 85%;
    margin: 5px auto 22px auto;
}

.gridviewgridc {
    width: 96%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
}

@media (min-width: 320px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: 50% 50%;
        margin: 0 auto;
    }

    .gridviewgridBC {
        width: 86%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 50% 50%;
        margin: 0 auto 25px auto;
        /* border: 1px solid red; */
        /* gap: 3px; */
    }

    .gridviewgridPP {
        /* width: 90vh; */
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        /* grid-template-columns: 50% 50%; */
        grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
        /* grid-template-columns: 1fr 1fr 1fr; */
        margin: 0 auto;
    }

    .windowgrid {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 25% 25% 25% 25%;
    
        width: 85%;
        margin: 5px auto 22px auto;
    }

    .gridviewgridc {
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
    }
}



@media (min-width: 510px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
        margin: 0 auto;
    }

    .gridviewgridBC {
        width: 86%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
        margin: 0 auto 25px auto;
        /* border: 1px solid red; */
        /* gap: 3px; */
    }

    .gridviewgridPP {
        /* width: 90vh; */
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        /* grid-template-columns: 50% 50%; */
        grid-template-columns: 25% 25% 25% 25%;
        /* grid-template-columns: 1fr 1fr 1fr; */
        margin: 0 auto;
    }

    .windowgrid {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 20% 20% 20% 20% 20%;
    
        width: 85%;
        margin: 5px auto 22px auto;
    }

   
}

@media (min-width: 569px) {
 

    .gridviewgridc {
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 50% 50%;
        margin: 0 auto;
        /* border: 1px solid red; */
    }
}



@media (min-width: 690px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: 25% 25% 25% 25%;
        margin: 0 auto;
    }
    
    .gridviewgridBC {
        width: 86%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 25% 25% 25% 25%;
        margin: 0 auto 25px auto;
        /* border: 1px solid red; */
        /* gap: 3px; */

       
    }

    .gridviewgridPP {
        /* width: 90vh; */
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        /* grid-template-columns: 50% 50%; */
        grid-template-columns: 20% 20% 20% 20% 20%;
        /* grid-template-columns: 1fr 1fr 1fr; */
        margin: 0 auto;
    }

    .windowgrid {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6);
    
        width: 85%;
        margin: 5px auto 22px auto;
    }

    .gridviewgridc {
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 50% 50%;
        margin: 0 auto;
        /* border: 1px solid red; */
    }
}

@media (min-width: 930px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: 20% 20% 20% 20% 20%;
        margin: 0 auto;
    }

    .gridviewgridBC {
        width: 86%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 20% 20% 20% 20% 20%;
        margin: 0 auto 25px auto;
        /* border: 1px solid red; */
        /* gap: 3px; */
    }

    .gridviewgridPP {
        /* width: 90vh; */
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        /* grid-template-columns: 50% 50%; */
        grid-template-columns: calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6);
        /* grid-template-columns: 1fr 1fr 1fr; */
        margin: 0 auto;
    }

    .windowgrid {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: calc(100%/7) calc(100%/7) calc(100%/7) calc(100%/7) calc(100%/7) calc(100%/7) calc(100%/7);
    
        width: 85%;
        margin: 5px auto 22px auto;
    }

    .gridviewgridc {
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
        margin: 0 auto;
        /* border: 1px solid red; */
    }

   
}



/* @media (min-width: 1290px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6) calc(100%/6);
        margin: 0 auto;
    }
} */

@media (min-width: 1200px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: 25% 25% 25% 25%;
        margin: 0 auto;
    }

    .mgdatesoth {
        display: none;
    }

    .mgdatesothpp {
        display: none;
    }

}

@media (min-width: 1300px) {
    .gridviewgrid {
        width: 96%;
        display: grid;
        justify-items: center;
        /* align-items: center; */
        grid-template-columns: 20% 20% 20% 20% 20%;
        margin: 0 auto;
    }

}





/* below is for bc window where you have 1 or 2 upcoming events */


.gvgaddmarginbot {
    margin-bottom: 25px;
}

.gvgaddmarginbotff {
    margin-bottom: 20px;
}

/* grid card - standard (parties, ...) but note the elements eg time, price are based on party card */

.gridviewcardstd {
    position: relative;
    /* width: 98%; */
    width: 96%;
    /* height: 50%; */
    aspect-ratio: 1/.95;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    /* margin: 4px 0; */
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(81,81,81,0.2)
    ; */
    /* border: 1px solid yellow; */
    
    background-color: white;
    color: white !important;
    text-decoration: none !important;
}

.gridviewcardBC {
    position: relative;
    width: 96%;
    /* height: 50%; */
    aspect-ratio: 1/.95;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 5px 0;
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ; */
    /* border: 1px solid yellow; */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
}

.gridviewcardstdnewnew {
    position: relative;
    /* width: 98%; */
    width: 96%;
    /* height: 50%; */
    aspect-ratio: 1/.95;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: 4px 0;
    
    /* border: 1px solid yellow; */
    background-color: white;
    color: white !important;
    text-decoration: none !important;
}

.gridviewcardstdfeat {
    position: relative;
    width: 95%;
    /* height: 50%; */
    aspect-ratio: 16/9;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    /* margin: 4px 0; */
    box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(81,81,81,0.2)
    ;
    /* border: 1px solid yellow; */
    background-color: white;

    color: white !important;
    text-decoration: none !important;
}

.gridviewcardstd2 {
    position: relative;
    width: 96%;
    /* height: 50%; */
    aspect-ratio: 1/1.25;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    margin: 4px 0;
    
    /* border: 1px solid yellow; */
    background-color: #333;
}



.gridviewcardimagecover2 {
    height: 100%;
    width: 100%;
    display: flex;
    flex: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    border-radius: 20px;
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.1),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(81,81,81,0.2)
    ; */
    
}

.gridviewcardimage2 {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 25px; */
    /* border-radius: 20px; */
    border-radius: 20px;
    
}

.gridviewbottomcontainer2 {
    /* position: absolute; */
    /* bottom: 0%; */
    height: 28%;
    /* height: 100px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
    /* overflow: hidden; */
    /* background-color: black; */
    /* border-radius: 0 0 20px 20px; */
    /* background-color: rgba(51,51,51,.3); */
    /* backdrop-filter: saturate(180%) blur(10px); */
    background-color: transparent;
}


.gridviewtitlecontainer2 {
    /* padding-right: 2px; */
    /* height: 20%; */
    /* width: 65%; */
    width: 75%;
    
    /* border: 1px solid red; */
}

.gridviewtitlefull2 {
    padding: 0 18px;
    /* margin-bottom: 10px; */
    font-family: Montserrat;
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
    /* text-shadow: 1px 2px 0px #222; */
    /* text-shadow: 1px 1px 0px fuchsia; */
    /* text-transform: uppercase; */

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* border: 1px solid yellow; */
    position: relative;
}

.midcont {
    width: 75%;
    height: 10%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 15px 0 15px 18px;
    font-family: Montserrat;
    font-size: 0.9rem;
    /* color: #0BFFB6; */
    font-weight: bold;
}

.midcont2 {
    width: 100%;
    /* height: 10%; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 12px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-family: Montserrat;
    font-size: 0.88rem;
    
    font-weight: bold;
}

.midcontsau {
    width: 100%;
    /* height: 10%; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 1px 12px 1px 12px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-family: Montserrat;
    font-size: 0.88rem;
    
    font-weight: bold;
}

.midcontdate, .midconttime {
    color: #0BFFB6;
}


.biggreenborder {
    border: 8px solid green;
}


.midcontdateballs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1px 0;
}

.midconttime {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.midconttimer {
    color: #FF1493;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.midcontdatelive {
    color: #FF1493;
}

.midcontalt {
    width: 100%;
    /* height: 10%; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: left;
    padding: 0 12px;
    margin-top: 2px;
    font-family: Montserrat;
    font-size: 0.9rem;
    color: white;
    /* font-weight: bold; */
    font-family: Montserrat;
    font-size: 0.8rem;
    color: white;
    /* margin: 0 0 12px 18px; */

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tinyball {
    height: 4px;
    width: 4px;
    background-color: #0BFFB6;
    border-radius: 50%;

    margin: 0 10px;
}

.tinyballr {
    height: 4px;
    width: 4px;
    background-color: #FF1493;
    border-radius: 50%;

    margin: 0 10px;
}

.tinyballw {
    height: 4px;
    width: 4px;
    background-color: #555;
    border-radius: 50%;

    margin: 0 10px;
}

.tinyball2 {
    height: 4px;
    width: 4px;
    background-color: fuchsia;
    border-radius: 50%;

    margin: 0 12px;
}

.tinyball3 {
    height: 4px;
    width: 4px;
    background-color: #0BFFB6;
    border-radius: 50%;

    margin: 0 12px;
}

.largedesccont {
    
    width: 75%;
    font-family: Montserrat;
    font-size: 0.8rem;
    color: white;
    margin: 0 0 12px 18px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}




.gridviewcardppl {
    position: relative;
    width: 97%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    /* box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.15),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ; */
    margin: 3px 0;
    background-color: white;
    color: white !important;
    text-decoration: none !important;
  }



.gridviewcardimagecoverppl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 15px; */
    border-radius: 20px;
    background-image: linear-gradient(
        0deg,
        rgba(65,65,65,.9), rgba(65,65,65,.6), rgba(65,65,65,.3), transparent, transparent, transparent
    );
    
    /* border: 2px solid red; */
}

/* below is for bc window where you have 1 or 2 upcoming events */
/* now im using it for all window cards it seems (where 1 or 2 things) */


/* .gridviewcardfixedsize {
    position: relative;
    width: 43vw;
    height: 43vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin: 5px 0;
    box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.15),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ;
} */

.gridviewcardimage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 25px; */
    border-radius: 20px;
}

.gridviewcardimageppl {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 15px; */
    border-radius: 10px;
}

.gridviewcardimagecover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 25px; */
    border-radius: 20px;
    /* background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.9),rgba(0,0,0,.8), rgba(0,0,0,.7), rgba(0,0,0,.6),rgba(0,0,0,.5), rgba(0,0,0,.4), rgba(0,0,0,.25),rgba(0,0,0,.15), rgba(0,0,0,.1),rgba(0,0,0,.05), transparent, transparent, transparent, transparent, transparent, transparent, transparent
    ); */
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.9),rgba(0,0,0,.85), rgba(0,0,0,.75), rgba(0,0,0,.65),rgba(0,0,0,.5), rgba(0,0,0,.4), rgba(0,0,0,.25),rgba(0,0,0,.15), rgba(0,0,0,.1),rgba(0,0,0,.05), transparent, transparent, transparent, transparent, transparent, transparent
    );
    
    /* border: 2px solid red; */
}

.gridviewcardimagecoverpploth {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 25px; */
    border-radius: 10px;
    /* background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.9),rgba(0,0,0,.8), rgba(0,0,0,.7), rgba(0,0,0,.6),rgba(0,0,0,.5), rgba(0,0,0,.4), rgba(0,0,0,.25),rgba(0,0,0,.15), rgba(0,0,0,.1),rgba(0,0,0,.05), transparent, transparent, transparent, transparent, transparent, transparent, transparent
    ); */
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.9),rgba(0,0,0,.85), rgba(0,0,0,.75), rgba(0,0,0,.65),rgba(0,0,0,.5), rgba(0,0,0,.4), rgba(0,0,0,.25),rgba(0,0,0,.15), rgba(0,0,0,.1),rgba(0,0,0,.05), transparent, transparent, transparent, transparent, transparent, transparent
    );
    
    /* border: 2px solid red; */
}

.gridviewcardimgcoverfeat {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 25px; */
    border-radius: 20px;
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.5),rgba(0,0,0,.5)
    );
    
    /* border: 2px solid red; */
}

.gridviewcardimgcoveroth {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 25px; */
    border-radius: 20px;
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,.4),rgba(0,0,0,.4)
    );
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */

}

.gridviewcardimagecoversoldout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px;
    /* background-image: linear-gradient(
        0deg,
        rgba(65,65,65,.9), rgba(65,65,65,.6), rgba(65,65,65,.3)
    ); */

    background-color: rgba(0,0,0,.7);
    /* border: 2px solid red; */
    z-index: 100;
}

#gridviewcardimagecovercs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px;
    /* background-image: linear-gradient(
        0deg,
        rgba(65,65,65,.9), rgba(65,65,65,.6), rgba(65,65,65,.3)
    ); */
    background-color: rgba(65,65,65,.7);
    /* border: 2px solid red; */
}

.gridviewextrasoldoutbox {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background-color: rgba(0,0,0,.6);
    overflow: hidden;
    z-index: 10;
    border-radius: 20px;
}

.gridviewextrasoldouttext {
    color: #FF1493;
    transform: rotate(-39deg);
    font-size: 1.1rem;
    letter-spacing: 0.12rem;
    /* border: 1px solid red; */
}

.gridviewextradetailstext {
    color: #949494;
    /* transform: rotate(-39deg); */
    font-size: 1rem;
    letter-spacing: 0.08rem;
    line-height: 1.4rem;
    /* border: 1px solid red; */
    width: 90%;
    text-align: center;
    text-shadow: 1px 2px 0px #333;
}

.sponscont {
    position: absolute;
    top: 4px;
    right: 10px;
    color: #d4d4d4;
    font-family: Montserrat;
    font-size: 0.7rem;
    /* height: 30px;
    width: 30px; */
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);

    backdrop-filter: saturate(180%) blur(10px);
}

.newtypecont {
    position: absolute;
    top: 4px;
    right: 10px;
    color: white;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 0.7rem;
    /* height: 30px;
    width: 30px; */
    padding: 4px 0px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);

    backdrop-filter: saturate(180%) blur(10px);
    /* backdrop-filter: saturate(180%) blur(10px); */
}

.gridviewlikebuttoncontainer {
    position: absolute;
    top: 4px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.7);

    backdrop-filter: saturate(180%) blur(10px);
}


.gridviewlikebutton {
    font-size: 20px;
    color: #FF1493;
    /* margin: 0px 0 0 0px; */
}

.liked {
    background: radial-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.8),
        rgba(255,255,255,0.7),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.1),
        transparent,
        transparent,
        transparent,
        transparent);
}




@media (max-width: 374px) {
    

    .windowgridfour {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
    
        width: 100%;
        margin: 0 auto 25px 0;
    }
}

@media (min-width: 580px) {
    .windowgridfour {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
        width: 100%;
        margin: 0 auto 25px 0;
    }

    
}

@media (min-width: 680px) {
    .windowgridfour {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    
        width: 100%;
        margin: 0 auto 25px 0;
    }

    
}

@media (min-width: 870px) {
    .windowgridfour {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    
        width: 100%;
        margin: 0 auto 25px 0;
    }

    
}


/* BELOW IS FOR BAR AND CLUB CARD IN PARTY WINDOW. I'VE PUT IT HERE BECAUSE IT'S A VARIATION OF ABOVE. */


.newgridcal {
    position: absolute;
    top: 4px;
    right: 6px;
    height: 38px;
    /* height: 25px; */
    width: 38px;
    /* width: 140px; */
    /* padding: 5px 6.5px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    
    /* font-size: 0.77rem; */
    font-weight: 700;
    /* color: #BC00FE; */
    /* color: white; */
    letter-spacing: 0.02rem;
   
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);

    backdrop-filter: saturate(180%) blur(10px);
    
    
}

.newgridcal2 {
    position: absolute;
    top: 4px;
    right: 6px;
    /* height: 38px; */
    /* height: 25px; */
    /* width: 38px; */
    /* width: 140px; */
    /* padding: 5px 6.5px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    
    /* font-size: 0.77rem; */
    font-weight: 700;
    /* color: #BC00FE; */
    /* color: white; */
    letter-spacing: 0.02rem;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, .8); */
    /* background-color: rgba(0, 0, 0, 1); */

    /* background-color: rgba(36, 36, 36, 0.8);
    backdrop-filter: saturate(180%) blur(10px); */
    background-color: black;
    /* background-image: linear-gradient(
        45deg,
        #FF007A, #FF00FF 50%, #14EAFC 80%, #0BFFB6
        ); */
        


    /* backdrop-filter: saturate(180%) blur(10px); */
    /* border: 1px solid #0BFFB6; */
    /* color: #FF80FF; */
    /* color: #FF00FF; */
    /* text-shadow: .2px .2px 0 #e600e6; */

    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    /* box-shadow: 0 0 2px fuchsia, 0 0 2px fuchsia; */

    /* color: white; */

    font-size: .8rem;
    /* margin: 1px 0; */
    /* margin-bottom: .5px; */
    font-family: Montserrat;
    padding: 4px 8px;
}


.newgridcaltt {
    position: absolute;
    top: 8px;
    right: 6px;
    /* height: 35px; */
    /* height: 25px; */
    /* width: 50px; */
    /* width: 140px; */
    /* padding: 5px 6.5px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-family: Gilroy;
    font-size: 0.75rem;
    font-weight: 600;
    /* color: #BC00FE; */
    /* color: white; */
    letter-spacing: 0.02rem;
    color: white;
    /* background-color: rgba(255, 255, 255, .3);
    backdrop-filter: saturate(180%) blur(10px);
    background-color: rgba(181, 181, 181, .2);
    backdrop-filter: saturate(180%) blur(10px);
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: saturate(180%) blur(10px); */
    /* border: 1px solid #0BFFB6; */
    /* background-color: #000; */
    background-color: transparent;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);

    backdrop-filter: saturate(180%) blur(10px);
    /* text-shadow: .5px .5px 0 white; */

    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    /* box-shadow: 0 0 2px fuchsia, 0 0 2px fuchsia; */
    padding: 3px 7px;
    /* text-transform: uppercase; */
}



.topdate {
    font-size: .8rem;
    /* margin: 1px 0; */
    margin-bottom: .5px;
    font-family: Gilroy;
    /* background: -webkit-linear-gradient(200deg, #FF007A 10%, #FF00FF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        color: white;
       
}

.botdate {
    font-size: 0.65rem;
    text-transform: uppercase;
    margin-top: .5px;
    margin-bottom: .5px;
    font-family: Gilroy;
    /* background: -webkit-linear-gradient(45deg, #FF007A, #FF00FF, #14EAFC);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        /* background: -webkit-linear-gradient(200deg, #FF007A 10%, #FF00FF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
        color: white;
}

/* eg using below for tour cards */


.gridviewbottomcontainer {
    position: absolute;
    bottom: 0%;
    height: 37%;
    /* height: 100px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
}

.gridviewbottomcontainer19 {
    position: absolute;
    bottom: 0%;
    /* height: 35%; */
    height: 60px;
    /* padding-bottom: 10px; */
    /* height: 100px; */
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */




    
}

.gridviewbottomcontainernewnew {
    position: absolute;
    bottom: 0%;
    height: 37%;
    /* height: 100px; */
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
}

.trybox {
    height: fit-content;
    /* border: 1px solid blue; */
}

.trybox2 {
    height: fit-content;
    width: 100%;
    /* border: 1px solid blue; */
    margin-bottom: 15px;
}

.trihq {
    /* height: 70px; */
    /* filter: 
   
     drop-shadow(0 0 35px fuchsia)
     drop-shadow(0 0 35px fuchsia)
  
    ; */

    /* width: 170px; */
    /* width: 20px; */



    /* width: 28px; */
    width: 30px;
}

.trihq2 {
    /* height: 70px; */
    /* filter: 
   
     drop-shadow(0 0 35px fuchsia)
     drop-shadow(0 0 35px fuchsia)
  
    ; */

    width: 180px;
    /* width: 20px; */
}

.gridviewbottomcontainertry {
    /* position: absolute;
    bottom: 0%; */
    /* height: 80px; */
    /* height: 100px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
}

.gridviewbottomcontfeat {
    position: absolute;
    bottom: 0%;
    height: 100%;
    /* height: 100px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: end;
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
}

.gridviewbottomcontainer3 {
    /* position: absolute; */
    
    height:81%;
    /* height: 100px; */
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* display: flex; */
    align-items: end;
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
    
}

.gridviewunderbottomcontainer3 {
    /* position: absolute; */
    
    height: 19%;
    /* height: 100px; */
    width: 100%;
    
    /* justify-content: space-between; */
    /* align-items: center; */
    /* border: 1px solid yellow; */
    /* margin-bottom: 2px; */
    display: flex;
    padding: 7px 18px 0 18px;
    /* align-items: flex-start; */
}

.undmg {
    margin-right: 7px;
}

.underbub {
    padding: 5px 8px 4px 8px;
    background-color: rgba(81, 81, 81, 0.5);
    backdrop-filter: saturate(180%) blur(10px);
    /* background-color: #000; */
    border-radius: 12px;
    color: fuchsia;
    /* font-weight: bold; */
    font-weight: 600;
    font-family: Gilroy;
    letter-spacing: 0.03rem;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-self: flex-start; */
    color: #0BFFB6;
    /* border: 1px solid #0BFFB6; */
    align-self: start;
    text-transform: uppercase;
}

.underbub2 {
    margin-top: 3px;
    color: white;
    font-family: Montserrat;
    font-size: 0.9rem;
    
}

.gridviewbottomcontBC {
    position: absolute;
    bottom: 2%;
    height: 31%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid yellow; */
}

@media (max-width: 374px) {
    .gridviewbottomcontainer {
        position: absolute;
        bottom: 2%;
        height: 31%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* border: 1px solid yellow; */
        /* margin-bottom: 2px; */
    }
}

/* currently only using for very small people cards in drag expo window */
.gridviewbottomcontaineralt {
    position: absolute;
    bottom: 0%;
    /* height: 40%; */
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: end;
    /* border: 1px solid yellow; */
    padding-bottom: 10px;
}


.gridviewtitlecontainer {
    /* padding-right: 2px; */
    height: 100%;
    /* width: 65%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* border: 1px solid red; */
}




/* BELOW IS FOR CARDS THAT JUST HAVE A TITLE AT THE BOTTOM EG BAR AND CLUB CARD IN PARTY WINDOW. I'VE PUT IT HERE BECAUSE IT'S A VARIATION OF ABOVE. */
.gridviewtitlecontainerfull {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
}




.gridviewBCballs {
    position: absolute;
    top: 4px;
    right: 10px;
    /* height: 40px; */
    /* width: 140px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    /* border: 1px solid red; */
}



.gridballop {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.65rem;
    font-weight: bold;
    /* color: #0BFFB6; */
    font-family: Montserrat;
    /* font-size: .82rem; */
    font-weight: bold;
    /* text-shadow: 1px 1px 0 #333; */
    color: #0BFFB6;
    background-color: #222;
    /* box-shadow: 0 0 2px #0BFFB6, 0 0 2px #0BFFB6, 0 0 2px #0BFFB6; */
    /* margin-left: 1px; */
    /* margin-bottom: 1px; */
}

.gridballcl {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.6rem;
    font-weight: bold;
    color: #444;
    font-family: Montserrat;
    
    
    background-color: #000;
    /* opacity: 0.3; */
    margin-left: 1px;
    margin-bottom: 1px;
}





.BCcardball {
    height: 21px;
    width: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #DEDEDE;
    /* color: fuchsia; */
    font-size: 0.67rem;
    font-weight: bold;
    /* background-image: linear-gradient(
        90deg,
        #111, #333, #666
    ); */
    background-image: linear-gradient(
        120deg,
        #402565 40%, #30BE96
    );
    box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    margin: 1px 0;
}



@media (min-width: 510px) and (max-width: 599px) {
   

    .BCcardball {
        height: 17px;
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* color: #7df9ff; */
        /* color: fuchsia; */
        font-size: 0.55rem;
        font-weight: bold;
        /* background-image: linear-gradient(
            90deg,
            #111, #333, #666
        ); */
        color: #DEDEDE;
    /* color: fuchsia; */
    /* font-size: 0.67rem; */
    
        box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        margin: 2px 0;
    }
}

@media (min-width: 690px) and (max-width: 789px) {
   

    .BCcardball {
        height: 17px;
        width: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* color: #7df9ff; */
        /* color: fuchsia; */
        font-size: 0.55rem;
        font-weight: bold;
        /* background-image: linear-gradient(
            90deg,
            #111, #333, #666
        ); */
        color: #DEDEDE;
    /* color: fuchsia; */
    /* font-size: 0.67rem; */
    
        box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        margin: 2px 0;
    }
}




@media (max-width: 319px) {
   
  

    .gridviewbottomcontainer {
        position: absolute;
        bottom: 2%;
        height: 22%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* border: 1px solid yellow; */
        /* margin-bottom: 2px; */
    }

    .gridviewbottomcontBC {
        
        height: 21%;
        
    }



   
}



/* BELOW IS FOR CARDS THAT JUST HAVE A TITLE AT THE BOTTOM EG BAR AND CLUB CARD IN PARTY WINDOW. I'VE PUT IT HERE BECAUSE IT'S A VARIATION OF ABOVE. */
.gridviewtitlefull {
    padding: 0 18px;
    /* margin-bottom: 10px; */
    font-family: Gilroy;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    /* text-shadow: 1px 2px 0px #222; */
    /* text-shadow: 1px 1px 0px fuchsia; */
    /* text-transform: uppercase; */
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* border: 1px solid yellow; */
    position: relative;
}



.gridviewtitlefulloth.extop {
    margin-top: 8px;
}

.gridviewtitlefulloth {
    margin-top: 4px;
    padding: 0 12px;
    text-align: left;
    /* margin-bottom: 10px; */
    font-family: Gilroy;
    color: white;
    font-size: 1.14rem;
    font-weight: bold;
    /* text-shadow: 1px 2px 0px #222; */
    /* text-shadow: 1px 1px 0px fuchsia; */
    /* text-transform: uppercase; */

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* border: 1px solid yellow; */
    position: relative;
}




.gridviewtimecontainer {
    height: 100%;
    /* width: 35%; */
    width: 25%;
    display: flex;
    align-items: center;
    /* border: 1px solid yellow; */
}

.gridviewtimecontaineralt {
    height: 100%;
    width: 26%;
    display: flex;
    align-items: center;
    /* border: 1px solid yellow; */
}

.gridviewtimecontaineroth {
    height: 100%;
    /* width: 35%; */
    width: 32%;
    display: flex;
    align-items: center;
    /* border: 1px solid yellow; */
}







.dragtoursoldout {
    font-size: .8rem;
    color: #FF1493;
}



.livefromnewoth {
    /* color: grey; */
    color: #d4d4d4;
    text-shadow: none;
    /* text-shadow: 1px 1px 0 #333; */
    font-weight: lighter;
    font-style: italic;
    font-size: 0.65rem;
    /* margin-bottom: 0.5px; */
    letter-spacing: 0.01rem;
    font-family: Gilroy;
    margin-right: 2.5px;
}





.colorw {
    /* color: fuchsia;
    font-size: .72rem;
    margin-top: 0.5px;
    padding: 0 8px;
    width: 100%;
    text-align: center; */

    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */

    /* text-transform: uppercase; */
    margin-left: 2.5px;
    font-size: 0.7rem;
    /* text-shadow: 1px 1px 0 #000; */
    /* text-shadow: 1px 1px 0 fuchsia; */
    /* color: #FFE6FF; */
    /* text-shadow: 1px 1px 0 fuchsia; */
    /* color: #FF80FF; */
    color: white;
    /* text-shadow: .5px .5px 0 fuchsia; */
}



.livewindowbox {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 88%;
    border: 1px solid #FF1493;
    border-radius: 15px;
    background-color: rgba(121, 121, 121, 0.15);
    backdrop-filter: saturate(180%) blur(10px);
}



.newliveboxcont {
    /* padding: 6px 14px; */
    /* border-radius: 12px; */
    /* animation: blink-text 1.9s ease-in-out infinite; */
    
    /* border: 1px solid #FF1493; */
    /* margin: 0 2px; */
    margin-bottom: 19px;
    
    
}

.newlivebox {
    display: inline-block;
    /* padding: 6px 14px; */
    /* border-radius: 12px; */
    /* background-color: #FF1493; */
    font-size: 1.05rem;
    padding: 6px 13px;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: bold;
    color: #FF1493;
    letter-spacing: 0.08rem;
    /* background-image: linear-gradient(
        40deg,
        #FF1493, #777, #FF1493
    ); */
    /* background-image: linear-gradient(
        0deg,
        #111, #222, #333, #444
    ); */
    /* box-shadow: 0 0 3px #FF1493, 0 0 3px #FF1493, 0 0 3px #FF1493; */
    background-color: rgba(0, 0, 0, 0.6);

    backdrop-filter: saturate(180%) blur(10px);
}

.livewindowboxcity {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 90%;
    /* border: 1px solid #FF1493; */
    
}



.colfuc {
    color: fuchsia;
}

@keyframes blink-text {
    0% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
    50% {
    opacity: 0.6;
    text-shadow: none;
    }
    100% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
}

@-webkit-keyframes blink-text {
    0% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
    50% {
    opacity: 0.6;
    text-shadow: none;
    }
    100% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
}

@-moz-keyframes blink-text {
    0% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
    50% {
    opacity: 0.6;
    text-shadow: none;
    }
    100% {
        opacity: 1;
        text-shadow:
       /* White glow */
       0 0 1px #ffb5c4;
       /* 0 0 10px #FF1493; */
       /* 0 0 2px rgb(0, 255, 255); */
       /* 0 0 3px #fff; */
    }
}




.noupcomingpartiescontainer {
    line-height: 1.3rem;
    color: grey;
}

.noparties {
    /* padding: 3px 15px 10px 15px; */
    padding: 5px 18px 0px 18px;
    /* width: 96%; */
    font-family: Montserrat;
    color: white;
    font-size: .9rem;
    color: #949494;
}

.noparties2 {
    /* padding: 3px 15px 10px 15px; */
    padding: 20px;
    /* width: 96%; */
    font-family: Montserrat;
    color: white;
    font-size: .9rem;
    color: #949494;
}

.cnttr {
    text-align: center;
}

.gridviewlineheightsm {
    line-height: 1.4rem;
}

.gridviewlineheightbg {
    line-height: 1.95rem;
}

.negm {
    margin-top: -5px;
}

.today, .upcoming {
    font-weight: bold;
    background: -webkit-linear-gradient(34deg, #BC00FE, fuchsia);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }






.dis {
    display: inline-block;
}

@media (max-width: 380px) {
   

    .largedesccont {
    
        width: 75%;
        font-family: Montserrat;
        font-size: 0.8rem;
        color: white;
        margin: 0 0 12px 18px;
    
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .helpicon {
        font-size: 1.4rem;
        /* margin: 0 6px; */
        margin-right: 14%;
        color: #ccc;
    }
}

@media (max-width: 350px) {
    .dis {
        display: none;
    }

    .largedesccont {
    
        width: 75%;
        font-family: Montserrat;
        font-size: 0.8rem;
        color: white;
        margin: 0 0 12px 18px;
    
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .helpicon {
        font-size: 1.3rem;
        /* margin: 0 6px; */
        margin-right: 14%;
        color: #ccc;
    }
}

.orgtxt {
    background: -webkit-linear-gradient(90deg, #E100FF, #FF55EC);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bltxt {
    background: -webkit-linear-gradient(34deg, #36D1DC, #5B86E5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gridviewsectionheader2 {
    padding: 4px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    
}

.gridviewsectionheaderft {
    padding: 4px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    font-size: 1.2rem;
    
}

.gridviewsectionname2 {
    padding: 0 14px 3px 14px;
    /* color: fuchsia; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* background: -webkit-linear-gradient(90deg, fuchsia, #ff77ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  /* font-family: Gilroy; */
  /* border: 1px solid red; */
}
.gridviewsectionnamecs {
    line-height: 2rem;
    padding: 0 14px 3px 14px;
    /* color: fuchsia; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* background: -webkit-linear-gradient(90deg, fuchsia, #ff77ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: white;
  font-family: Gilroy;
  /* font-weight: bold; */
  /* font-family: Gilroy; */
  /* border: 1px solid red; */
  
}

.plscs {
    text-decoration: underline;
}

.seeall {
    cursor: pointer;
    padding: 0 14px 3px 14px;
    font-size: 1rem;
    color: #BC00FE;
    /* color: white; */
    font-family: Gilroy;
    /* margin-bottom: -20px; */
    /* border-bottom: 1px solid fuchsia; */
    font-weight: bold;
    text-decoration: underline;
}




.sechead1, .sechead2, .sechead3 {
    width: 25%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid yellow; */
    /* font-family: Gilroy; */
    font-weight: bold;
}

.sechead1 {
    justify-content: start;

}

.sechead3 {
    justify-content: end;
}

.sechead2 {
    flex-grow: 1;
    justify-content: center;
    /* color: fuchsia; */
}

.secbbl {
    padding: 4px 12px;
    border-radius: 12px;
    background-color: #444;
}



.icncont {
    font-size: .85rem;
    margin: 0 6px;
    color: #999;
}



.pinkcol {
    color: #999;
}

.pinkcolub {
    color: #999;
    font-weight: 500;
}

.endclip {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.whitecol {
    color: white;
}

.mgbel {
    margin-bottom: 8px;
}

.gridviewsectionheader6 {
    /* padding: 10px 0; */
    /* width: 96%; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;
    color: #aaa;
    /* color: white; */
    font-family: Gilroy;
    /* margin-bottom: -20px; */
    /* border-bottom: 1px solid fuchsia; */
    font-weight: bold;
    /* position: relative;
    bottom: -2px; */
    
}

.testincldate {
    /* padding: 10px 0; */
    width: 96%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    
}



.extbox {
    height: 100%;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}






.padmargadj {
    margin-top: 12px;
    padding-bottom: 7px;
}

.padmargadj2 {
    margin-top: 20px;
    padding-bottom: 10px;
}

.padmargadj3 {
    margin-top: 30px;
    padding-bottom: 10px;
}

.gridleftline {
    width: 10%;
    padding: 0 5px;
}

.gridleftlinett {
    width: 15%;
    padding: 0 5px;
}



.gridrightline {
    /* width: 10%; */
    flex-grow: 1;
    padding: 0 5px;
}

.gridrightlinefx {
    /* width: 10%; */
    width: 8%;
    padding: 0 5px;
}


.topline {
    height: 50%;
    border-bottom: 2px solid #444;
}

.botline {
    height: 50%;
    border-top: 1px solid #333;
}

.toplineb {
    height: 50%;
    border-bottom: 2px solid #444;
}

.botlineb {
    height: 50%;
    border-top: 1px solid #333;
}

.gridviewsectionname {
    padding: 0 4px;
    /* color: fuchsia; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: -webkit-linear-gradient(90deg, fuchsia, #ff77ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: fuchsia;
}



.gridviewsectionnameb {
    padding: 0 4px;
    /* color: #637FFD; */
    /* background-image: linear-gradient(
        90deg,
        #ec008c, #fc6767
    ); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: -webkit-linear-gradient(34deg, #36D1DC, #5B86E5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #0BFFB6;
}



.gridviewsectionnamemg {
    padding: 0 4px;
    color: white;
}



/* special grid for people cards in drag tour window */

.windowgridtwo {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    margin: 0 auto 25px 0;
}

/* special grid for people cards in drag expo window */




.typedrag {
    background-image: linear-gradient(
        90deg,
        #E100FF, #FF55EC
    );
    text-transform: uppercase;
}

.typedj {
    background-image: linear-gradient(
    140deg,
    #7df8ff 10%, #535EEB, #535EEB
    );
}

.typesing {
    background-image: linear-gradient(
        90deg,
        #8E2DE2, #4A00E0
    );
    text-transform: uppercase;
}

.typeoth {
    background-image: linear-gradient(
        90deg,
        #ec008c, #fc6767
    );
    text-transform: uppercase;
}



.gridviewtitlefulltiny {
    padding: 0 6px;
    font-family: Gilroy;
    color: white;
    /* font-size: .9rem; */
    font-weight: bold;
    /* text-shadow: 1px 1px 1px grey; */
    /* hyphens: auto; */

    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; */
    /* border: 1px solid yellow; */

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}







/* SPECIAL CLASSES FOR BAR AND CLUB CARDS */



.BCdayscont {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}



#ballclosed {
    visibility: hidden;
}






@media (max-width: 319px) {
  

    .gridviewtitlefulltiny {
        /* padding: 0 4px; */
       
        
        font-size: .7rem;
       
    }

}

@media (max-width: 349px) {
   

    .gridviewtitlefull {
        /* padding: 0 4px; */
       
        
        font-size: .95rem;
       
    }

}

@media (max-width: 319px) {
   

    .gridviewtitlefull {
        /* padding: 0 4px; */
       
        
        font-size: 1.1rem;
       
    }

}

@media (min-width: 320px) and (max-width: 374px) {
   

    .gridviewtitlefulltiny {
        /* padding: 0 4px; */
       
        
        font-size: .83rem;
       
    }

}

@media (min-width: 375px) {
   

    .gridviewtitlefulltiny {
        /* padding: 0 4px; */
       
        
        font-size: .75rem;
       
    }

}

@media (min-width: 425px) {
  

    .gridviewtitlefulltiny {
        /* padding: 0 4px; */
       
        
        font-size: .8rem;
       
    }

}

@media (min-width: 475px) {
  

    .gridviewtitlefulltiny {
        /* padding: 0 4px; */
       
        
        font-size: .85rem;
       
    }

}

/* THREE BY THREE GRID FOR PEOPLE PAGE */




.gridviewcardPP {
    position: relative;
    width: 96%;
    aspect-ratio: 1/1;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 1px 0px rgba(181,181,181,0.15),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ;
    margin: 3px 0;
  }

.gridviewcardimagePP {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.gridviewcardimagecoverPP {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-image: linear-gradient(
        0deg,
        rgba(65,65,65,.9),
        rgba(65,65,65,.6),
        rgba(65,65,65,.3),
        transparent,
        transparent,
        transparent
    );
}

.gridviewlikebuttoncontainerPP {
    position: absolute;
    top: 2px;
    right: 3px;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.7);

    backdrop-filter: saturate(180%) blur(10px);
}


.gridviewbottomcontainerPP {
    position: absolute;
    bottom: 5%;
    height: 27%;
    width: 100%;
    display: flex;
    /* border: 1px solid red; */
}




@media (max-width: 400px) {
   

    .BCcardball {
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* color: #7df9ff; */
        /* color: fuchsia; */
        font-size: 0.57rem;
        font-weight: bold;
        /* background-image: linear-gradient(
            90deg,
            #111, #333, #666
        ); */
        box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        margin: 1px 0;
        color: #DEDEDE;
    /* color: fuchsia; */
    /* font-size: 0.67rem; */
    
    }



  

  
    

}

@media (max-width: 374px) {

    

   
    .BCcardball {
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* color: #7df9ff; */
        /* color: fuchsia; */
        font-size: 0.5rem;
        font-weight: bold;
        /* background-image: linear-gradient(
            90deg,
            #111, #333, #666
        ); */
        color: #DEDEDE;
    /* color: fuchsia; */
    /* font-size: 0.67rem; */
    
        box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        margin: 1px 0;
    }

 



}


@media (max-width: 350px) {
   

    .gridviewsectionheader6 {
        /* padding: 10px 0; */
        /* width: 96%; */
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      
        font-size: .85rem;
        color: #aaa;
        /* color: white; */
        font-family: Gilroy;
        /* margin-bottom: -20px; */
        /* border-bottom: 1px solid fuchsia; */
        font-weight: bold;
        /* position: relative;
        bottom: -2px; */
        
    }

    .seeall {
        padding: 0 14px 3px 14px;
        font-size: .85rem;
        color: #BC00FE;
        /* color: white; */
        font-family: Gilroy;
        /* margin-bottom: -20px; */
        /* border-bottom: 1px solid fuchsia; */
        font-weight: bold;
        text-decoration: underline;
    }
}








@media (max-width: 319px) {
    
    


    .BCcardball {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* color: #7df9ff; */
        /* color: fuchsia; */
        font-size: 0.65rem;
        font-weight: bold;
        /* background-image: linear-gradient(
            90deg,
            #111, #333, #666
        ); */
        color: #DEDEDE;
    /* color: fuchsia; */
    /* font-size: 0.67rem; */
    
        box-shadow: 0px 0px 0px 0px rgba(191,191,191,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        margin: 3px 0;
    }
}

/* GRID CARDS FOR CRUISE (JUST FOR PEOPLE WINDOW SINCE MAIN SECTION ONLY HAS LIST CARDS) */

/* .gridviewcardpricefrom {
    font-size: 0.65rem;
    font-style: italic;
    font-weight: bold;
    margin-right: 4.5px;
} */


/* INFO BUTTON */

ion-fab-button {
  
    /* border: 2px solid fuchsia; */
    --background: fuchsia;
      --background-activated: fuchsia;
    
    }

ion-fab-button::part(native) {
    /* background-color: #ec008c; */
    /* background: rgba(121,121,121,.4); */
    background-color: fuchsia;
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 50%;
    /* border: 2px solid #FF1493; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    /* color: fuchsia; */
  }

  .infoicon {
    color: white;
    /* background-color: #FF1493; */
    /* color: white; */
    font-size: 1.3rem;
  }

  .infoiconhlp {
    color: white;
    /* background-color: #FF1493; */
    /* color: white; */
    font-size: 1.4rem;
  }

  .fabbord {
    /* border: 1px solid #FF1493; */
  }




@media (min-width: 820px) {
   


    .gridviewsectionheaderft {
        display: none;
        
    }

    .gridviewgridfix {
        display: none;
    }
}

