@media (max-width: 619px) {
    .windowcontainerwithgrad {
        position: relative;
        width: 100%;
        min-height: 100vh;
        /* above is because gradient would extend to portion of screen if not enough content */
        /* font-family: Poppins, sans-serif; */
        /* background-image: linear-gradient(
        to top,
        #444, #333, #222, #222, #222
        ); */

        
        /* background-image: linear-gradient(
        to top,
        #444, #333, #333, #222, #222, #222, #222, #222, #222
        ); */

        background-color: #111;
        
    }

    .windowcontainerslowgrad {
        position: relative;
        width: 100%;
        min-height: 100vh;
        /* above is because gradient would extend to portion of screen if not enough content */
        /* font-family: Poppins, sans-serif; */
        /* background-image: linear-gradient(
        to top,
        #444, #333, #222, #222, #222, #222, #222, #222, #222
    )   ; */
        background-color: #111;
    }
}


.innerwindowflex {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notfoundmsg {
    width: 80%;
    font-family: Montserrat;
    font-size: 1.25rem;
    color: #949494;
    text-align: center;
}

.btntabcont {
    position: fixed;
    top: 45px;
    left: 0px;
    width: 100%;
    /* height: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.btntab {
    width: 75%;
    /* background-color: #000; */
    height: 50px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.75);
}

.ionbackbuttoncont {
    position: fixed;
    /* top: 10vw; */
    /* top: 10vw; */
    top: 45px;

    /* left: 3.75vw; */
    left: 20px;
    width: 33px;
    height: 33px;
    border-radius: 8px;
    border-radius: 50%;;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    /* background-color: rgba(255, 0, 255, 0.10); */
    /* background-color: rgba(255, 0, 255, 0.30); */
    background-color: rgba(0, 0, 0, 0.55);

    /* backdrop-filter: saturate(180%) blur(10px); */
    backdrop-filter: saturate(180%) blur(20px);
    /* border: 1px solid #0BFFB6; */
}

ion-back-button.backsize {
    color: #fff;
    /* why is it automatically fuchsia */
    /* font-size: .65rem !important; */
    /* height: 30px; */
    --icon-font-size: 1.2rem;
    --icon-margin-end: 2px;

}

.mainmenucont {
    position: fixed;
    /* top: 10vw; */
    top: 45px;
    /* left: 17vw; */
    /* left: 70vw; */
    /* left: calc(7.5vw + 45px); */
    left: 60px;
    width: 33px;
    height: 33px;
    /* border-radius: 8px; */
    border-radius: 50%;
    /* border: 1px solid #0BFFB6; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    /* background-color: rgba(255, 0, 255, 0.10); */
    /* background-color: rgba(188, 0, 254, 0.4); */
    background-color: rgba(0, 0, 0, 0.65);

    /* backdrop-filter: saturate(180%) blur(10px); */
    backdrop-filter: saturate(180%) blur(20px);
}

@media (min-width: 520px) {
    .mainmenucont {
       
        /* left: calc(7.5vw + 45px); */
        
    }
}



.windowmenutog {
    color: #fff;
    font-size: 1.1rem;
    /* margin-left: 1px; */
}

/* LIKE BUTTON */

.windowheartcont {
    position: fixed;
    /* top: 10vw; */
    top: 45px;

    right: 20px;
    width: 33px;
    height: 33px;
    /* border-radius: 8px; */
    border-radius: 50%;
    /* border: 1px solid #FF1493; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    /* background-color: rgba(255, 255, 255, 0.10); */
    /* background-color: rgba(255, 20, 147, 0.40); */
    background-color: rgba(0, 0, 0, 0.65);

    backdrop-filter: saturate(180%) blur(10px);
}

.windowheartcont2 {
    position: fixed;
    top: 40vw;
    left: 3.75vw;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    /* background-color: rgba(255, 255, 255, 0.10); */
    /* background-color: rgba(255, 20, 147, 0.40); */
    background-color: rgba(0, 0, 0, 0.65);

    backdrop-filter: saturate(180%) blur(10px);
}

.windowheart {
    font-size: 1.8rem;
    font-size: 1.7rem;
    font-size: 1.18rem;
    z-index: 4;
    margin-top: 1px;
    /* padding-right: 0px; */
    /* the above centers it better */
}

/* .windowheart.extc {
    background-color: #FF1493;
} */

.windowheartlit {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: radial-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.8),
        rgba(255,255,255,0.7),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.1),
        transparent,
        transparent,
        transparent);
    z-index: 1;
  }

  /* TOP PART */

.windowtopcont {
    position: relative;
    height: 20%;
    /* border: 2px solid green; */
}

.windowimgbox {
    width: 100%;
    height: 100%;
    min-height: 450px;
    object-fit: cover;
    /* border-radius: 0 0 20px 20px; */
}

.windowimggrad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: linear-gradient(
        0deg,
        rgba(17,17,17, 1),
        rgba(17,17,17, .9),
        rgba(17,17,17, .8),
        rgba(17,17,17, .6),
        rgba(17,17,17, .2),
        transparent,
        transparent,
        transparent,
        transparent,
        transparent
    );
}

.windowtitlebox {
    position: relative;
    height: 100%;
    width: 85%;
    display: flex;
    align-items: end;
    /* border: 1px solid red; */
}






.windowtitlefull {
    position: relative;
    width: 100%;
    /* height: 70%; */
    font-family: Montserrat;
    font-size: 2.3rem;
    font-weight: bold;
    color: white;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* text-shadow: 1px 1px 0 fuchsia; */
    padding-bottom: 26px;

    /* text-transform: uppercase; */

    /* background:linear-gradient(#333,#444,#333);
    -webkit-background-clip: text;
    color:transparent; */

    /* text-shadow: 1px 1px 1px #fff; */
    /* -webkit-text-stroke: .5px #fff; */
   /* color: white; */
   /* text-shadow:
       1px 1px 0 #fff,
     -1px -1px 0 #888,  
      1px -1px 0 #888,
      -1px 1px 0 #888,
       1px 1px 0 #888; */
    
}



.titleboxright {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
}






/* MAIN SECTION */

.windowmainsetwidth {
    width: 85%;
    margin: 0 auto;
}

.windowdatetimecont {
    display: flex;
    /* justify-content: center; */
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    /* background-color: #444; */
    /* padding: 15px; */
    /* border-radius: 10px; */
}

.windowdatetimecontf {
    /* display: flex; */
    /* justify-content: center; */
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
    margin-bottom: 24px;
    /* background-color: #444; */
    /* padding: 15px; */
    /* border-radius: 10px; */
}

.datetimebox {
    /* border: 1px solid red; */
    /* width: 100%; */
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.datetimeboxf {
    /* border: 1px solid red; */
    /* width: 100%; */
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
}

.datetimeboxt {
    /* border: 1px solid red; */
    /* width: 100%; */
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}



.datetimeboxlv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datetimeicon {
    font-size: 1.8rem;
    font-size: 1.4rem;
    color: #0096FF;
    color: #0BFFB6;
    /* color: #888; */

    /* background: -webkit-linear-gradient(#5260ff, aqua);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

    margin-right: 12px;
}

.anothbubble {
    margin-left: 7.5px;
    padding: 5px 9px;
    /* background-color: #0BFFB6; */
    border-radius: 10px;
    color: #0BFFB6;
    border: 1px solid #0BFFB6;
}

.datetimeiconme {
    font-size: 2.1rem;
    color: fuchsia;

    /* background: -webkit-linear-gradient(#5260ff, aqua);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.datetimeiconlv {
    font-size: 2.6rem;
    color: fuchsia;

    /* background: -webkit-linear-gradient(#5260ff, aqua);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.datebox {
    padding: 0;
    /* margin-left: 11px; */
}

.dateboxlv {
    padding: 0;
}

.smalldot {
    height: 5px;
    width: 5px;
    background-color: #0BFFB6;
    border-radius: 50%;
    margin: 0 12px 0 13px;
}

.smalldot2 {
    height: 5px;
    width: 5px;
    background-color: #FF1493;
    border-radius: 50%;
    margin: 0 12px 0 13px;
}

.timebox {
    padding: 0;
    /* margin-left: 9px; */
}

.citybox {
    padding: 0;
    margin-left: 4px;
}

.neoncal {
    /* width: 75%; */
    height: 40px;
  }

.datetimetext {
    display: flex;
    /* justify-content: center; */
    align-items: center;
   
    font-family: Montserrat;
    /* font-size: 0.88rem; */
    font-weight: bold;
    /* color: #0096FF; */
    color: #0BFFB6;
    font-size: 1.1rem;
    
    font-size: 1.05rem;
    /* font-style: italic; */
        /* font-weight: bold; */
        /* color: white; */
        letter-spacing: 0.02rem;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
}

.datetimetextoth {
    display: flex;
    /* justify-content: center; */
    align-items: center;
 
    font-family: Montserrat;
    /* font-size: 0.88rem; */
    font-weight: bold;
    /* color: #0096FF; */
    color: white;
    font-size: 1.1rem;
 
    font-size: 1.05rem;
    /* font-style: italic; */
        /* font-weight: bold; */
        /* color: white; */
        letter-spacing: 0.02rem;

        display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.datetimetext2 {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    
    font-family: Montserrat;
    /* font-size: 0.88rem; */
    font-weight: bold;
    /* color: #0096FF; */
    color: #FF1493;
    font-size: 1.1rem;
   
    font-size: 1.05rem;
    /* font-style: italic; */
        /* font-weight: bold; */
        /* color: white; */
        letter-spacing: 0.02rem;
}

.pdri {
    padding-right: 6px;
}



.windowmainboxstd {
    width: 100%;
    margin-bottom: 20px;
    /* should it be padding or margin */
}

.windowmainboxstdf {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    display: flex;
    /* should it be padding or margin */
}

.windowmainboxstdrev {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.windowmainboxvenue {
    width: 50%;
    max-width: 200px;
    margin-bottom: 27px;
    /* should it be padding or margin */
}
/* the above box ensures that the venue card can use gridviewcardstd class */

.windowmainheadingstd {
    padding-bottom: 7px;
    text-transform: uppercase;
    /* or make JSX capital */
    font-family: Montserrat;
    font-size: .9rem;
    /* font-weight: bold; */
    color: #999;
    letter-spacing: .04rem;
}

.windowmainheadingrv {
    /* padding-bottom: 7px; */
    text-transform: uppercase;
    /* or make JSX capital */
    font-family: Montserrat;
    font-size: .9rem;
    /* font-weight: bold; */
    color: #999;
    letter-spacing: .04rem;
}

.addflx {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 8px;
}

.windowmainheadingflex {

    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-bottom: 7px; */
    text-transform: uppercase;
    /* or make JSX capital */
    font-family: Montserrat;
    font-size: .9rem;
    /* font-weight: bold; */
    color: #999;
    letter-spacing: .04rem;
}

.mgab {
    margin-top: 6px;
}

.mgab2 {
    margin-top: 3px;
}

.pinkfont {
    color: fuchsia;
    /* filter:
  
   drop-shadow(0px 0px 10px fuchsia)
 
  ;
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; */
}

.pinkfont2 {
    color: fuchsia;
    filter:
   /* drop-shadow(0px 0px 35px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
   drop-shadow(0px 0px 10px fuchsia)
   /* drop-shadow(0px 0px 25px fuchsia) */
   /* drop-shadow(0px 0px 40px fuchsia)
   drop-shadow(0px 0px 40px fuchsia) */
  ;
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 20px;
}

.livenow {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* width: 10%; */
    height: 100%;
    padding-right: 10px;
    color: #FF1493;
}

.livelines {
    height: 100%;
    /* width: 100%; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    flex-grow: 1;
}

.livelinet {
    height: 50%;
    /* border-bottom: .5px solid fuchsia; */
    /* flex-grow: 1; */
}

.livelineb {
    height: 50%;
    border-top: .5px solid #FF1493;
    /* flex-grow: 1; */
}

/* .bdrbtm {
    border-bottom: 0.5px solid fuchsia;
} */



.windowmaintextboxstd {
    width: 100%;
    font-family: Montserrat;
    font-size: 1.05rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    /* color: white; */
    font-weight: 500;
}

.redfont {
    color: #FF1493;
    background-color: #444;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 1.05rem;
}





.exclambox {
    height: 100%;
    width: 11%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.exclam {
    font-size: 1.8rem;
    color: #FF1493;
    /* color: #FF9933; */
}

.exclamtext {
    padding: 0 10px 0 0;
    padding: 10px;
    width: 89%;
    background-color: #444;
    border-radius: 0 15px 15px 0;
    /* object-fit: contain; */
}

.extrapadorg {
    padding-right: 12px;
}
/* the above is for the party organiser text if theres also ticket vendor info. note some styling is in jsx. dont need it if just party organiser (and therefore 100pc width) but doesnt matter */






.windowmainvenuecardbox {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.windowvenueleft {
    /* width: 70%; */
    height: 100%;
    font-family: Montserrat;
    font-size: 1.05rem;
    line-height: 1.5rem;
    flex-grow: 1;
    padding-right: 10px;
}

.addresshasvenuename {
    font-size: 1.1rem;
    font-weight: bold;
    
}

.addmgb {
margin-bottom: 1px;
}

.addressfirstrowflexbox {
    display: flex;
}

.limitaddresstooneline {
    display: flex;
    display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      /* border: 1px solid red; */
}

.windowvenueright {
    /* width: 30%; */
    display: flex;
    justify-content: end;
    align-items: center;
    /* border: 1px solid red; */
}

.windowvenueimg {
    height: 60px;
    width: 60px;
}

/* FLOATING BUTTONS AT BOTTOM OF WINDOW */


.floatingbuttoncont2 {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    /* justify-content: center; */
    justify-content: space-between;
    
    z-index: 6;
    /* border: 1px solid red; */

    background-color: rgba(17, 17, 17, 0.65);
    backdrop-filter: saturate(180%) blur(10px);
    /* border-top: 1px solid #0BFFB6; */
    /* margin: 0 auto; */
}

.floatingbuttoncontonething {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
  
    z-index: 6;
    /* border: 1px solid red; */

    background-color: rgba(36, 36, 36, 0.55);
    background-color: rgba(17, 17, 17, 0.65);
    backdrop-filter: saturate(180%) blur(10px);
    /* border-top: 1px solid #0BFFB6; */
    /* margin: 0 auto; */
}



.wmcont {
    cursor: pointer;
    padding: 7px 12px;
    border-radius: 10px;
    background-color: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    /* min-width: 100%; */
        /* border: 1px solid red; */
    /* max-width: calc(100%-40px); */
    /* max-width: fit-content; */
    
}

.wmflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 80px; */
    /* border: 1px solid yellow; */
    width: calc(100% - 50px);
}

.wmtxt {
    font-family: Gilroy;
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 0.06rem;
    /* color: #999; */
    /* color: #d4d4d4; */
    color: white;
    /* background: -webkit-linear-gradient(90deg, #E100FF, #BC00FE);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-bottom: 2px;
}

.wmtxtb {
    font-family: Montserrat;
    font-size: 0.7rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    /* color: #999; */
    color: #999;
    /* background: -webkit-linear-gradient(90deg, #E100FF, #BC00FE);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-top: 3px;

  display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

}

.wmicon {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* color: #0BFFB6; */
    
    
     
    background-image: linear-gradient(
        90deg,
        fuchsia, #f80759
    );
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    color: white;
}

.wmicontt {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* color: #0BFFB6; */
    
    
        background-image: linear-gradient(
        0deg,
        #000, #111
    );
  
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    
}

.wmiconyou {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: #FF0000;
  
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    
}


.wmiconfb {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: #316FF6;
  
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    
}


.wmiconsc {
    width: 40px;
    height: 40px;
    
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* color: #0BFFB6; */
    
    
        background-image: linear-gradient(
        180deg,
        #8E2DE2, #4A00E0
    );
  
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    
}

.wmiconsc2 {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* color: #0BFFB6; */
    
    
        background-image: linear-gradient(
        180deg,
        #FF8800, #FF3300
    );
  
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    
}

.wmiconinsta {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
    45deg,
    #FA7E1E, #D62976, #962FBF
    );
    color: white;
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 2px 4px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
}

.aremove {
    text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
  display: block; /* Make anchor a block-level element to contain child elements properly */
}

.wmiconph {
    width: 40px;
    height: 40px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
    45deg,
    fuchsia, #FF1493
    );
    /* background-color: fuchsia; */
    color: white;
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 2px 4px 0px rgba(0,0,0,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
}

.insticn {
    font-size: 1.5rem;
    /* font-weight: bold; */
    /* color: #7DF9FF; */
    /* margin-bottom: 2px; */
}

.icoth {
    font-size: 1.4rem;
    /* font-weight: bold; */
    /* color: #7DF9FF; */
    /* margin-bottom: 2px; */
}

.icoth2 {
    font-size: 1.3rem;
    /* font-weight: bold; */
    /* color: #7DF9FF; */
    /* margin-bottom: 2px; */
}
  

.btnsect1 {
    width: 42.5%;
    /* border: 1px solid red; */
    height: 80%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding-right: 5px;
}

.btnsect1free {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 42.5%;
    /* border: 1px solid red; */
    height: 80%;
    /* padding-right: 5px; */
    margin-left: 5%;
}

.btnsect1phone {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* flex-direction: column; */
    width: 25%;
    /* border: 1px solid red; */
    /* height: 80%; */
    /* padding-right: 5px; */
    margin-left: 5%;
}

.btnsect2insta {
    width: 42.5%;
    /* border: 1px solid red; */
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-left: 5px; */
    margin-right: 7.5%;
}

.newpricehead {
   color: #D4D4D4;
    font-family: Gilroy;
    padding: 10px 0 0 0;
    /* font-weight: bold; */
    font-size: 1.05rem;
    letter-spacing: 0.01rem;
}

.newpriceheadfree {
    color: #D4D4D4;
     font-family: Gilroy;
     /* padding: 10px 0 0 10px; */
     font-size: 1.05rem;
    letter-spacing: 0.02rem;
 }



.newprice {
    font-family: Montserrat;
    font-size: 1.3rem;
    letter-spacing: 0.03rem;
    padding: 7px 0 0 0;
    color: #0BFFB6;
    font-weight: bold;
}

.newpricefree {
    font-family: Montserrat;
    font-size: 1.3rem;
    /* padding: 5px 0 0 10px; */
    color: #0BFFB6;
    /* margin-left: 5px; */
    margin-top: 4px;
    letter-spacing: 0.03rem;
    font-weight: bold;
}

.newpricesoldout {
    font-family: Montserrat;
    font-size: 1.15rem;
    /* padding: 5px 0 0 10px; */
    color: #FF1493;
    /* margin-left: 5px; */
    margin-top: 2px;
    letter-spacing: 0.04rem;
    font-weight: bold;
    
}

.btnsect2 {
    width: 42.5%;
    /* border: 1px solid red; */
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-left: 5px; */
    margin-right: 7.5%;
}

.btnsectonething {
    width: 75%;
    margin: 0 auto;
    /* border: 1px solid red; */
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-left: 5px; */
    /* margin-right: 5%; */
}

.mainwindowbtngreen {
    /* width: 77%; */
    width: 100%;
    /* height: 50px; */

    /* border-radius: 50px 8px; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #0BFFB6;
    letter-spacing: 0.03rem;
    text-shadow: .5px .5px 0 #333;
    /* background-image: linear-gradient(
        90deg,
        #7F00FF, #E100FF
        ); */
        /* background-image: linear-gradient(
        0deg,
        #111, #222, #333, #444
    ); */
    background-color: #000;
    border: 1.5px solid #0BFFB6;
        /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    font-family: Montserrat;
    /* transform: skew(-15deg); */
    /* padding: 15px 0 17px 0; */
    height: 51px;
cursor: pointer;


    /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */

}



.phnew {
    font-size: 1.2rem;
    color: #0BFFB6;
}

.mainwindowbtngreenph {
    /* width: 77%; */
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: #0BFFB6;
    
    
        background-image: linear-gradient(
        0deg,
        #111, #222, #333, #444
    );
    /* border: 3px solid #0BFFB6; */
        /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
   



    box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6;

}

.mainwindowbtngreenalt {
    /* width: 77%; */
    width: 85%;
    height: 63%;
    /* height: 50px; */

    /* border-radius: 50px 8px; */
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #0BFFB6;
    letter-spacing: 0.04rem;
    text-shadow: .5px .5px 0 #333;
    
        background-image: linear-gradient(
        0deg,
        #111, #222, #333, #444
    );
    /* border: 3px solid #0BFFB6; */
        /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    font-family: Montserrat;
    /* transform: skew(-15deg); */
    padding: 15px 0 17px 0;



    box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6;

}

.mainwindowbtnred {
    /* width: 77%; */
    width: 100%;
    /* height: 50px; */

    /* border-radius: 50px 8px; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #FF1493;
    letter-spacing: 0.03rem;
    text-shadow: .5px .5px 0 #333;
    border: 2px solid #FF1493;
        
    font-family: Montserrat;
    /* transform: skew(-15deg); */
    /* padding: 15px 0 17px 0; */
height: 55px;
background-color: #000;
cursor: pointer;

    /* box-shadow: 0 0 5px #FF1493, 0 0 5px #FF1493, 0 0 5px #FF1493; */

}

.sharebutton {
    width: 20%;
    height: 55px;
    border-radius: 15px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-image: linear-gradient(
        90deg,
        #000, #111, #333
        );
        box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    transform: skew(-15deg);
}

.mainwindowbtn {
    /* width: 77%; */
    width: 100%;
    height: 55px;

    /* border-radius: 50px 8px; */
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.05rem;
    font-weight: bold;
    color: #0BFFB6;
    letter-spacing: 0.05rem;
    text-shadow: .5px .5px 0 #DEDEDE;
    background-image: linear-gradient(
        90deg,
        #7F00FF, #E100FF
        );
        background-image: linear-gradient(
        0deg,
        #444, #111
    );
    border: 3px solid #0BFFB6;
        box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    
    transform: skew(-15deg);
}

.reserv {
    font-size: 1rem;
    letter-spacing: 0.03rem;
}

@media (max-width: 380px) {
    .mainwindowbtn {
        /* width: 77%; */
        width: 100%;
        height: 55px;
    
        /* border-radius: 50px 8px; */
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .95rem;
        font-weight: bold;
        color: white;
        letter-spacing: 0.05rem;
        text-shadow: .5px .5px 0 #DEDEDE;
        background-image: linear-gradient(
            90deg,
            #7F00FF, #E100FF
            );
            box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
            1px 3px 6px 0px rgba(36,36,36,0.7),
            2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        
        transform: skew(-15deg);
    }

    .reserv {
        font-size: .9rem;
        letter-spacing: 0.0rem;
    }
}

.floatbtnskew {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skew(15deg);
}



.sharebtnicon {
    font-size: 1.5rem;
    font-weight: bold;
    color: #E100FF;
    margin-bottom: 2px;
}
/* 
.sharebtniconlg {
    font-size: 1.7rem;
    font-weight: bold;
    color: #7DF9FF;
    margin-bottom: 2px;
} */

.sharebtniconlgx {
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 2px;
}

.sharebtniconinsta {
    font-size: 1.9rem;
    font-weight: bold;
    /* color: #7DF9FF; */
    margin-bottom: 2px;
}

.sharebtniconlg {
    font-size: 1.7rem;
    font-weight: bold;
    /* color: #7DF9FF; */
    color: fuchsia;
    margin-bottom: 2px;
}

.sharebtniconlgx {
    font-size: 1.7rem;
    font-weight: bold;
    /* color: #7DF9FF; */
    color: #333;
    margin-bottom: 2px;
}

.btnopac {
    opacity: 0.5;
}

.clickawayarrow {
    font-size: 1.5rem;
    color: #0BFFB6;
    margin-left: 4px;

    transform: rotate(-45deg);

    margin-top: 1px;
}

.clickawayarrowrd {
    font-size: 1.5rem;
    color: #FF1493;
    margin-left: 4px;

    transform: rotate(-45deg);

    margin-top: 1px;
}




.clickawayarrow3 {
    /* margin-top: 2px; */
    font-size: 1.2rem;
    color: white;
    margin-left: 3.5px;

    transform: rotate(-45deg);
}

.spaceforbtn {
    height: 110px;
    height: 100px;
    width: 100%;
}

.socialmediabtn {
    height: 57px;
    width: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;
    /* color: white; */
    background-image: linear-gradient(
    90deg,
    #000, #111, #333
    );
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
    1px 3px 6px 0px rgba(36,36,36,0.7),
    2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    /* margin: 0 15px; */

    transform: skew(-12deg);
    margin: 0 10px;
}

.sharebuttonsm {
    width: 62px;
    height: 57px;
    border-radius: 15px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-image: linear-gradient(
        90deg,
        #000, #111, #333
        );
        box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    transform: skew(-15deg);
}

.socialmediabtninsta {
    height: 57px;
    /* width: 128px; */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    /* background-image: linear-gradient(
    90deg,
    #222, #333, #555
    ); */
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
    1px 3px 6px 0px rgba(36,36,36,0.7),
    2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    margin: 0 10px;

    transform: skew(-12deg);
}



/* .fbbtn {
    background-image: linear-gradient(
    90deg,
    #0078FF, #00C6FF
    );
} */

.instabtn {
    background-image: linear-gradient(
    90deg,
    #FA7E1E, #D62976, #962FBF
    );
    color: white;
    
}

.instabtngr {
    background-image: linear-gradient(
    90deg,
    #FA7E1E, #D62976, #962FBF
    );
    color: white;
    flex-grow: 1;
    margin: 0 10px;
}

/* .webbtn {
   
    color: #BC00FE;
}

.phonebtn {
 
    color: #BC00FE;
} */

ion-content.testpage {
    border: 3px solid red;
    max-width: 300px;
}

.floatbtnskewsocialmed {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skew(12deg);
}

.makefull {
    width: 100%;
    height: 100%;
}

/* VIEW MAP BUTTONS */

.viewmapbtnpurp {
    cursor: pointer;
    min-width: 92px;
    padding: 7px 18px;
    /* border-radius: 6px 26px; */
    border-radius: 20px;
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    color: fuchsia;
    border: 1.5px solid fuchsia;
    letter-spacing: 0.03rem;
    /* text-transform: uppercase; */
    font-weight: bold;
    /* background-image: linear-gradient(
        0deg,
        #000, #222, #333, #444
    );
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    background-color: #000;
    /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
}


.viewreview {
    min-width: 95px;
    font-size: .8rem;
  letter-spacing: 0.01rem;
  border-radius: 4px;
  display: flex;
    justify-content: center;
    align-items: center;
  /* border: 1px solid white; */
  padding: 5px 10px;
  background-color: #333;
  color: fuchsia;
  /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
  box-shadow: 1px 1px 0px 0px rgba(36,36,36,0.7),
                1px 3px 6px 0px rgba(0,0,0,0.7)
    ;
    font-weight: 500;
    /* margin-bottom: 1px; */
    font-weight: bold;
    font-family: Montserrat;
    text-shadow: 1px 1px 0 #111;
}




/* TIMETABLE IN BARS AND CLUBS WINDOW */

.timetablebox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.openinghoursbox {
    width: 100%;
    height: 100%;
    /* background-color: #000; */

    border-radius: 15px;
    padding: 0px 0px;
    /* padding: 5px 10px; */

    /* border: 1px solid fuchsia; */
    /* box-shadow: 2px 2px 2px 2px fuchsia; */
    /* margin-top: 20px; */
    /* margin-top: 5px; */
    margin-top: 1px;
    /* padding-bottom: 20px; */
    
  }

.weekday {
    height: 32px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-family: Montserrat;
    font-size: .9rem;
    font-weight: bold;
    color: white;
    /* text-shadow: 1px 1px 1px fuchsia; */
    letter-spacing: 0.03rem;
}

/* I haven't yet shortened the names below */

.BCtimes {
    /* display: flex; */
    /* width: 56%; */
    width: 60%;
 
  }

.backgroundsec {
    background-color: #333;
    padding: 10px;
    border-radius: 10px;

}

.standardsinglerowopeninghours {
    height: 32px;
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #333; */
    
}

.brdtop {
    border-top: 1px solid #333;
}

.firstcolumnopeninghours {
    width: 38%;
    display: flex;
    /* justify-content: end; */
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    /* color: fuchsia; */
}

.bubbledaycontainer {
    padding: 4px 10px;
    width: 100%;
    max-width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-family: Montserrat;
    font-size: .85rem;
    font-weight: bold;
    /* font-style: italic; */
    /* background-image: linear-gradient(
        0deg,
        #000, #222, #444
    ); */
    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    /* border: 1px solid fuchsia;
    background-color: #000; */
    color: #0BFFB6;
}

.secondcolumnopeninghours {
    width: 24%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    /* font-style: italic; */
    color: #0BFFB6;
}

.thirdcolumnopeninghours {
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: fuchsia; */
}

.closedsinglerowopeninghours {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
    /* color: grey; */
    color: #999;
    /* font-weight: bold; */
    /* font-style: italic; */
    /* letter-spacing: .04rem; */
    letter-spacing: 0.1rem;
    /* border: 1px solid red; */
    /* border-bottom: 1px solid #333; */
    font-style: italic;
}

.twentyfourhourssinglerowopeninghours {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-bottom: 1px solid #333; */
}

.opentwentyfourhours {
    padding: 2px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: .85rem;
    /* color: #BC00FE; */
    color: #0BFFB6;
    /* font-weight: bold; */
    letter-spacing: 0.1rem;
    /* background-image: linear-gradient(
        180deg,
        #333, #222, #333, #444
    );   */
    /* font-weight: 500; */
}

/* CRUISE WINDOW - IM PROBABLY DOUBLING UP WITH MULTI MAP VIEW FOR BARS AND CLUBS */

.cruisemapcross {
    position: absolute;
    top: 1%;
    right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cruisemapcirc {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        90deg,
        #000, #111, #222
    );
    box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                /* 2px 4px 8px 0px rgba(16,16,16,0.7), */
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    z-index: 3;
}

.cruisemapicon {
    color: fuchsia;
    font-size: 1.45rem;
    margin-left: 2px;
}

.cruisemapdestcont {
    position: absolute;
    bottom: 5%;
    /* left: 2.5%; */
    width: 90%;
    max-width: 500px;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 0 auto; */
    z-index: 5;

    --ion-background-color: transparent;
}

.cruisemapdestbox {
    background-color: #111;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    overflow: hidden;
}

.itinplusmapheadercont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 28px;

}

.itinerarybox {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    /* should it be padding or margin */
}

.itineraryflexbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
        /* border: 1px solid red; */

}

.itincirccont {
    width: 25%;
    height: 30px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    z-index: 4;
    /* border: 1px solid yellow; */
}



.itincirc {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    z-index: 4;
}

.circ {
    /* border: 1px solid fuchsia; */
    color: fuchsia;
    /* background-image: linear-gradient(
        90deg,
        #000, #111, #333
    ); */
    background-color: #333;
}

.circinv {
    /* border: 1px solid fuchsia; */
    color: white;
    background-image: linear-gradient(
        90deg,
        fuchsia, #FF1493
    );
}

.itinlinecont {
    width: 30px;
    height: 64px;
    display: flex;
    z-index: 4;
    /* border: 1px solid red; */
}

.itinlineleft {
    width: 50%;
    border-right: .5px solid #555;
}

.itinlineright {
    width: 50%;
    border-left: .5px solid #555;
}

.itinerarytextbox {
    position: relative;
    bottom: 47px;
    width: 70%;
    height: 64px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Gilroy;
    z-index: 4;
    /* border: 1px solid red; */
}

.itinmidtextinner {
    display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
}

.itinerarymiddletext {
    margin: 4px 0;
    padding-bottom: 2px;
    /* did the above 2px ruin some balance thing I had going on? maybe not because of fixed height above.. just shifted things around... */
    /* height: 44%; */
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    /* text-transform: uppercase; */
    /* display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden; */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
}



.midtextextra {
    /* margin-bottom: 4px; */
    /* font-size: 1.1rem; */
    margin-bottom: 1px;
}

.midtextextraoth {
    /* margin-bottom: 4px; */
    /* font-size: 1.1rem; */
    margin-bottom: 5px;
}

.itinerarybottomtext {
    /* height: 28%; */
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    /* letter-spacing: 0.02rem; */
    color: #ccc;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.bottextkeyword {
    color: fuchsia;
    /* text-shadow: .5px .5px 0 fuchsia; */
    font-weight: bold;
    
}

.itinminuspad {
    margin-top: -35px;
}


.pricingflexboxcirccontainer {
    /* padding: 5px; */
    width: 100%;
    /* border-radius: 15px; */
    /* background-color: #333; */
    display: flex;
    flex-wrap: wrap;
    /* margin: 8px 0; */
    
    /* margin: 0px auto 8px auto; */
    /* align-items: flex-start; */
    /* align-content: flex-start; */
    
}



.festbtncont {
    
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
    /* margin-bottom: 24px; */
    margin-top: 5px;
}

.festbtncont2 {
    
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    align-items: center;
    /* margin-bottom: 24px; */
    padding: 7px 0px;
    margin-bottom: 20px;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    
    
}

.intxx {
    font-family: Montserrat;
    font-size: 0.9rem;
    padding-right: 10px;
    color: #ccc;

}

.clickawayarrow2 {
    font-size: 1.2rem;
    color: #FF00FF;
    margin-left: 8px;

    /* transform: rotate(-45deg); */
}


.festbtn {
    width: 100%;
    height: 44px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        0deg,
        #111, #222, #444
    );
    box-shadow: 1px 1px 0px 0px rgba(81,81,81,0.2),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ;
    border: 2px solid #7df9ff;
}

.festbtnoth {
   
     /* width: 77%; */
     width: 25%;
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     /* border: 1px solid red; */
}



.festbtntxt {
    font-family: Montserrat;
    font-size: .95rem;
     font-weight: bold;
     color: #FF00FF;
     letter-spacing: 0.03rem;
     text-shadow: .5px .5px 0 #333;
 

    width: 90%;
     height: 40px;
    /* height: 100%; */
     /* border-radius: 50px 8px; */
     border-radius: 12px;
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: .95rem;
     font-weight: bold;
     color: #FF00FF;
     letter-spacing: 0.03rem;
     text-shadow: .5px .5px 0 #333;
     /* background-image: linear-gradient(
         90deg,
         #7F00FF, #E100FF
         ); */
         background-image: linear-gradient(
         0deg,
         #111, #222, #333, #444
     );
     /* border: 3px solid #0BFFB6; */
         /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
         1px 3px 6px 0px rgba(36,36,36,0.7),
         2px 4px 8px 0px rgba(0,0,0,0.7)
     ; */
     font-family: Montserrat;
     /* transform: skew(-15deg); */
     padding: 15px 0 17px 0;
 
 
 
     box-shadow: 0 0 5px #FF00FF, 0 0 5px #FF00FF, 0 0 5px #FF00FF;
}

.festtag {
    width: 100%;
    display: flex;
    /* align-items: center; */
    font-family: Montserrat;
    font-size: .95rem;
    font-style: italic;
        /* font-weight: bold; */
        /* color: white; */
        /* letter-spacing: 0.01rem; */
        /* text-shadow: 1px 1px 0 #000; */
    /* color: #637FFD; */
    color: fuchsia;
    /* padding: 10px 0; */
    /* border-bottom: 1px solid fuchsia; */
    /* padding: 5px 0; */
    margin-top: 10px;
    /* margin-bottom: 2px; */
    line-height: 1.25rem;
}

.triancont {
    /* position: absolute; */
    height: 100%;
    /* width: 12px; */
    /* right: -10px; */
    /* height: 100%; */
    /* width: 37px; */
    
    /* transform: rotate(90deg); */


    /* font-family: Poppins; */
    margin-right: 10px;
    /* margin-bottom: 1px; */
    display: flex;
    /* align-items: center; */
    /* margin-bottom: 10px; */
    position: relative;
    bottom: 3px;
    
}

.trianwin {
    font-size: 1.6rem;
    color: fuchsia;
   
    /* filter:
     
       drop-shadow(0px 0px 10px fuchsia)
       drop-shadow(0px 0px 15px fuchsia)
      
      ; */
  }

  .extalert {
    font-size: 1.6rem;
    color: fuchsia;
   
    /* filter:
     
       drop-shadow(0px 0px 10px fuchsia)
       drop-shadow(0px 0px 15px fuchsia)
      
      ; */
  }

/* PEOPLE WINDOW */


.titleboxrightPP {
    width: 37%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
}



.tvshowlistcont {
    margin-top: 5px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.tvshowlistitem {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: .9rem;
    font-weight: bold;
    color: fuchsia;
    margin-right: 10px;
    margin-bottom: 6px;
    /* border: 1px solid yellow; */
}


/* PRICING OPTIONS IN DRAG EXPO WINDOW (DIDNT UPDATE CLASS NAMES) */

.pricingflexboxDEcontainer {
    /* padding: 5px; */
    width: 100%;
    /* border-radius: 15px; */
    /* background-color: #333; */
}

.pricingflexboxDE {
    /* width: 90%; */
    /* width: 95%; */
    width: 100%;
    /* height: 40px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    
    margin: 0px auto 8px auto;
}




.priceopflexac {
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
}

.pinkballalt {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: fuchsia;
    margin: 0 6px;
  }






/* this is for cruise window under itinerary. wait..i already have this for description its just that i dont have one right now... */
.reducemarg {
    margin-top: -20px;
}






@media (max-width: 380px) {

   

    /* .mainmenucont {
        position: fixed;
        top: 10vw;
        left: 70px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: rgba(188, 0, 254, 0.4);
        backdrop-filter: saturate(180%) blur(20px);
    } */

    .windowtitlefull {
   
        font-size: 2.1rem;
       
    }

    .itincirccont {
        width: 25%;
      
    }


.itinerarytextbox {
 
    width: 75%;
  
}
    

    .titleboxright {
        width: 21%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
    }

  

  

 
    
    .datetimetext, .datetimetext2 {
    
        font-size: 1rem;
    }

 

   

 
    
    .redfont {
        color: #FF1493;
        background-color: #444;
        border-radius: 20px;
        padding: 10px 15px;
        font-size: .95rem;
    }


    
    
    .addresshasvenuename {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 5px;
        /* why isnt the above doing anything? */
    }

  

    .socialmediabtninsta {
        height: 52px;
        width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        font-size: 1rem;
        font-weight: bold;
        flex-grow: 0;
        color: white;
        /* background-image: linear-gradient(
        90deg,
        #222, #333, #555
        ); */
        box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        /* margin: 0 15px; */
    
        transform: skew(-12deg);
        margin: 0 4px;
    }

    .socialmediabtn {
        height: 52px;
        width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        font-size: 1rem;
        font-weight: bold;
        /* color: white; */
        background-image: linear-gradient(
        90deg,
        #000, #111, #333
        );
        box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
        1px 3px 6px 0px rgba(36,36,36,0.7),
        2px 4px 8px 0px rgba(0,0,0,0.7)
        ;
        /* margin: 0 15px; */
    
        transform: skew(-12deg);
        margin: 0 4px;
    }

  


   
    
    
    .bottextkeyword {
        color: fuchsia;
        /* text-shadow: .5px .5px 0 fuchsia; */
        font-weight: bold;
        
    }
}



@media (max-width: 375px) {
   

    .viewmapbtnpurp {
        padding: 7px 12px;
       
        font-size: 0.85rem;
        
        letter-spacing: 0.03rem;
        min-width: 85px;
    }
}

@media (max-width: 374px) {
   

    .windowmaintextboxstd {
        width: 100%;
        font-family: Montserrat;
        font-size: 1rem;
        line-height: 1.4rem;
        white-space: pre-wrap;
        /* color: white; */
        font-weight: 500;
    }

    .windowvenueleft {
        /* width: 70%; */
        height: 100%;
        font-family: Montserrat;
        font-size: 1rem;
        line-height: 1.4rem;
        flex-grow: 1;
        padding-right: 10px;
    }

    .weekday {
        height: 32px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        font-family: Montserrat;
        font-size: .85rem;
        font-weight: bold;
        color: white;
        /* text-shadow: 1px 1px 1px fuchsia; */
        letter-spacing: 0.03rem;
    }

    .bubbledaycontainer {
        padding: 4px 10px;
        width: 100%;
        max-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        font-family: Montserrat;
        font-size: .8rem;
        font-weight: bold;
        /* font-style: italic; */
        /* background-image: linear-gradient(
            0deg,
            #000, #222, #444
        ); */
        /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                    1px 3px 6px 0px rgba(36,36,36,0.7),
                    2px 4px 8px 0px rgba(0,0,0,0.7)
        ; */
        /* border: 1px solid fuchsia;
        background-color: #000; */
        color: #0BFFB6;
    }

    .newpricefree {
        font-family: Montserrat;
        font-size: 1.2rem;
        /* padding: 5px 0 0 10px; */
        color: #0BFFB6;
        /* margin-left: 5px; */
        margin-top: 4px;
        letter-spacing: 0.03rem;
        font-weight: bold;
    }



}

@media (max-width: 374px) {
 


    .titleboxrightPP {
        display: none;
    }

    
    .viewmapbtnpurp {
        padding: 6px 12px;
       
        font-size: 0.8rem;
        
        letter-spacing: 0.03rem;
        min-width: 80px;
    }

    .datetimeiconme {
        font-size: 1.7rem;
        color: fuchsia;
    
        /* background: -webkit-linear-gradient(#5260ff, aqua);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
    }
    
    .datetimeiconlv {
        font-size: 2rem;
        color: fuchsia;
    
        /* background: -webkit-linear-gradient(#5260ff, aqua);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
    }


    
    .bottextkeyword {
        color: fuchsia;
     
        font-weight: bold;
      
    }



    .festbtntxt {
        
        font-size: .95rem;
          
            /* letter-spacing: 0.05rem; */
       
    }

    .mainwindowbtngreen {
      
        font-size: 1rem;
        font-weight: bold;
        color: #0BFFB6;
        letter-spacing: 0.03rem;
        text-shadow: .5px .5px 0 #333;
        /* background-image: linear-gradient(
            90deg,
            #7F00FF, #E100FF
            ); */
            /* background-image: linear-gradient(
            0deg,
            #111, #222, #333, #444
        ); */
        /* background-color: #444; */
        /* border: 3px solid #0BFFB6; */
            /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
            1px 3px 6px 0px rgba(36,36,36,0.7),
            2px 4px 8px 0px rgba(0,0,0,0.7)
        ; */
        font-family: Montserrat;
        /* transform: skew(-15deg); */
        /* padding: 15px 0 17px 0; */
        height: 47px;
    
    
    
    }

    .clickawayarrow {
        font-size: 1.3rem;
        color: #0BFFB6;
        margin-left: 4px;
    
        transform: rotate(-45deg);
    
        margin-top: 1px;
    }
}

@media (max-width: 320px) {
   

    .mainwindowbtngreen {
        /* width: 77%; */
        width: 100%;
        /* height: 50px; */
    
        /* border-radius: 50px 8px; */
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .9rem;
        font-weight: bold;
        color: #0BFFB6;
        letter-spacing: 0.03rem;
        text-shadow: .5px .5px 0 #333;
        /* background-image: linear-gradient(
            90deg,
            #7F00FF, #E100FF
            ); */
            /* background-image: linear-gradient(
            0deg,
            #111, #222, #333, #444
        ); */
        /* background-color: #444; */
        /* border: 3px solid #0BFFB6; */
            /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
            1px 3px 6px 0px rgba(36,36,36,0.7),
            2px 4px 8px 0px rgba(0,0,0,0.7)
        ; */
        font-family: Montserrat;
        /* transform: skew(-15deg); */
        /* padding: 15px 0 17px 0; */
        height: 42px;
    
    
    
        /* box-shadow: 0 0 5px #0BFFB6, 0 0 5px #0BFFB6, 0 0 5px #0BFFB6; */
    
    }

    .clickawayarrow {
        font-size: 1.2rem;
        color: #0BFFB6;
        margin-left: 4px;
    
        transform: rotate(-45deg);
    
        margin-top: 1px;
    }

    /* .mainmenucont {
        position: fixed;
        top: 10vw;
        left: 60px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: rgba(188, 0, 254, 0.4);
        backdrop-filter: saturate(180%) blur(20px);
    } */

    .festbtntxt {
        
        font-size: .85rem;
          
            /* letter-spacing: 0.02rem; */
       
    }

    .newpriceheadfree {
        color: #D4D4D4;
         font-family: Gilroy;
         /* padding: 10px 0 0 10px; */
         font-size: 1rem;
        letter-spacing: 0.02rem;
     }

    .newpricefree {
        font-family: Montserrat;
        font-size: 1.15rem;
        /* padding: 5px 0 0 10px; */
        color: #0BFFB6;
        /* margin-left: 5px; */
        margin-top: 4px;
        letter-spacing: 0.03rem;
        font-weight: bold;
    }

    .itinerarymiddletext {
    
        font-size: .95rem;

        
    }

    .itinerarybottomtext {
        
        font-size: 0.9rem;
    
    }
}

@media (min-width: 620px) {
    
    /* .ionbackbuttoncont {
        position: fixed;
        top: 62px;
        left: 33px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: rgba(255, 0, 255, 0.55);
        backdrop-filter: saturate(180%) blur(20px);
    }

    .mainmenucont {
        position: fixed;
        top: 62px;
        left: 90px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: rgba(188, 0, 254, 0.4);
        backdrop-filter: saturate(180%) blur(20px);
    }

    .windowheartcont {
        position: fixed;
        top: 62px;
        right: 33px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: rgba(255, 20, 147, 0.40);
        backdrop-filter: saturate(180%) blur(10px);
    } */

    .windowcontainerwithgrad {
        position: relative;
        width: 100%;
        min-height: 100vh;
        /* above is because gradient would extend to portion of screen if not enough content */
        /* font-family: Poppins, sans-serif; */
        /* background-image: linear-gradient(
        to top,
        #444, #333, #222, #222, #222
        ); */
        /* background-image: linear-gradient(
        to top,
        #444, #333, #333, #222, #222, #222, #222, #222, #222
    )   ; */
            background-color: #111;
            }
    
   
    
    .windowcontainerslowgrad {
        position: relative;
        width: 100%;
        min-height: 100vh;
        /* above is because gradient would extend to portion of screen if not enough content */
        /* font-family: Poppins, sans-serif; */
        /* background-image: linear-gradient(
        to top,
        #444, #333, #222, #222, #222, #222, #222, #222, #222
    )   ; */
    }

   
}

@media (min-width: 520px) {
    
 

    /* .datebox {
        padding: 0;
        margin-left: 19px;
    } */
    
    .dateboxlv {
        padding: 0;
    }
    
    /* .timebox {
        padding: 0;
        margin-left: 16px;
    } */
    
    .citybox {
        padding: 0;
        margin-left: 11px;
    }
}

@media (min-width: 620px) {
    .mainmenucont {
       
        /* left: 90vw; */
        /* left: calc(7.5vw + 45px); */
        top: 55px;   
        left: 75px;
    }

    .ionbackbuttoncont {
        top: 55px;
        left: 30px;
    }

    .windowheartcont {
        top: 55px;
        right: 30px;
    }
    
}

@media (max-width: 1199px) {
    .ionbackbuttoncontoth {
        display: none;
    }
    
   
}

@media (min-width: 1200px) {
    .ionbackbuttoncont {
        display: none;
    }

    .ionbackbuttoncontoth {
        position: fixed;
        /* top: 10vw; */
        /* top: 10vw; */
        top: 35px;
    
        /* left: 3.75vw; */
        left: 30px;
        /* width: 33px; */
        /* height: 33px; */
        border-radius: 20px;
        /* border-radius: 50%;; */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        /* background-color: rgba(255, 0, 255, 0.10); */
        /* background-color: rgba(255, 0, 255, 0.30); */
        background-color: rgba(0, 0, 0, 0.65);
    
        /* backdrop-filter: saturate(180%) blur(10px); */
        backdrop-filter: saturate(180%) blur(20px);
        /* border: 1px solid #0BFFB6; */
        /* font-weight: bold; */
    }

    /* ion-back-button.backsize {
        --icon-font-weight: bold;
    } */

    .mainmenucont {
        display: none;
    }

    .windowheartcont {
        display: none;
    }
}