ion-modal.notifmodal {
    --width: 90%;
    --max-width: 550px;
    --height: 66%;
    --border-radius: 20px;
    /* box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;     */
    /* --box-shadow: 0 0 5px fuchsia, 0 0 5px fuchsia, 0 0 5px fuchsia; */
    --background: transparent;
    
}

ion-modal.notifmodal::part(backdrop) {
    background: rgba(0, 0, 0);
    /* opacity: .7; */
    --backdrop-opacity: .6;

}

ion-modal.notifmodal2 {
    --width: 90%;
    --max-width: 500px;
    --height: auto;
    --border-radius: 20px;
    /* box-shadow: 1px 3px 0px 0px rgba(169,169,169,0.7),
              1px 2px 4px 0px rgba(51,51,51,0.7),
              3px 4px 8px 0px rgba(36,36,36,0.7),
              3px 4px 16px 0px rgba(16,16,16,0.7),
              3px 6px 16px 0px rgba(0,0,0,0.7)
    ;     */
    /* --box-shadow: 0 0 5px fuchsia, 0 0 5px fuchsia, 0 0 5px fuchsia; */
    --background: transparent;
    
}

ion-modal.notifmodal2::part(backdrop) {
    background: rgba(0, 0, 0);
    /* opacity: .7; */
    --backdrop-opacity: .8;

}

ion-toolbar.notiftoolbar {
    /* height: 45px; */
    --max-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* --background: rgba(36,36,36,.6); */
    /* --background: #222; */
    /* backdrop-filter: none; */
    /* border-bottom: 2px solid #0BFFB6; */
    /* --backdrop-opacity: .6; */
    /* --background: rgba(17,17,17,.7);
    backdrop-filter: saturate(180%) blur(15px); */

    --background: rgba(36,36,36,.7);
    backdrop-filter: saturate(180%) blur(10px);
}

.notifheader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 1.1rem;
    letter-spacing: 0.06rem;
    font-weight: bold;    
    /* color: #0BFFB6; */
    /* color: #999; */
    /* color: white; */
    background: linear-gradient(10deg, #FF1493, #FF00FF);
  -webkit-background-clip: text;
  color: transparent; /* This makes the gradient visible on the text */
    /* border: 2px solid yellow; */
    /* background-color: transparent; */
    /* border-bottom: 2px solid #444; */
}

.btnstw {
    font-size: 1.05rem;
}


ion-content.ioncnotif {
    min-height: 400px;
    --padding-bottom: 0;
    /* background-image: linear-gradient(
        to top,
         #222, #333, #444
    ); */
    --background: #111;
}

.notifgradient {
    padding: 10px 0 5px 0;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background-image: linear-gradient(
        to top,
         #222, #333, #444
    ); */
    /* --background: #444; */
}

.notifcard {
    /* position: relative; */
    padding: 12px 0;
    width: 95%;
    border-radius: 12px;
    /* background-image: linear-gradient(
        180deg,
        #222, #333
    ); */
    box-shadow: 1px 2px 1px 0px rgba(51,51,51,0.15),
                1px 2px 4px 0px rgba(51,51,51,0.7),
                2px 4px 8px 0px rgba(36,36,36,0.7),
                3px 4px 10px 0px rgba(16,16,16,0.7),
                3px 6px 12px 0px rgba(0,0,0,0.7),
                -1px -1px 0 0px rgba(255,255,255,0.2)
    ;
    /* margin: 15px auto; */
    margin-bottom: 12px;
}

.notifcardtop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifcardheadingcont {
    position: relative;
    padding: 0 15px 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-weight: bold; */
}

.notifcardheading {
    font-family: Montserrat;
    font-size: 1.05rem;
    /* color: #BC00FE; */
    color: fuchsia;
    font-weight: bold;
    letter-spacing: 0.03rem;
    /* text-transform: uppercase; */

    
}

.notifcardlockcont {
    /* position: absolute;
    top: 0px;
    right: -18px; */
    /* padding: 0 10px 0 0; */
    margin-left: 7px;
}

.notifcardlock {
    font-size: 1.1rem;
    color: #ccc;
}

.notifcardmain {
    position: relative;
    padding: 6px 15px 0 15px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notifcardmalt {
    position: relative;
    padding: 6px 15px 0 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.notifcardmsg {
    font-family: Montserrat;
    font-size: .95rem;
    width: 100%;
    line-height: 1.2rem;
}

.setbtncont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.notifcardbot {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifcardbotcont {
    width: 90%;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.notifcardmeta {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Gilroy;
    font-size: 0.9rem;
    color: #ccc;
}

@media (max-width: 374px) {
    .notifcardmeta {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Gilroy;
        font-size: 0.7rem;
        /* color: #949494; */
    }

    .notifheader {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        font-size: .95rem;
        letter-spacing: 0.06rem;
        font-weight: bold;    
        /* color: #0BFFB6; */
        /* color: #999; */
        /* color: white; */
        /* border: 2px solid yellow; */
        /* background-color: transparent; */
        /* border-bottom: 2px solid #444; */
    }

    .btnstw {
        font-size: .95rem;
    }
}

@media (max-width: 319px) {
   

    .notifheader {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        font-size: .85rem;
        letter-spacing: 0.06rem;
        font-weight: bold;    
        /* color: #0BFFB6; */
        /* color: #999; */
        /* color: white; */
        /* border: 2px solid yellow; */
        /* background-color: transparent; */
        /* border-bottom: 2px solid #444; */
    }

    .btnstw {
        font-size: .85rem;
    }
}

.ratingbox {
    height: 50px;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.emailbox {
    /* height: 50px; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.emailinp {
    /* height: 100%; */
    width: 100%;
    
    
}

.staremp {
    /* height: 90%; */
    font-size: 1.5rem;
    color: #FFF01F;
}

.starfll {
    font-size: 1.5rem;
    color: #FFF01F;
}

.txtbox {
    width: 90%;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  
  .txtarea {
    /* margin-top: 10px; */
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: none;
    outline: none;
    background-color: #000;
    color: white;
    padding: 0px 8px;
    border: 1px solid #333;
    resize: none;
    font-size: 1rem;
    font-family: Gilroy;
  }

  ion-input.txtarea {
    /* margin-top: 10px; */
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: none;
    outline: none;
    background-color: #000;
    color: white;
    /* --padding: 0px 8px;
    --padding-left: 4px; */
    --padding-bottom: 0px;
    --padding-end: 8px;
    --padding-start: 8px;
    --padding-top: 1px;
    border: 1px solid #333;
    resize: none;
    font-size: 1rem;
    font-family: Gilroy;
  }


  .plslimit {
    font-family: Gilroy;
    font-size: .9rem;
    color: #949494;
    font-style: italic;
    margin-top: 10px;
  }
  

.tgainsta {
    /* font-weight: bold; */
    /* color: fuchsia; */
    /* text-decoration: underline; */
    padding: 5px 11px;
    display: inline-block;
    border-radius: 10px;
    font-family: Montserrat;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02rem;
    background-image: linear-gradient(
    90deg,
    #D62976, #962FBF
    );
    font-size: .95rem;
    /* box-shadow: 1px 1px 0px 0px rgba(51,51,51,0.7),
                1px 3px 6px 0px rgba(36,36,36,0.7),
                2px 4px 8px 0px rgba(0,0,0,0.7)
    ; */
    margin: 8px 0 3px 0;
}

.tgainsta2 {
  
    display: inline-block;
   
    font-weight: bold;
    letter-spacing: 0.02rem;
    /* background-image: linear-gradient(
    90deg,
    #D62976, #962FBF
    ); */
    
    margin: 0 0;
    background: -webkit-linear-gradient(0deg, #D62976, #962FBF);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
